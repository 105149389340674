export default class CaptionsManager
{
    constructor(fullText, timeSeconds, startTime, endTime, bufferDOMobject, maxBufferHeight)
    {
        this.totalRunningTime = timeSeconds;
        this.nextWordTimer = 0.0;
        this.nextWordTimerMax = 0.0;
        this.startTime = startTime;
        this.endTime = endTime;

        this.maxBufferHeight = maxBufferHeight;

        this.wordObjects = [];
        this.currWordObjIndex = 0;

        this.nextBufferWordObject;
        this.nextWordObject;

        this.bufferDOMobject = bufferDOMobject;

        this.bufferDOMobject.innerHTML = ""; // you need to wipe the last buffer

        this.buildWordObjects(fullText, (timeSeconds - 3.0))
    }

    buildWordObjects(fullText, timeSeconds)
    {
        let spaceIndex = 0;
        let str = fullText;
       
        while(spaceIndex != -1) {
            spaceIndex = str.indexOf(" ");
            let spannedStr;
            if(spaceIndex != -1) {                
                spannedStr = str.substring(0, spaceIndex);
                this.wordObjects.push({
                    "isNewLine": false,
                    "word": spannedStr
                });
    
                const subStr = str.substring(spaceIndex + 1);
                str = subStr;
            }
            else {                
                spannedStr = str.substring(0);
                this.wordObjects.push({
                    "isNewLine": false,
                    "word": spannedStr
                });
            }
        }

        let fullStr = "";
        for(let i = 0; i < this.wordObjects.length; i++) {
            fullStr += this.wordObjects[i].word + " ";
        }
        // console.log(fullStr);

        const numWords = this.wordObjects.length;
        // this.nextWordTimerMax = timeSeconds / numWords;
        this.nextWordTimerMax = (this.endTime - this.startTime) / numWords;

        if(this.wordObjects.length > 0) {
            this.nextBufferWordObject = this.wordObjects[0];
            this.updateBuffer(this.nextBufferWordObject);

            if(this.wordObjects.length > 1) {
                this.currWordObjIndex = 1;
            }
        }
    }

    hasNextWord(elapsedTime)
    {
        if(elapsedTime < this.startTime)
            return false;
        else
            return (this.nextWordObject !== null)? true : false;
    }

    getNextWord()
    {    
        let nextWord = this.nextWordObject;
        this.nextWordObject = null;

        return nextWord;
    }

    updateBuffer(wordObject)
    {
        let activeBufferText = this.bufferDOMobject.innerHTML;
        activeBufferText += " " + wordObject.word;
        this.bufferDOMobject.innerHTML = activeBufferText;
    }

    swapBuffer(nextWord)
    {
        this.nextWordObject = nextWord;
        const bufferRect = this.bufferDOMobject.getBoundingClientRect();
        const bufferHeight = bufferRect.height;

        // console.log("Buffer height:" + bufferHeight);

        if(bufferHeight > this.maxBufferHeight) {
            this.nextWordObject.isNewLine = true;
            this.bufferDOMobject.innerHTML = "";            
        }
        else {
            this.nextWordObject.isNewLine = false;
        }

        this.nextBufferWordObject = null;
    }

    update(deltaTime, elapsedTime)
    {
        if(this.nextBufferWordObject !== null) {
            this.swapBuffer(this.nextBufferWordObject);
        }

        if(elapsedTime >= this.startTime) {
            this.nextWordTimer += deltaTime;
            if(this.nextWordTimer >= this.nextWordTimerMax) {
                this.nextWordTimer -= this.nextWordTimerMax;

                if(this.currWordObjIndex < this.wordObjects.length) {
                    // this.nextWordObject = this.wordObjects[this.currWordObjIndex];
                    this.nextBufferWordObject = this.wordObjects[this.currWordObjIndex];
                    this.currWordObjIndex++;

                    this.updateBuffer(this.nextBufferWordObject);
                }
            }
        }

    }
}