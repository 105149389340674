import * as THREE from 'three'
import vertexShader from './../shaders/rose/vertex.glsl'
import fragmentShader from './../shaders/rose/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import gsap from "gsap";

export default class Rose extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 1.5,
            cameraZ: 3,

            uSmallWavesElevation: 0.322, // 0.322, 0.922, 0.522
            uSmallWavesFrequency: 2.96,
            uSmallWavesSpeed: 1.3,
            uSmallWavesIterations: 1,
            rectWidth: 12,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(0,0,0),//new THREE.Vector3(110.0 / 255.0, 110.0 / 255.0, 110.0 / 255.0),
            depthColor: new THREE.Vector3(0,0,0),//new THREE.Vector3(49.0 / 255.0, 49.0 / 255.0, 49.0 / 255.0),
            numSine: 0.25, // 5.44,
            numCosine: 0.25, // 3.59,
            
            elevationAddClamp: 0.57,
            wireframe: true,
            areWavesChanging: false
            
        };

        this.lines = [];
        this.lineStaff = null;
        this.water = null;
        this.geometry = null;
        this.material = null;

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        this.audioListener = new THREE.AudioListener();
        this.camera.add(this.audioListener);    
        this.sound = new THREE.Audio(this.audioListener);
        this.scene.add(this.sound);
        this.soundSet = false;
        this.soundPlaying = false;

        this.sampleTimer = 0.0;
        this.sampleRate = null;
        this.currSampleIndex = 0;
        this.rawAudioData = null;
        this.normalizedAudioData = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.1;
        this.unrealBloomPass.radius = 0.1;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        /// phases
        this.phases;

        this.currPhaseIndex;;    
        this.currPhase;
        this.isDone;
        this.nextPhaseStartTime;
        this.growthTimer = 0.0;

        // this.currSurfaceColor = this.phases[0].surfaceColor;
        // this.currDepthColor = this.phases[0].depthColor;

        this.barMaterials = [];
        this.barSizes = [];

        this.totalRunningTime = 0.0;

    } // end constructor

    playAudio()
    {
        if(this.soundSet) {
            this.sound.play();
            this.soundPlaying = true;
        }
    }

    buildVisual() 
    {  
        this.geometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 256, 256);
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            wireframe: this.params.wireframe,
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                uGhostHeadPos: { value: this.ghostHead1 },
    
                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },

                uCurrentRadius: { value: 0.0 }
            }
        });

        this.water = new THREE.Mesh(this.geometry, this.material);
        this.water.rotation.x = - Math.PI * 0.5;
        this.scene.add(this.water);
        
        this.camera.position.x = 0;
        this.camera.position.y = 1.5;
        this.camera.position.z = 3;

        this.camera.lookAt(new THREE.Vector3(0,0,0));

        // this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ);        

        this.phases = [
            {  // phase 1               
                startTime: 0.0,
                
                                         
            },
            {  // phase 2 ---                 
                startTime: 0.5,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.material.uniforms.uSurfaceColor.value,
                        instruction: { duration: 1.5, x: 0.431, y: 0.431, z: 0.431, ease: 'expo.out' }                          
                    },       
                    {                        
                        param: this.material.uniforms.uDepthColor.value,
                        instruction: { duration: 1.5, x: 0.192, y: 0.192, z: 0.192, ease: 'expo.out', delay: -1.5 }                          
                    },             
                ]                                             
            }, 
            {  // phase 2 ---                 
                startTime: 2.0,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.material.uniforms.uNumSines,
                        instruction: { duration: 15.0, value: 5.99, ease: 'expo.out' }                          
                    },
                    {                        
                        param: this.material.uniforms.uNumCosines,
                        instruction: { duration: 10.0, value: 3.59, ease: 'expo.out', delay: -5.0 }                          
                    },
                    {                        
                        param: this.material.uniforms.uElevationAddClamp, 
                        instruction: { duration: 2.0, value: 0.72, ease: 'expo.out', delay: -3.5 }                          
                    },
                    {                        
                        param: this.material.uniforms.uElevationAddClamp, 
                        instruction: { duration: 3.0, value: 0.52, ease: 'expo.out'  }                          
                    }
                ]                                             
            },      
            // {  // phase 2 ---                 
            //     startTime: 24.0,
            //     changesSines: true,
            //     sineTweens: [
                    
            //         {                        
            //             param: this.material.uniforms.uElevationAddClamp, 
            //             instruction: { duration: 3.0, value: 0.52, ease: 'expo.out'  }                          
            //         }
            //     ]                                             
            // }, 
            {  // phase 2 ---                 
                startTime: 29.0,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.material.uniforms.uNumSines,
                        instruction: { duration: 15.0, value: 0.25, ease: 'expo.out' }                          
                    },
                    {                        
                        param: this.material.uniforms.uNumCosines,
                        instruction: { duration: 15.0, value: 0.25, ease: 'expo.out', delay: -5.0 }                          
                    }
                ]                                             
            },
            {  // phase 2 ---                 
                startTime: 43.5,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.material.uniforms.uSurfaceColor.value,
                        instruction: { duration: 2.0, x: 0.000, y: 0.000, z: 0.000, ease: 'expo.out' }                          
                    },         
                    {                        
                        param: this.material.uniforms.uDepthColor.value,
                        instruction: { duration: 1.5, x: 0.0, y: 0.0, z: 0.0, ease: 'expo.out', delay: -1.5 }                          
                    },           
                ]                                             
            },           
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
    }

    updateCamera(deltaTime)
    {
        if(this.params.cameraZ != this.currPhase.cameraZ) {
            this.params.cameraZ += deltaTime * 0.2;
            if(this.params.cameraZ > this.currPhase.cameraZ)
                this.params.cameraZ = this.currPhase.cameraZ;
            
            this.camera.position.z = this.params.cameraZ;    
        }
    }

    doGsapTransition(timelineSequences)
    {
        const timeline = gsap.timeline();
        for(let i = 0; i < timelineSequences.length; i++) {     
            
            let tween = gsap.to(timelineSequences[i].param, timelineSequences[i].instruction);
            timeline.add(tween);
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update cube rotation
        // this.updateRotation(deltaTime, elapsedTime);

        // if(this.currPhase.hasCameraChange == true)
        //     this.updateCamera(deltaTime); 
        // if(this.currPhase.doingHeave == 1.0)
        //     this.updateHeave(deltaTime, this.currPhase.heaveForward);
        // if(this.currPhase.doingJitter == 1.0)
        //     this.updateJitter(deltaTime);
        // if(this.currPhase.doingSwirl == 1.0)
        //     this.updateSwirl(deltaTime, this.currPhase.swirlForward);  

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                //console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];    
                    
                    if(this.currPhase.changesSines === true) {
                        this.doGsapTransition(this.currPhase.sineTweens);
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }
            
        
        // update audio
        if(this.soundPlaying == true) {
            this.sampleTimer += deltaTime;
            if(this.sampleTimer >= this.sampleRate) {
                if(this.currSampleIndex < this.rawAudioData.length) {                    
                    this.material.uniforms.uCurrentRadius.value = this.normalizedAudioData[this.currSampleIndex];                 

                    this.currSampleIndex++;        
                }
                this.sampleTimer = 0.0;
            }            
        }

        this.material.uniforms.uTime.value = this.totalRunningTime;      
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
        
        this.sampleRate = sampleRate;        
        this.rawAudioData = rawAudioData;
        this.normalizedAudioData = normalizedAudioData;
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class