import * as THREE from 'three'

export default class BaseVisual
{
    scene = null;
    renderer = null;
    camera = null;
    sizes = null;
    audioListener = null;
    sound = null;

    constructor(scene, renderer, camera, sizes)
    {
        this.scene = scene;
        this.renderer = renderer;
        this.camera = camera;
        this.sizes = sizes;

        this.audioListener = new THREE.AudioListener();
        this.camera.add(this.audioListener);    
        this.sound = new THREE.Audio(this.audioListener);
        this.scene.add(this.sound);
        this.soundSet = false;
        this.soundPlaying = false;

        this.baseVolume = 1.0;
    }

    changeSizes(sizes)
    {
        this.sizes = sizes;
    }

    buildVisual() { }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData) { }

    disableAudio()
    {
        this.sound.setVolume(0.0);
    }

    enableAudio()
    {
        this.sound.setVolume(this.baseVolume);
    }

    playAudio()
    {
        if(this.soundSet) {
            this.sound.play();
            this.soundPlaying = true;
        }
    }

    pauseAudio()
    {
        if(this.soundSet) {
            this.sound.pause();
            this.soundPlaying = false;
        }
    }

    stopAudio()
    {
        if(this.soundSet) {
            this.sound.stop();
            this.soundPlaying = false;
        }
    }

    update(deltaTime, elapsedTime) { }

    kill() { }

}

