import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Vector2, Vector3 } from 'three'
import BaseVisual from './baseVisual.js'
import * as dat from 'dat.gui'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader'

import galaxyVertexShader from './../shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './../shaders/galaxy/fragment.glsl'
import wormsVertexShader from './../shaders/galaxy/wormsVertex.glsl'
import wormsFragmentShader from './../shaders/galaxy/wormsFragment.glsl'

export default class Blackhole extends BaseVisual
{    
    colorInside;// = new THREE.Color(parameters.insideColor);
    colorOutside;// = new THREE.Color(parameters.outsideColor);
    colorFar;// = new THREE.Color(parameters.deepColor);

    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);       

        this.parameters = {
            branches: 8,
            startParicleCount: 35500, //12000
            startParicleSize: 0.015,
            radiusMinimum: 1.2,
            radiusShrinkBaseSpeed: 0.005,
            startRadiusMin: 1.5,
            startRadiusMax: 5.5,
            radiusMax: 7.0,
            startZMin: 0.25,
            startZMax: 3.25,
            baseRotationSpeed: 3.0,   
            insideColor: new THREE.Vector3(241.0 / 255.0, 241.0 / 255.0, 241.0 / 255.0),
            outsideColor: new THREE.Vector3(46.0 / 255.0, 46.0 / 255.0, 46.0 / 255.0),    
            deepColor: new THREE.Vector3(1.0, 1.0, 1.0),
            maxParticleCount: 35500,
            maxParticleScale: 1.0,
            farthestZ: 8.0,
            anim1StartTime: 3.0,
            anim1NumParticlesAdd: 10,
            anim2StartTime: 6.0,
            gravityStartTime: 3.0,
            gravity: 9.8,
            holeRadius: 2.2,
            cameraZ: 5.0,
            visibleSize: 25,
            nextVisibleStep: 2,
            // for worms
            uLineX: 0.1,
            uLineWidth: 0.045,
            uLineHeight: 0.02,
            linesX: [-0.065, -0.065, -0.065, -0.065, -0.065, -0.065, -0.065, -0.065, -0.065, -0.065],
            wallRadius: 3.0,
            baseWormRotationSpeed: 0.25

        };

        this.phases = [
            {  // phase 1
                rotationSpeed: 1.0,
                startTime: 0.0,
                growthWaitTimer: 0.15,
                showFlakes: false
            },
            {  // phase 2
                rotationSpeed: 2.25,
                startTime: 8.0,
                growthWaitTimer: 0.075,
                showFlakes: false
            },
            {  // phase 3
                rotationSpeed: 3.0,
                startTime: 15.0,
                growthWaitTimer: 0.025,
                showFlakes: true
            },
            {  // phase 4
                rotationSpeed: 4.0,
                startTime: 20.0,
                growthWaitTimer: 0.0,
                showFlakes: true
            },
            {  // phase 5
                rotationSpeed: 5.0,
                startTime: 25.0,
                growthWaitTimer: 0.2,
                showFlakes: true
            },
            {  // phase 6
                rotationSpeed: 5.0,
                startTime: 33.0,
                growthWaitTimer: 0.0,
                showFlakes: false
            },
            
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.nextPhase = this.phases[1];
        this.growthTimer = 0.0;
            
        this.geometry = null;
        this.material = null;
        this.points = null;

        this.colorInside = new THREE.Color('#f1f1f1');
        this.colorOutside = new THREE.Color('#565656');
        this.colorFar = new THREE.Color('#ffffff');

        this.gui = new dat.GUI();      

        // worms
        // Geometry
        this.wallGeometry = null;
        this.wallMaterial = null;
        this.leftWall = null;
        this.rightWall = null;
        this.topWall = null;
        this.bottomWall = null;

        this.currVisualTotalTime = 0.0;

        this.totalVisualTime = 0.0; // the above is a bit buggy. Need this here too.

        // rendering
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }

        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);    

        

        // // this.unrealBloomPass = new UnrealBloomPass();
        // // this.unrealBloomPass.enabled = true;
        // // this.unrealBloomPass.strength = 1;
        // // this.unrealBloomPass.radius = 3;
        // // this.unrealBloomPass.threshold = 0.6;
        // // this.effectComposer.addPass(this.unrealBloomPass);

        // this.horizontalBlurPass = new ShaderPass(HorizontalBlurShader);
        // this.horizontalBlurPass.material.uniforms.h.value = 1.0 / 3000.0;
        // this.horizontalBlurPass.material.uniforms.tDiffuse.value = 100.0;
        // this.effectComposer.addPass(this.horizontalBlurPass);

    }

    buildVisual() 
    {
        let positions = new Float32Array(this.parameters.startParicleCount * 3);       
        let scales = new Float32Array(this.parameters.startParicleCount * 1);
        let particleIndex = new Float32Array(this.parameters.startParicleCount * 1);
        let particleRadii = new Float32Array(this.parameters.startParicleCount * 1);

        // Destroy old galaxy
        if(this.points !== null) {
            this.geometry.dispose()
            this.material.dispose()
            this.scene.remove(this.points) // make sure to remove the objects from the scene
        }

        // Geometery
        this.geometry = new THREE.BufferGeometry()       

        for(let i = 0; i < this.parameters.startParicleCount; i++) 
        {
            const i3 = i * 3
            
            const particleRadius = this.parameters.startRadiusMin + (Math.random() * (this.parameters.startRadiusMax - this.parameters.startRadiusMin))
            const angleRadians = (Math.random() * 360.0) * (Math.PI / 180.0); // random angle / to radians

            positions[i3] = particleRadius * Math.cos(angleRadians);
            positions[i3 + 1] = particleRadius * Math.sin(angleRadians);            
            positions[i3 + 2] = (Math.random() * this.parameters.startZMax);             

            scales[i] = Math.random();

            particleIndex[i] = i;        
            particleRadii[i] = particleRadius;    
        }

        this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));            
        this.geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));   
        this.geometry.setAttribute('aParticleIndex', new THREE.BufferAttribute(particleIndex, 1));
        this.geometry.setAttribute('aRadius', new THREE.BufferAttribute(particleRadii, 1));

        const maxDistanceToCenter = this.parameters.startRadiusMin + this.parameters.startRadiusMax;

        // Material
        this.material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            vertexShader: galaxyVertexShader,
            fragmentShader: galaxyFragmentShader,
            uniforms:
            {
                uSize: { value: 80.0  * this.renderer.getPixelRatio() },  ////// <-- NOTE: need to set the pixel ratio for high def screens
                uTime: { value: 0.0 },
                uVisibilityIndex: { value: this.parameters.visibleSize },
                uBaseRotationSpeed: { value: this.parameters.baseRotationSpeed },
                uMaxDistanceToCenter: { value: maxDistanceToCenter },
                uMinRadius: { value: this.parameters.startRadiusMin },
                uInsideColor: { value: this.parameters.insideColor },
                uOutsideColor: { value: this.parameters.outsideColor },
                uDeepColor: { value: this.parameters.deepColor }
            }
        });

        // Points
        this.points = new THREE.Points(this.geometry, this.material)
        this.scene.add(this.points)

        // this.buildWorms();

        this.camera.position.z = 7.65; //12;
        this.camera.position.x = 0.0;
        this.camera.position.y = 0.0;
        this.camera.lookAt(new THREE.Vector3(0,0,0));

    }   

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    buildWorms()
    {
        this.currVisualTotalTime = 0.0;
        // this.wallGeometry = null;
        // this.wallMaterial = null;
        // this.leftWall = null;
        // this.rightWall = null;

        this.wallGeometry = new THREE.PlaneGeometry(this.sizes.wallWidth, this.sizes.wallHeight, 512, 512);

        this.wallMaterial = new THREE.ShaderMaterial({
            vertexShader: wormsVertexShader,
            fragmentShader: wormsFragmentShader,
            side: THREE.DoubleSide,
            blending: THREE.AdditiveBlending,
            uniforms: {
                uWallSize: { value: new THREE.Vector2(this.sizes.wallWidth, this.sizes.wallHeight) },     
                uTime: { value: 0.0 },
                uLineX: { value: this.parameters.uLineX },
                uLineWidth: { value: this.parameters.uLineWidth },
                uLineHeight: { value: this.parameters.uLineHeight },
                uLinesX: { value: this.parameters.linesX },
                uShowLines: { value: 0.0 }   
            }
        });

        // Walls
        this.leftWall = new THREE.Mesh(this.wallGeometry, this.wallMaterial); 
        this.leftWall.rotation.y = - Math.PI * 1.5
        this.leftWall.position.x = -this.parameters.wallRadius;
        this.leftWall.position.z -= 5.0;
        this.scene.add(this.leftWall)

        this.rightWall = new THREE.Mesh(this.wallGeometry, this.wallMaterial); 
        this.rightWall.rotation.y = Math.PI * 0.5
        this.rightWall.position.x = this.parameters.wallRadius;
        this.rightWall.position.z -= 5.0;
        this.scene.add(this.rightWall)

        this.topWall = new THREE.Mesh(this.wallGeometry, this.wallMaterial); 
        this.topWall.rotation.y = Math.PI * 0.5;
        this.topWall.rotation.x = Math.PI * 0.5;
        this.topWall.rotation.z = -Math.PI * 0.5;
        this.topWall.position.x = 0;
        this.topWall.position.y = this.parameters.wallRadius;
        this.topWall.position.z -= 5.0;
        this.scene.add(this.topWall)

        this.bottomWall = new THREE.Mesh(this.wallGeometry, this.wallMaterial); 
        this.bottomWall.rotation.y = Math.PI * 0.5;
        this.bottomWall.rotation.x = Math.PI * 0.5;
        this.bottomWall.rotation.z = -Math.PI * 0.5;
        this.bottomWall.position.x = 0;
        this.bottomWall.position.y = -this.parameters.wallRadius;
        this.bottomWall.position.z -= 5.0;
        this.scene.add(this.bottomWall)

    }

    getRandomStartPosition() 
    {
        const particleRadius = 0.75 + Math.random() * this.parameters.startRadiusMin; //parameters.startRadiusMin + (Math.random() * (parameters.startRadiusMax - parameters.startRadiusMin))
        const angleRadians = (Math.random() * 360.0) * (Math.PI / 180.0); // random angle / to radians

        const x = particleRadius * Math.cos(angleRadians); // x 
        const y = particleRadius * Math.sin(angleRadians); // y
        const z = this.parameters.startZMin + (Math.random() * this.parameters.startZMax); // z

        return new THREE.Vector3(x, y, z);
    }

    getDistance2D(x, y, cx, cy)     
    {
        return Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - cy, 2))
    }
    
    getDistance3D(x, y, z, cx, cy, cz)
    {
        return Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - cy, 2) + Math.pow(z - cz, 2));
    }    

    getAngle(x, y, cx, cy)
    {
        let alfa;
        let beta;
        const distanceX = Math.abs(Math.abs(x) - Math.abs(cx));
        const distanceY = Math.abs(Math.abs(y) - Math.abs(cy));
    
        if (x >= cx && y <= cy) {
            // find tangent
            beta = Math.atan(distanceY / distanceX);
            alfa = 90 - beta;
            return alfa;
        }
        // 90-180 -> second area
        else if (x >= cx && y >= cy) {
            beta = Math.atan(distanceY / distanceX);
            alfa = 90 + beta;
            return alfa;
        }
        // 180 - -90 -> third area
        else if (x <= cx && y >= cy) {
            beta = Math.atan(distanceY / distanceX);
            alfa = 270 - beta;
            return alfa;
        }
        // -90 - 0 -> fourth area
        else if (x <= cx && y <= cy) {
            beta = Math.atan(distanceY / distanceX);
            alfa = 270 + beta;
            if (alfa == 360) {
                alfa = 0;
            }
            return alfa;    
        } 
        else {
            return -1;
        }
    }

    updateVisibilityAndRotation(deltaTime, elapsedTime)
    {   
        this.growthTimer += deltaTime;

        if(this.growthTimer >= this.currPhase.growthWaitTimer) {
            this.growthTimer -= this.currPhase.growthWaitTimer;

            if(this.parameters.visibleSize < this.parameters.startParicleCount) {
                this.parameters.visibleSize += this.parameters.nextVisibleStep;
                this.parameters.nextVisibleStep += 1;

                this.material.uniforms.uVisibilityIndex.value = this.parameters.visibleSize;
            }

            // update rotation speed
            // if(this.nextPhase !== null) {                
            //     if(this.parameters.baseRotationSpeed < this.nextPhase.rotationSpeed) {
            //         this.parameters.baseRotationSpeed += 0.01;
            //         if(this.parameters.baseRotationSpeed > this.nextPhase.rotationSpeed) {
            //             this.parameters.baseRotationSpeed = this.nextPhase.rotationSpeed;
            //         }

            //         this.material.uniforms.uBaseRotationSpeed.value = this.parameters.baseRotationSpeed;
            //     }
            // }
        }        
    }
   

    update(deltaTime, elapsedTime) 
    {
        this.currVisualTotalTime += deltaTime;
        this.totalVisualTime += deltaTime;

        this.updateVisibilityAndRotation(deltaTime, elapsedTime);

        if(this.nextPhase != null) {    
            if( this.totalVisualTime >= this.nextPhase.startTime) {
                this.currPhase = this.nextPhase;
                // console.log("CurrPhase: " + (this.currPhaseIndex + 1) + "num particles: " + this.parameters.visibleSize);
                
                // if(this.currPhase.showFlakes)
                //     this.wallMaterial.uniforms.uShowLines.value = 1.0;
                // else
                //     this.wallMaterial.uniforms.uShowLines.value = 0.0;               

                this.currPhaseIndex++;
                if(this.currPhaseIndex < this.phases.length) {
                    this.nextPhase = this.phases[this.currPhaseIndex];
                }
                else {                    
                    this.nextPhase = null;
                    // console.log("Size of radii: " + this.parameters.radiusMax);
                }
            }
        }
        const totalParticles = this.geometry.attributes.position.array.length / 3;
        
        this.material.uniforms.uTime.value =  this.totalVisualTime;

        // Update particles  

        if(this.currPhaseIndex >= this.phases.length) {
            this.parameters.radiusMax -= 0.01;            
            this.parameters.startRadiusMax -= 0.01;   
        }

        for(let i = 0; i < totalParticles; i++) {
    
            const i3 = i * 3;
            let x = this.geometry.attributes.position.array[i3];
            let y = this.geometry.attributes.position.array[i3 + 1];
            let z = this.geometry.attributes.position.array[i3 + 2];                   
                        
            // adjust radius
            const oldRadius = this.geometry.attributes.aRadius.array[i];
            let newRadius = oldRadius - this.parameters.radiusShrinkBaseSpeed * (this.parameters.radiusMax - oldRadius) / this.parameters.radiusMax; //Math.pow(this.parameters.baseRotationSpeed, (this.parameters.radiusMax - oldRadius) / this.parameters.radiusMax);  
            
            if(newRadius < this.parameters.radiusMinimum) { 
                if(i <= 32500) {
                    const radius = this.parameters.startRadiusMin + (Math.random() * (this.parameters.startRadiusMax - this.parameters.startRadiusMin))
                    newRadius = radius;
                }   
                else {
                    const radius = this.parameters.radiusMinimum + (Math.random() * this.parameters.startRadiusMax);
                    newRadius = radius;
                }
            }
           
            this.geometry.attributes.aRadius.array[i] = newRadius;                     
    
        }
        
        this.geometry.attributes.aRadius.needsUpdate = true;
        // geometry.attributes.size.needsUpdate = true;    


        // // update worms
        // for(let i = 0; i < this.parameters.linesX.length; i++) {
        //     this.parameters.linesX[i] += deltaTime;
        //     if( this.totalVisualTime <= 5.0) {
        //         if(this.parameters.linesX[i] >= 1.5) {
        //             this.parameters.linesX[i] = 0.0 - this.parameters.uLineWidth;
        //         } 
        //     }
        //     else {
        //         if(this.parameters.linesX[i] >= (1.0 + Math.min(Math.random(), 0.5))) {
        //             //this.wallMaterial.uniforms.uShowLines.value = 1.0;
        //             this.parameters.linesX[i] = 0.0 - this.parameters.uLineWidth - Math.min(Math.random(), 0.5);
        //         }
        //     }        
        // }
    
        // this.wallMaterial.uniforms.uLinesX.value = this.parameters.linesX;    
        // this.wallMaterial.uniforms.uTime.value = this.currVisualTotalTime;

        // this.leftWall.position.x = this.parameters.wallRadius * Math.sin(-this.totalVisualTime * this.parameters.baseWormRotationSpeed);
        // this.leftWall.position.y = this.parameters.wallRadius * Math.cos(-this.totalVisualTime * this.parameters.baseWormRotationSpeed);

        // this.rightWall.position.x = this.parameters.wallRadius * Math.sin(Math.PI * 0.5 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed * 1.05);
        // this.rightWall.position.y = this.parameters.wallRadius * Math.cos(Math.PI * 0.5 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed * 1.05);

        // this.topWall.position.x = this.parameters.wallRadius * Math.sin(Math.PI * 0.75 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed);
        // this.topWall.position.y = this.parameters.wallRadius * Math.cos(Math.PI * 0.75 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed);

        // this.bottomWall.position.x = this.parameters.wallRadius * Math.sin(Math.PI * 0.25 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed);
        // this.bottomWall.position.y = this.parameters.wallRadius * Math.cos(Math.PI * 0.25 * -this.totalVisualTime * this.parameters.baseWormRotationSpeed);
    
        if( this.currVisualTotalTime > 10.0)
            this.currVisualTotalTime = 5.25;
    }

    render() 
    {
        this.renderer.render(this.scene, this.camera);
        // this.effectComposer.render();
    }


    kill() 
    { 
        // Destroy old galaxy
        if(this.points !== null) 
        {
            this.geometry.dispose()
            this.material.dispose()
            this.scene.remove(this.points) // make sure to remove the objects from the scene
        }
    }

} // end class

