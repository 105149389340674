import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { Vector2, Vector3 } from 'three'
import dist from 'webpack-merge'
import Stats from 'three/examples/jsm/libs/stats.module'
import gsap from "gsap";

import { SectionsIntroText } from './scripts/sectionsText'
import { MediaControl } from './scripts/sectionsText'
import { RevealText } from './scripts/sectionsText'
import { CC } from './scripts/sectionsText'
import { CC_FRENCH } from './scripts/sectionsText'

// audio
import { normalizeData, filterData} from './scripts/AudioUtils.js'

// visuals
import Blackhole from './scripts/blackhole'
import Heaving from './scripts/heaving'
import Thunder from './scripts/thunder'
import MindBugs from './scripts/mindBugs'
import ConfinementLines from './scripts/confinementLines'
import Cages from './scripts/cages'
import Spirits from './scripts/spirits'
import Gobular from './scripts/Gobular'
import Rose from './scripts/Rose'
import Sinister from './scripts/sinister'
import Cube from './scripts/cube'
import DevilsDoor from './scripts/devilsDoor'
import Caterpillar from './scripts/caterpillar'
import Tunnel from './scripts/tunnel'
import AbdelAudio from './scripts/AbdelAudio'
import TiffanyAudio from './scripts/TiffanyAudio'
import Amina from './scripts/Amina'
import MichelleCube from './scripts/michelleCube'
import CaptionsManager from './scripts/CaptionsManager'

window.addEventListener("load", function() {
    setTimeout(function() {
       // This hides the address bar:
       window.scrollTo(0, 1);
    }, 0);
 });

/**
 * Sizes
 */
 let sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Language
 */
const EN = "english";
const FR = "french";
let activeLanguage = EN;

/**
 * Main parameters
 */
const mainParams = {
    numVisuals: 10,
    currentVisualIndex: -1,
    showingPlayer: false,    
    isRunning: true,
    isShowingIntro: false,
    introTimer: 0,
    currentIntroTimerLength: 0,
    nextIntroText: "",
    // nextInt  howingText: "",
    // nextIntroHiddenText: "",
    nextIntroWordLength: 0,
    nextIntroWordPointer: 0,
    nextIntroWordTimer: 0,
    nextIntroWordTimerMax: 0.15,
    nextIntroSpans: [],
    currIntroSpansIndex: 0,
    hasIntroPlayed: false,
    showingVisual: false,
    hasAudioLoaded: false,
    activeSectionNum: 0,
    activeVisualNum: 0,

    activeVisualTimer: 0.0,
    activeVisualMaxTimer: 0.0,

    isShowingRevealPanel: false,
    isShowingInfoPanel: false,

    volumeOn: true,
    showPageSoundSet: false,
    shortSoundSet: false,
    introPageSoundSet: false,
    sectionPageSoundSet: false,
    isAudioButtonLoaded: false,
    
    showingCaptions: false,
    startedExperience: false,

    isShowMobileMediaPlayer: false,
    lastMoueMoveTime: 0.0,
    maxIdleMouseTime: 2.0
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

let activeVisual = null;
let captionsManager = null;

/**
 * Camera
 */
// Base camera
let camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)

camera.position.x = 0
camera.position.y = 0
camera.position.z = 8.0
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

// Sound effects
const showPageAudioListener = new THREE.AudioListener();
camera.add(showPageAudioListener);    
const showPageSound = new THREE.Audio(showPageAudioListener);
scene.add(showPageSound);

const shortSoundAudioListener = new THREE.AudioListener();
camera.add(shortSoundAudioListener);    
const shortSound = new THREE.Audio(shortSoundAudioListener);
scene.add(shortSound);

const introSoundAudioListener = new THREE.AudioListener();
camera.add(introSoundAudioListener);    
const introSound = new THREE.Audio(introSoundAudioListener);
scene.add(introSound);

const sectionSoundAudioListener = new THREE.AudioListener();
camera.add(sectionSoundAudioListener);    
const sectionSound = new THREE.Audio(sectionSoundAudioListener);
scene.add(sectionSound);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Mobile stuff
 */
 const isMobile = () => {
    if((window.matchMedia('(min-width: 750px)').matches))
        return false;
    else
        return true;
};

/**
 * Audio
 */
 const audioFileNames = [ "INTRO_4.mp3", "01_AMNESTY_MIX4.mp3", "02_AMNESTY_MIX4.mp3", "03_AMNESTY_MIX4.mp3", 
 "04_AMNESTY_MIX4.mp3", "05_AMNESTY_MIX4.mp3", "06_AMNESTY_MIX4.mp3", "07_AMNESTY_MIX4.mp3", 
 "08_AMNESTY_MIX4.mp3", "09_AMNESTY_MIX4.mp3", "10_AMNESTY_MIX4.mp3", "11_AMNESTY_MIX4.mp3",
 "12_AMNESTY_MIX4.mp3", "13_AMNESTY_MIX4.mp3", "14_AMNESTY_MIX4.mp3", "15_AMNESTY_MIX4.mp3",
 "16_AMNESTY_MIX4.mp3", "17_AMNESTY_MIX4.mp3", "Page_show.mp3", "short_sound.mp3", "SECTION_4.mp3" ];

const audioLoadTrackers = [ {name: "INTRO_4.mp3", loaded:0}, {name: "01_AMNESTY_MIX4.mp3", loaded:0}, {name: "02_AMNESTY_MIX4.mp3", loaded:0}, 
    {name: "03_AMNESTY_MIX4.mp3", loaded:0}, {name: "04_AMNESTY_MIX4.mp3", loaded:0}, {name: "05_AMNESTY_MIX4.mp3", loaded:0},
    {name: "06_AMNESTY_MIX4.mp3", loaded:0}, {name: "07_AMNESTY_MIX4.mp3", loaded:0}, {name: "08_AMNESTY_MIX4.mp3", loaded:0},
    {name: "09_AMNESTY_MIX4.mp3", loaded:0}, {name: "10_AMNESTY_MIX4.mp3", loaded:0}, {name: "11_AMNESTY_MIX4.mp3", loaded:0},
    {name: "12_AMNESTY_MIX4.mp3", loaded:0}, {name: "13_AMNESTY_MIX4.mp3", loaded:0}, {name: "14_AMNESTY_MIX4.mp3", loaded:0},
    {name: "15_AMNESTY_MIX4.mp3", loaded:0}, {name: "16_AMNESTY_MIX4.mp3", loaded:0}, {name: "17_AMNESTY_MIX4.mp3", loaded:0},
    {name: "Page_show.mp3", loaded:0}, {name: "short_sound.mp3", loaded:0}, {name: "SECTION_4.mp3", loaded:0},
];

const loader = new THREE.AudioLoader(); // instantiate a loader

let numAudioLoaded = 0;
let audioLoadedPercent = 0.0;
const totalAudioToLoad = audioFileNames.length;  

const audioObjects = [];

const handleIntroAssetLoad = () => {

    if((mainParams.isAudioButtonLoaded === true) && (mainParams.introPageSoundSet === true)) {
        const playSoundButton = document.getElementById("enable_sound_button");
        playSoundButton.classList.add("active");
    }
}

const imageLoader = new THREE.ImageLoader();
imageLoader.load(
    './images/voice_on_2x.png',
    function ( image ) {
        mainParams.isAudioButtonLoaded = true;
        handleIntroAssetLoad();
    }
)

const handleAudioLoad = (fileName, audioBuffer, sampleRate, rawData, normalizedAudioData) => {
    numAudioLoaded += 1;
    
    audioObjects.push(
        {
            'fileName': fileName,
            'audioBuffer': audioBuffer,
            'sampleRate': sampleRate,
            'rawData': rawData,
            'normalizedAudioData': normalizedAudioData
        }
    );

    if(fileName === "INTRO_4.mp3") {
        // console.log("Intro sound loaded...");
        handleIntroAssetLoad();
    }

    if(numAudioLoaded == totalAudioToLoad) {
       
        mainParams.hasAudioLoaded = true;

        for(let x = 1; x <= 17; x++) {
            const nextId = `title_letter_${x}`;
            // console.log("next el: " + nextId);
            const nextEl = document.getElementsByClassName(`${nextId}`)[0];
            if(nextEl !== null) {
                nextEl.classList.remove(nextId);
                nextEl.classList.add("visible");
            }
        }    

        const subheader = document.getElementById('intro_text');
        const startButton = document.getElementById('start_button');

        setTimeout(() => {
            subheader.classList.add('visible');
        }, 2.0);
        // show the subheader
        
        setTimeout(() => {
            startButton.classList.add('active');
        }, 5.0);

        // make start button function    
        // const startButton = document.getElementById("start_button");
        if(window.matchMedia('(min-width: 750px)').matches)
            startButton.innerHTML = "Start the Experience";
        else
            startButton.innerHTML = "Start";

        const startButtonWrapper = document.getElementById('page_button_wrapper');

        startButton.addEventListener('mouseenter', () => {           
            startButtonWrapper.classList.add('hover');
            if(window.matchMedia('(min-width: 750px)').matches) {
                const startButtonRect = startButtonWrapper.getBoundingClientRect();
                const startButtonWidth = startButtonRect.width;
                gsap.to("#page_button_wrapper", { duration: 0.35, backgroundSize: `${startButtonWidth}px 1px` })     
            }     
        });
        startButton.addEventListener('mouseleave', () => {
            if(window.matchMedia('(min-width: 750px)').matches) {
                const startButtonRect = startButtonWrapper.getBoundingClientRect();
                const startButtonWidth = startButtonRect.width;
                gsap.to("#page_button_wrapper", { 
                    duration: 0.35, backgroundSize: `0px 1px`,
                    onComplete: () => {
                        startButtonWrapper.classList.remove('hover');
                    } 
                });
            }
            else {
                startButtonWrapper.classList.add('remove');
            }
        });
        startButton.addEventListener('click', () => {
            if(mainParams.shortSoundSet === true) {
                shortSound.play();
            }
            showDisclaimer();
            //startExperience();
            
        });        
    }
};

// load a resource
audioFileNames.forEach((fileName) => {

    // "14-abdelrahman.mp3", "16-tiffany.mp3"
    let numAudioSamples = 1000;
    let sampleRate = 1000;    
    let rawData = null;
    let filteredData = null;
    let normalizedAudioData = null;

    loader.load(        
        './audio/' + fileName,
    
        // onLoad callback
        function ( audioBuffer ) {            
    
            // do additional processing for visuals driven by audio
            if((fileName === "14_AMNESTY_MIX4.mp3") || (fileName === "16_AMNESTY_MIX4.mp3") || (fileName === "17_AMNESTY_MIX4.mp3")) { 
                if((fileName === "14_AMNESTY_MIX4.mp3") || (fileName === "17_AMNESTY_MIX4.mp3"))
                    numAudioSamples = 7000;

                sampleRate = audioBuffer.duration / numAudioSamples;
                rawData = audioBuffer.getChannelData(0);

                filteredData = filterData(rawData, numAudioSamples);    
                normalizedAudioData = normalizeData(filteredData);
            }    
            
            if(fileName === "Page_show.mp3") {
                mainParams.showPageSoundSet = true;
                showPageSound.setBuffer(audioBuffer);
                showPageSound.setVolume(0.6);
            }
            else if(fileName === "short_sound.mp3") {
                mainParams.shortSoundSet = true;
                shortSound.setBuffer(audioBuffer);
                shortSound.setVolume(0.35);
            }
            else if(fileName === "INTRO_4.mp3") {
                mainParams.introPageSoundSet = true;
                introSound.setBuffer(audioBuffer);
                introSound.setVolume(2.0);
            }
            else if(fileName === "SECTION_4.mp3") {
                mainParams.sectionPageSoundSet = true;
                sectionSound.setBuffer(audioBuffer);
                sectionSound.setVolume(2.0);
            }

            handleAudioLoad(fileName, audioBuffer, sampleRate, rawData, normalizedAudioData);   
        },
    
        // onProgress callback
        function ( xhr ) {
            // console.log("Loaded: " + xhr.loaded);            
            // audioLoadedPercent += xhr.loaded / 31,884,612;
            // console.log( audioLoadedPercent * 100 + '% loaded' );

            const trackerObj = audioLoadTrackers.find(audioLoadTracker => audioLoadTracker.name === fileName);
            if(trackerObj !== null) {
                trackerObj.loaded = xhr.loaded / xhr.total;                
            }

            let totalLoaded = 0.0;
            for(let x = 0; x < audioLoadTrackers.length; x++) {
                totalLoaded += audioLoadTrackers[x].loaded / audioLoadTrackers.length;
            }
            audioLoadedPercent = totalLoaded;
           //console.log( audioLoadedPercent * 100 + '% loaded' );

            const startButton = document.getElementById("start_button");
            startButton.innerHTML = "Loading... " + (Math.round(audioLoadedPercent * 100)) + "%";
        },
    
        // onError callback
        function ( err ) {
            console.log( 'An error happened' );
        }
    );
});



const showDisclaimer = () => {
    const mainUI = document.getElementById('main_ui_content');
    mainUI.classList.add('not_on_page');

    const disclaimerPanel = document.getElementById('disclaimer_panel');
    disclaimerPanel.classList.add('visible');
}

/**
 * Sequencers
 */
const buildIntroText = (nextText) => {

    let str = nextText;
    let spannedStr = "";
    let spaceIndex = 0;
    let numSpaces = 0;

    while(spaceIndex != -1) {
        spaceIndex = str.indexOf(" ");
        if(spaceIndex != -1) {
            numSpaces++;
            const nextWord = str.substring(0, spaceIndex);
            spannedStr += `<span class='intro_text_fadeable'>${nextWord} </span>`;

            const subStr = str.substring(spaceIndex + 1);
            str = subStr;
        }
        else {
            const nextWord = str.substring(0);
            spannedStr += `<span class='intro_text_fadeable'>${nextWord} </span>`;
        }
    }

    mainParams.nextIntroText = spannedStr;
    mainParams.nextIntroWordLength = numSpaces + 1;      
};

const hideIntroText = () => {

    //document.getElementById("main_ui_content").classList.remove("black_background");

    const titleDiv = document.getElementById("title");    
    titleDiv.classList.remove("subheader_in");
    const introDiv = document.getElementById("intro_text");
    const subheaderDiv = document.getElementById("subheader_text");
    
    titleDiv.classList.add('hidden');
    introDiv.classList.add('hidden');
    introDiv.classList.remove('visible');
    subheaderDiv.classList.add('hidden');
    subheaderDiv.classList.remove('visible');

    const nextButton = document.getElementById('next_button');
    // nextButton.classList.add('not_on_page');
    nextButton.classList.add('hidden');
}

const swapIntroText = (visualIndex) => {

    const mainUI = document.getElementById("ui_content");
    mainUI.classList.add('black_background');

    // hide the captions panel
    if(mainParams.showingCaptions === true) {
        if(isMobile() !== true)
            document.getElementById("captions_container").classList.remove('visible');
        else 
            document.getElementById("mobile_cc_bar").classList.remove('visible');
    }

    if(mainParams.sectionPageSoundSet === true) {
        if(mainParams.volumeOn === true) {
            if(sectionSound !== null) {
                sectionSound.play();
            }
        }
    }
        
    const twitterMainLink = document.getElementById('twitter_main_link');
    if(twitterMainLink.classList.contains('not_on_page') !== true)
        twitterMainLink.classList.add('not_on_page')
    
    // const eyeButton = document.getElementById('eye_button');
    // if(eyeButton.classList.contains('not_on_page') === true) {
    //     eyeButton.classList.remove('not_on_page');
    // }

    if(visualIndex === 7) // show the outro
    {
        if(twitterMainLink.classList.contains('not_on_page') === true)
            twitterMainLink.classList.remove('not_on_page')

        // if(eyeButton.classList.contains('not_on_page') === false) {
        //     eyeButton.classList.add('not_on_page');
        // }

        const titleDiv = document.getElementById("title");
            
        titleDiv.classList.remove("subheader_in");
        titleDiv.classList.add("subheader_in");

        titleDiv.innerHTML = "When We Act, Hope Comes In"  
        titleDiv.classList.remove('hidden');        

        const subhead = document.getElementById('header_row');
        if(subhead.classList.contains('hidden') !== true)
            subhead.classList.add('hidden');
        const subrow = document.getElementById('subheader_row');
        if(subrow.classList.contains('hidden') !== true)
            subrow.classList.add('hidden');
        const nButt = document.getElementById('next_button_section');
        if(nButt.classList.contains('hidden') !== true)
            nButt.classList.add('hidden'); 
        
        const nextButt = document.getElementById('next_button');            
        nextButt.classList.remove('active');    
        
    }
    else 
    {
        const subhead = document.getElementById('header_row');
        if(subhead.classList.contains('hidden') === true)
            subhead.classList.remove('hidden');
        const subrow = document.getElementById('subheader_row');
        if(subrow.classList.contains('hidden') === true)
            subrow.classList.remove('hidden');
        const nButt = document.getElementById('next_button_section');
        if(nButt.classList.contains('hidden') === true)
            nButt.classList.remove('hidden');  

        if(visualIndex === 0) {
            // swap the main column layout
            const mainUI = document.getElementById('main_ui_content');
            mainUI.classList.remove('container-3-row-center');
            mainUI.classList.add('container-7-row-center');

            // remove the old h2
            const theOldH2 = document.getElementById('start_button');
            theOldH2.classList.add('not_on_page');

            // enable the new h2
            const theH2 = document.getElementById('subheader_text');
            theH2.classList.remove('not_on_page');
        }

        // get the next intro text
        const nextTitle = SectionsIntroText[visualIndex].title;
        const nextText = SectionsIntroText[visualIndex].header;
        const nextSubtext = SectionsIntroText[visualIndex].subheader;

        // add the spans to the text
        buildIntroText(nextText);

        // update the timers
        mainParams.isShowingIntro = true;
        mainParams.introTimer = 0;
        mainParams.currentIntroTimerLength = SectionsIntroText[visualIndex].timerLength;
        mainParams.nextIntroWordPointer = 0;
        mainParams.hasIntroPlayed = false;
        
        // enable the title divs
        const titleDiv = document.getElementById("title");
            
        titleDiv.classList.remove("subheader_in");
        titleDiv.classList.add("subheader_in");
        
        const introDiv = document.getElementById("intro_text");
        const subheaderDiv = document.getElementById("subheader_text");
        
        titleDiv.classList.remove('hidden');        
        introDiv.classList.add('visible');
        
        subheaderDiv.classList.add('hidden'); // make the subheader invisible to start
        const nextButton = document.getElementById('next_button');
        nextButton.classList.add('hidden');
        nextButton.classList.remove('active');

        // set the text for the intro
        if(visualIndex === 0)
            titleDiv.innerHTML = "";
        else
            titleDiv.innerHTML = nextTitle;
        introDiv.innerHTML = mainParams.nextIntroText; 
        subheaderDiv.innerHTML = nextSubtext; 

        // set the array of spans that track each word in the intro
        mainParams.nextIntroSpans = document.getElementsByClassName('intro_text_fadeable');
        mainParams.currIntroSpansIndex = 0;
    }
}

const updateIntroTextAnimation = (deltaTime) => {

    // add the visible class to intro text words, one-by-one
    mainParams.nextIntroWordTimer += deltaTime;
    if(mainParams.nextIntroWordTimer >= mainParams.nextIntroWordTimerMax) {
        mainParams.nextIntroWordTimer = 0;
        if(mainParams.currIntroSpansIndex < mainParams.nextIntroSpans.length) {
            mainParams.nextIntroSpans[mainParams.currIntroSpansIndex].classList.add('intro_text_fade_in');
            mainParams.currIntroSpansIndex++;
            if(mainParams.currIntroSpansIndex >= mainParams.nextIntroSpans.length) {
                // begin transitions for other elements
                const subheaderDiv = document.getElementById("subheader_text");

                subheaderDiv.classList.remove('hidden');
                subheaderDiv.classList.add('visible');

                setTimeout(() => {
                    const nextButton = document.getElementById('next_button');
                    console.log("Just enabled the continue button");
                    console.log("main section: " + mainParams.activeSectionNum);
                    nextButton.classList.remove('hidden');
                    if(mainParams.activeSectionNum !== 7)
                        nextButton.classList.add('active');
                    
                }, 3.0);   
            }
        }              
    }
};

const removeAllChildNodes = (parent) => {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}

const stopAllVisuals = () => {
    mainParams.showingVisual = false;
    if(activeVisual != null) {
        activeVisual.stopAudio();
        activeVisual = null;
    }

    scene.remove(camera);

    for( var i = scene.children.length - 1; i >= 0; i--) { 
        const obj = scene.children[i];
        scene.remove(obj); 
    }
    
}    

const pauseAudio = () => {
    if(activeVisual !== null) {
        activeVisual.pauseAudio();
    }
}

const resumeAudio = () => {
    if(activeVisual !== null) {
        activeVisual.playAudio();
    }
}

const handleEndOfCurrentVisual = () => {

    let showSection = false;

    if(mainParams.activeVisualNum === 0) {
        handleSectionButtonClick(1);
        showSection = true;
    } else if((mainParams.activeVisualNum >= 1) && (mainParams.activeVisualNum < 4)) {
        mainParams.activeVisualNum++;
        showVisual(mainParams.activeVisualNum);
    } else if((mainParams.activeVisualNum === 4)) {
        handleSectionButtonClick(2);    
        showSection = true;
    } else if((mainParams.activeVisualNum >= 5) && (mainParams.activeVisualNum < 7)) {
        mainParams.activeVisualNum++;
        showVisual(mainParams.activeVisualNum);
    } else if((mainParams.activeVisualNum === 7)) {
        handleSectionButtonClick(3);
        showSection = true;
    } else if((mainParams.activeVisualNum >= 8) && (mainParams.activeVisualNum < 10)) {
        mainParams.activeVisualNum++;
        showVisual(mainParams.activeVisualNum);
    } else if((mainParams.activeVisualNum === 10)) {
        handleSectionButtonClick(4);
        showSection = true;
    } else if((mainParams.activeVisualNum >= 11) && (mainParams.activeVisualNum < 12)) {
        mainParams.activeVisualNum++;
        showVisual(mainParams.activeVisualNum);
    } else if((mainParams.activeVisualNum === 12)) {
        handleSectionButtonClick(5);
        showSection = true;
    } else if((mainParams.activeVisualNum === 13)) {
        handleSectionButtonClick(6);
        showSection = true;
    } else if((mainParams.activeVisualNum >= 14) && (mainParams.activeVisualNum < 16)) {
        mainParams.activeVisualNum++;
        showVisual(mainParams.activeVisualNum);
    }
    else { // the take action step at end
        handleSectionButtonClick(7);
        showSection = true;
    }   

    if(isMobile() === true) {
        if(showSection === true) {
            setMobileMediaBar(mainParams.activeSectionNum, -1);
            setMobilePlayerControls(mainParams.activeSectionNum, -1);
        }
        else {
            setMobileMediaBar(mainParams.activeSectionNum, mainParams.activeVisualNum);
            setMobilePlayerControls(-1, mainParams.activeVisualNum);
        }
    }
}

const showVisual = (visualNum) => {

    // remove the active from the continue button
    document.getElementById('next_button').classList.remove('active');

    // remove the Twitter link if it's there
    const twitterLink = document.getElementById('twitter_main_link');
    if(twitterLink.classList.contains('not_on_page') === false) {
        twitterLink.classList.add('not_on_page');
    }

    // remove ui black background
    const mainUI = document.getElementById("ui_content");
    mainUI.classList.remove('black_background');

    // you ALWAYS build the captions... just don't show
    // show the panel
    if(mainParams.showingCaptions === true) {    
        if(isMobile() !== true)
            document.getElementById("captions_container").classList.add('visible');
        else {
            document.getElementById("mobile_cc_bar").classList.add('visible');
            document.getElementById("captions_container_mobile_p").innerHTML = "";
        }
    }

    document.getElementById("captions_container_p").innerHTML = "";

    let visualText = CC[visualNum].text;
    if(activeLanguage === FR) {
        visualText = CC_FRENCH[visualNum].text;
    }
    let visualLength = CC[visualNum].length;
    if(activeLanguage === FR) {
        visualLength = CC_FRENCH[visualNum].length;
    }

    let ccStart = CC[visualNum].start;
    let ccEnd = CC[visualNum].end;

    if(isMobile() !== true) {
        const captionsBufferDOM = document.getElementById('captions_buffer_p');  
        captionsManager = new CaptionsManager(visualText, visualLength, ccStart, ccEnd, captionsBufferDOM, 35.0);    
    }
    else {        
        const captionsBufferDOM = document.getElementById('captions_buffer_mobile');  
        captionsManager = new CaptionsManager(visualText, visualLength, ccStart, ccEnd, captionsBufferDOM, 20.0);
    }

    mainParams.activeVisualTimer = 0;
    mainParams.activeVisualMaxTimer = CC[visualNum].length + 1.5;

    // make sure to stop other audio
    if(mainParams.sectionPageSoundSet === true)
    {
        if(sectionSound.isPlaying)
            sectionSound.stop();
    }

    // update the button
    setStyleForActiveVisual(visualNum);

    mainParams.showingVisual = true;
    mainParams.isShowingIntro = false;

    if(activeVisual != null) {
        activeVisual.stopAudio();
        activeVisual = null;
    }

    for( var i = scene.children.length - 1; i >= 0; i--) { 
        const obj = scene.children[i];
        scene.remove(obj); 
    }

    scene.add(camera);
    // scene.add(renderer);

    let audioFileName = audioFileNames[1];

    if(visualNum == 0) {
        activeVisual = new Blackhole(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[1];
    }
    else if(visualNum == 1) {
        // camera.position.set(0, 0, 0);
        // camera.lookAt(0,0,0);
        activeVisual = new Heaving(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[2];
    }
    else if(visualNum == 2) {
        activeVisual = new Rose(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[3];
    }
    else if(visualNum == 3) {
        activeVisual = new Thunder(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[4];
    }    
    else if(visualNum == 4) {
        activeVisual = new MindBugs(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[5];
    }
    else if(visualNum == 5) {
        activeVisual = new Gobular(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[6];
    }
    else if(visualNum == 6) {
        activeVisual = new Tunnel(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[7];
    }
    else if(visualNum == 7) {
        activeVisual = new ConfinementLines(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[8];
    }
    else if(visualNum == 8) {
        activeVisual = new MichelleCube(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[9];
    }
    else if(visualNum == 9) {
        activeVisual = new Cages(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[10];
    }
    else if(visualNum == 10) {
        activeVisual = new Cube(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[11];
    }
    else if(visualNum == 11) {
        activeVisual = new DevilsDoor(scene, renderer, camera, sizes);  
        audioFileName = audioFileNames[12];
    }
    else if(visualNum == 12) {
        activeVisual = new Spirits(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[13];
    }
    else if(visualNum == 13) {
        activeVisual = new AbdelAudio(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[14];
    }
    else if(visualNum == 14) {
        activeVisual = new Caterpillar(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[15];
    }
    else if(visualNum == 15) {
        activeVisual = new TiffanyAudio(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[16];
    }
    else if(visualNum == 16) {
        activeVisual = new Amina(scene, renderer, camera, sizes); 
        audioFileName = audioFileNames[17];
    }

    if(activeVisual != null) {
        if(mainParams.volumeOn === true) { 
            activeVisual.enableAudio(); 
        }
        else {
            activeVisual.disableAudio();
        }
        activeVisual.buildVisual();
        const sound = audioObjects.find(audioObject => audioObject.fileName === audioFileName);
        activeVisual.setSound(sound.audioBuffer, sound.sampleRate, sound.rawData, sound.normalizedAudioData);        
        activeVisual.playAudio();
    }
};

const replayActiveVisual = () => {
    showVisual(mainParams.activeVisualNum);
}

const setActiveSection = (sectionNum) => {

    mainParams.activeSectionNum = sectionNum;

    const sectionButtons = document.getElementsByClassName('dot_section');
    const sectionLabels = document.getElementsByClassName('media_player_section_label');

    for(let i = 0; i < sectionButtons.length; i++) {
        let sectionButton = sectionButtons[i];
        let sectionLabel = sectionLabels[i];
        if(i === sectionNum) {
            sectionButton.classList.add('active');
            sectionLabel.classList.add('active');
            gsap.to(sectionLabel, { duration: 1.25, opacity: "1.0" })
        }
        else {
            sectionButton.classList.remove('active');
            sectionLabel.classList.remove('active');
            sectionLabel.style.opacity = 0;
        }
    }       
        
};

const hideAllVisualLabels = () => {
    
    const labels = document.getElementsByClassName('media_player_name_container');
    
    for(let i = 0; i < labels.length; i++) {
        
        const label = labels[i];
        label.classList.remove('active');
    }
}

const setVisualNameLabel = (labelIndex, buttonXPos) => {
    
    // console.log("Position of control: " + buttonXPos);
    
    const labels = document.getElementsByClassName('media_player_name_container');

    for(let i = 0; i < labels.length; i++) {
        
        let label = labels[i];

        if(i === labelIndex) {
            label.classList.add('active');
            
        }
        else {
            label.classList.remove('active');
        }
    }
}

const hideAllVisualButtons = () => {

    const buttons = document.getElementsByClassName('dot_person');

    for(let i = 0; i < buttons.length; i++) {

        let button = buttons[i];        
        
        button.classList.remove('active')
    }
}

const setStyleForActiveVisual = (buttonIndex) => {
    const buttons = document.getElementsByClassName('dot_person');

    for(let i = 0; i < buttons.length; i++) {
        let button = buttons[i];
        
        if(i === buttonIndex) {
            button.classList.add('active');  
            const rect = button.getBoundingClientRect();
            setPositionsOfPlayerNames();
            setVisualNameLabel(buttonIndex, rect.left);          
        }
        else {
            button.classList.remove('active');           
        }
    }
}

const handleVisualButtonClick = (buttonIndex) => {
    
    mainParams.activeVisualNum = buttonIndex;    
      
    if(buttonIndex === 0) {
        setActiveSection(0);
    } 
    else if((buttonIndex > 0) && (buttonIndex <= 4)) {
        setActiveSection(1);
    }
    else if((buttonIndex > 4) && (buttonIndex <= 7)) {
        setActiveSection(2);
    }
    else if((buttonIndex > 7) && (buttonIndex <= 10)) {
        setActiveSection(3);
    }
    else if((buttonIndex > 10) && (buttonIndex <= 12)) {
        setActiveSection(4);
    }
    else if((buttonIndex > 12) && (buttonIndex <= 13)) {
        setActiveSection(5);
    }
    else if((buttonIndex > 13) && (buttonIndex <= 16)) {
        setActiveSection(6);
    }
    

    hideIntroText();
    showVisual(buttonIndex); 
};

const handleSectionButtonClick = (buttonIndex) => {
    // console.log("clicked the section: " + buttonIndex);
    
    mainParams.activeSectionNum = buttonIndex;

    
    hideAllVisualLabels();
    hideAllVisualButtons();
    setActiveSection(buttonIndex);
    const titleDiv = document.getElementById("title");
    titleDiv.classList.remove("subheader_in");

    const mainContainer = document.getElementById('main_ui_content');

    if(mainContainer.classList.contains('just_2_rows') === true) {
        mainContainer.classList.remove('just_2_rows');
    }

    if((buttonIndex === 5) || (buttonIndex === 6)) {        
        if(mainContainer.classList.contains('has_big_text') !== true) {
            mainContainer.classList.add('has_big_text');
        }
    }
    else {
        if(mainContainer.classList.contains('has_big_text') === true) {
            mainContainer.classList.remove('has_big_text');
        }
    }

    if(buttonIndex === 7) {
        if(mainContainer.classList.contains('just_2_rows') !== true) {
            mainContainer.classList.add('just_2_rows');
        }
    }

    if(buttonIndex !== 7) {     
        const subheaderDiv = document.getElementById("subheader_text");
        subheaderDiv.classList.remove('visible');
    }

    setTimeout(() => {
        stopAllVisuals();
        // update the text
        swapIntroText(buttonIndex);
    }, 25);
}

const setVisualsButtons = () => {
    
    const sectionButtons = document.getElementsByClassName('dot_hover_section');    
    for(let i = 0; i < sectionButtons.length; i++) {        
        let sectionButton = sectionButtons[i];
        sectionButton.addEventListener('click', (pointerEvent) => {
            handleSectionButtonClick(i);
        });   
        const buttonBorder = sectionButton.parentElement.childNodes[0].childNodes[0];
        sectionButton.addEventListener('mouseenter', (pointerEvent) => {
            // gsap.to(buttonBorder, { duration: 0.15, backgroundSize: "16px 16px" })
            buttonBorder.style.backgroundSize = "16px 16px";
        });  
        // sectionButton.addEventListener('mousemoved', (pointerEvent) => {
        //     gsap.to(buttonBorder, { duration: 0.1, backgroundSize: "16px 16px" })
        // });  
        
        sectionButton.addEventListener('mouseleave', (pointerEvent) => {
            gsap.to(buttonBorder, { duration: 0.35, backgroundSize: "0px 0px", onComplete: () => {
                    buttonBorder.style.backgroundSize = "0px 0px";
                } 
            })
        });  
        
    }
    
    const peopleButtons = document.getElementsByClassName('dot_hover_person');    
    for(let y = 0; y < peopleButtons.length; y++) {        
        let peopleButton = peopleButtons[y];
        peopleButton.addEventListener('click', (pointerEvent) => {
            handleVisualButtonClick(y);
        }); 
        const peopleButtonBorder = peopleButton.parentElement.childNodes[0].childNodes[0];
        peopleButton.addEventListener('mouseenter', (pointerEvent) => {
            // gsap.to(peopleButtonBorder, { duration: 0.15, backgroundSize: "16px 16px" })
            peopleButtonBorder.style.backgroundSize = "16px 16px";
        });  
        // peopleButton.addEventListener('mousemoved', (pointerEvent) => {
        //     gsap.to(peopleButtonBorder, { duration: 0.15, backgroundSize: "16px 16px" })
        // });
        peopleButton.addEventListener('mouseleave', (pointerEvent) => {
            gsap.to(peopleButtonBorder, { duration: 0.35, backgroundSize: "0px 0px", onComplete: () => {
                    peopleButtonBorder.style.backgroundSize = "0px 0px";
                } 
            })
        });      
    }
};

setVisualsButtons();

const updatePlayerVisualOnResize = () => {
    const playerSection1 = document.getElementById("player_section1");
    const sectionWidth = playerSection1.offsetWidth;
    // console.log("Section width: " + sectionWidth); 

    
    setVisualsButtons(playerSection1);
};

const showPlayer = () => {

    mainParams.showingPlayer = true;
    // add the mini lines
    updatePlayerVisualOnResize(); ///////// NEEED THIS!!!!!

    
    
};

////// REMOVE
const showNextVisual = () => {    

    // remove the text
    const mainUIContent = document.getElementById("main_ui_content");
    removeAllChildNodes(mainUIContent);
    mainUIContent.classList.remove("container-2-row");
    mainUIContent.classList.remove("container-3-row");
    mainUIContent.classList.add("container-2-row-player");

    // add the UI        
    mainUIContent.innerHTML = MediaControl;
    showPlayer();    


    mainParams.showingVisual = true;

    //camera.z = 20.0;
}

const setPositionsOfPlayerNames = () => {
    const buttons = document.getElementsByClassName('dot_person');         
    const labels = document.getElementsByClassName('media_player_name_container');
    for(let i = 0; i < buttons.length; i++) {
        let button = buttons[i];  
        const rect = button.getBoundingClientRect();        
        const label = labels[i];
        const mediaLabelsArea = document.getElementById('media_bar_names_area');
        const mediaLabelsAreaRect = mediaLabelsArea.getBoundingClientRect();
        const areaX = mediaLabelsAreaRect.left;
        const labelRect = label.getBoundingClientRect();
        const labelWidth = labelRect.right - labelRect.left;
        const labelPos = rect.left - areaX - (labelWidth / 2.0) + 9; // 12 is 1/2 width of the dot 

        label.style.left = labelPos + 'px';
        // setVisualNameLabel(i, rect.left);   
    }
}

const swapLanguageControls = () => {
    const enControl = document.getElementById('cc_english_selector');
    const frControl = document.getElementById('cc_french_selector');
    const enMobileControl = document.getElementById('mobile_lang_picker_item_english');
    const frMobileControl = document.getElementById('mobile_lang_picker_item_french');
    
    if(activeLanguage === EN) {
        enControl.classList.add('cc_language_selector_active');
        frControl.classList.remove('cc_language_selector_active');
        enMobileControl.classList.add('mobile_lang_picker_item_active');
        frMobileControl.classList.remove('mobile_lang_picker_item_active');
    } else {
        enControl.classList.remove('cc_language_selector_active');
        frControl.classList.add('cc_language_selector_active');
        enMobileControl.classList.remove('mobile_lang_picker_item_active');
        frMobileControl.classList.add('mobile_lang_picker_item_active');
    }

    // just make the CC button text active on picking the language
    const ccButtonText = document.getElementById('cc_button_text');    
    ccButtonText.classList.add('active');

    // and hide the overlay
    const ccLanguageBG = document.getElementById("cc_language_overlay");
    ccLanguageBG.classList.remove('visible');

    // and make sure that the cc is enabled
    if(mainParams.showingVisual == true)
        setCCActive(true);
}

const swapLanguageControlsMobile = () => {
    const enMobileControl = document.getElementById('mobile_lang_picker_item_english');
    const frMobileControl = document.getElementById('mobile_lang_picker_item_french');
    
    if(activeLanguage === EN) {       
        enMobileControl.classList.add('mobile_lang_picker_item_active');
        frMobileControl.classList.remove('mobile_lang_picker_item_active');
    } else {       
        enMobileControl.classList.remove('mobile_lang_picker_item_active');
        frMobileControl.classList.add('mobile_lang_picker_item_active');
    }

}

const handleLanguageChange = (language) => {
    mainParams.showingCaptions = true;
    
    if(language === EN) {
        if(activeLanguage === FR) {
            activeLanguage = EN;            
        }
    } else {
        if(activeLanguage === EN) {
            activeLanguage = FR;
        }
    }    

    // IF desktop, swap the language controls
    if(isMobile() !== true)
        swapLanguageControls();
    else 
        swapLanguageControlsMobile();

    if(mainParams.showingVisual == true)
        replayActiveVisual();
}

const showMediaPlayerOnPage = () => {

    const supplBar = document.getElementById('supplementary_bar');
    supplBar.classList.add('visible');
    const mediaBar = document.getElementById('media_bar');
    mediaBar.classList.add('visible');

    // attach the click listeners to buttons
    const pauseButton = document.getElementById("pause_button");

    if(pauseButton !== null) {        
        pauseButton.addEventListener('click', () => {
        
            if(mainParams.shortSoundSet === true) {
                shortSound.play();
            }

            if(mainParams.isRunning == true) { // pause
                pauseButton.classList.add("paused");
                mainParams.isRunning = false;
                pauseButton.classList.remove("playing_hover");
                pauseButton.classList.add("paused_hover");
                pauseAudio();
            }
            else {
                pauseButton.classList.remove("paused");
                mainParams.isRunning = true;
                pauseButton.classList.add("playing_hover");
                pauseButton.classList.remove("paused_hover");

                resumeAudio();
            }
        });

        pauseButton.addEventListener('mouseenter', () => {        
            if(mainParams.isRunning == true) { // pause
                pauseButton.classList.add("playing_hover");               
            }
            else {
                pauseButton.classList.add("paused_hover");
            }            
        });

        pauseButton.addEventListener('mouseleave', () => {        
            if(mainParams.isRunning == true) { // pause
                pauseButton.classList.remove("playing_hover");                
            }
            else {
                pauseButton.classList.remove("paused_hover");
            }            
        });

        const audioButton = document.getElementById("audio_button");
        if(audioButton !== null) {
            audioButton.addEventListener('click', () => {
        
                if(mainParams.volumeOn == true) { // pause
                    audioButton.classList.add("off");
                    mainParams.volumeOn = false;
                    if(activeVisual !== null) {
                        activeVisual.disableAudio();
                    }
                   
                    if(sectionSound !== null) {
                        if(sectionSound.isPlaying) {
                            sectionSound.stop();
                        }
                    }                    
                }
                else {
                    if(mainParams.shortSoundSet === true) {
                        shortSound.play();
                    }

                    audioButton.classList.remove("off");
                    mainParams.volumeOn = true;
                    if(activeVisual !== null) {
                        activeVisual.enableAudio();
                    }

                    if(mainParams.isShowingIntro === true) {
                        if(sectionSound !== null) {                            
                            sectionSound.play();                            
                        }
                    }
                }
            });
        }

        const ccButton = document.getElementById("cc_button");
        const ccButtonText = document.getElementById("cc_button_text");
        const ccLanguageBG = document.getElementById("cc_language_overlay");
        ccButtonText.addEventListener('click', () => {
            if(mainParams.showingCaptions === true) {
                ccButtonText.classList.remove('active');
                setCCActive(false);
            }
            else {
                ccButtonText.classList.add('active');
                setCCActive(true);
            }
        });

        ccButton.addEventListener('mouseover', () => {
            ccLanguageBG.classList.add('visible');
        });

        ccButton.addEventListener('mouseleave', () => {
            ccLanguageBG.classList.remove('visible');
        });

        const enControl = document.getElementById('cc_english_selector');
        const frControl = document.getElementById('cc_french_selector');     

        enControl.addEventListener('click', () => {
            handleLanguageChange(EN);
        });

        frControl.addEventListener('click', () => {
            handleLanguageChange(FR);
        });
    }    

    const eyeButton = document.getElementById("eye_button");
    eyeButton.addEventListener('mouseenter', () => {
        eyeButton.classList.add('hover');
    })
    eyeButton.addEventListener('mouseleave', () => {
        eyeButton.classList.remove('hover');
    })
}

const setCCActive = (isActive) => {
    mainParams.showingCaptions = isActive;
    // console.log("Is CC active: " + mainParams.showingCaptions);
    if(mainParams.isShowingIntro === false) {
        if(isActive) {
            if(isMobile() !== true)
                document.getElementById("captions_container").classList.add('visible'); 
            else 
                document.getElementById("mobile_cc_bar").classList.add('visible');           
        }
        else {
            if(isMobile() !== true)
                document.getElementById("captions_container").classList.remove('visible');
            else
                document.getElementById("mobile_cc_bar").classList.remove('visible');
        }
    }
}

// showMediaPlayerOnPage(); ////////// REMOVE

const startExperience = () => {

    // stop intro audio if necessary
    if(mainParams.introPageSoundSet) {
        if(introSound.isPlaying === true)
            introSound.stop();
    }

    mainParams.startedExperience = true;

    document.getElementById('enable_sound_button').classList.add('not_on_page');
    document.getElementById('next_button_section').classList.remove("not_on_page");

    if(isMobile() === true) {
        // hide desktop things
        hideDesktopThings();
        injectMobile();
    }

    startVisuals();
}

const startVisuals = () => {
    
    mainParams.currentVisualIndex = 0;

    if(mainParams.introPageSoundSet === true) {
        if(introSound !== null) {
            if(introSound.isPlaying)
                introSound.stop();
        }
    }        

    setActiveSection(0);
    swapIntroText(0);
    showMediaPlayerOnPage();
}

const showRevealPanel = () => {
    document.getElementById('more_text_panel').classList.add('visible');
    

    const nextText = RevealText[mainParams.activeSectionNum].text;
    document.getElementById("reveal_text_h3").innerHTML = nextText;

    if(isMobile() === true) {
        gsap.to('#reveal_panel_background', { duration: 0.5, left: "0px", ease: "expo.out" })
        gsap.to('#reveal_text', { 
            duration: 0.5, opacity: 1.0, delay: -0.5, ease: "expo.out", delay: -0.25,        
        });
    }
    else {
        const size = window.innerWidth * 3; 
        const newLeft = window.innerWidth - size / 2;   
        const newTop = window.innerWidth - size / 2;
        
        gsap.to('#reveal_panel_background', { duration: 0.5, left: newLeft, top: newTop })
        gsap.to('#reveal_text', { 
            duration: 0.5, opacity: 1.0, delay: -0.5, ease: "expo.out", delay: -0.25,        
        });
    }
    
    mainParams.isShowingRevealPanel = true;

    if(mainParams.shortSoundSet === true) {
        shortSound.play();
    }
}

const onPanelRevealDone = () => {
    document.getElementById('more_text_panel').classList.remove('visible');
    mainParams.isShowingRevealPanel = false;
}

const hideRevealPanel = () => {
    
    if(isMobile() === true) {
        gsap.to('#reveal_panel_background', { 
            duration: 0.5, left: "100%", ease: "expo.out"
        })
        gsap.to('#reveal_text', { 
            duration: 0.5, opacity: 0.0, ease: "expo.out", delay: -0.25,
            onComplete: onPanelRevealDone        
        });
    }
    else {
        const size = window.innerWidth * 2;    
    
        gsap.to('#reveal_panel_background', { 
            duration: 1.0, left: window.innerWidth - size / 5, top: window.innerWidth - size / 5, ease: "expo.out"
        })
        gsap.to('#reveal_text', { 
            duration: 1.0, opacity: 0.0, ease: "expo.out", delay: -0.25,
            onComplete: onPanelRevealDone        
        });
    }
    
    if(mainParams.shortSoundSet === true) {
        shortSound.play();
    }
}

const setRevealPanelPosition = () => {
    if(isMobile() === false) {
        if(mainParams.isShowingRevealPanel !== true) {
            const revealPanelBG = document.getElementById('reveal_panel_background');
            const size = window.innerWidth * 2;
            const left = window.innerWidth - size / 5;
            const top = window.innerHeight - size / 5;
            revealPanelBG.style.width = size + 'px';
            revealPanelBG.style.minWidth = size + 'px';
            revealPanelBG.style.height = size + 'px';
            revealPanelBG.style.minHeight = size + 'px';
            revealPanelBG.style.left = left + 'px';
            revealPanelBG.style.top = top + 'px';
        }
        else {
            const revealPanelBG = document.getElementById('reveal_panel_background');
            const size = window.innerWidth * 3; 
            const left = window.innerWidth - size / 2;
            const top = window.innerWidth - size / 2;
            revealPanelBG.style.width = size + 'px';
            revealPanelBG.style.minWidth = size + 'px';
            revealPanelBG.style.height = size + 'px';
            revealPanelBG.style.minHeight = size + 'px';
            revealPanelBG.style.left = left + 'px';
            revealPanelBG.style.top = top + 'px';
        }
    }
}

setRevealPanelPosition();

const setInfoPanelPosition = () => {
    if(isMobile() !== true) {
        if(mainParams.isShowingInfoPanel !== true) {
            const infoPanelBG = document.getElementById('info_panel_background');
            const size = window.innerWidth * 2;
            const left = 0 - size / 1.2;
            const top = window.innerHeight - size / 5;
            infoPanelBG.style.width = size + 'px';
            infoPanelBG.style.minWidth = size + 'px';
            infoPanelBG.style.height = size + 'px';
            infoPanelBG.style.minHeight = size + 'px';
            infoPanelBG.style.left = left + 'px';
            infoPanelBG.style.top = top + 'px';
        }
        else { // showing
            const infoPanelBG = document.getElementById('info_panel_background');
            const size = window.innerWidth * 3;
            const left = window.innerWidth - size / 2;
            const top = window.innerWidth - size / 2;
            infoPanelBG.style.width = size + 'px';
            infoPanelBG.style.minWidth = size + 'px';
            infoPanelBG.style.height = size + 'px';
            infoPanelBG.style.minHeight = size + 'px';
            infoPanelBG.style.left = left + 'px';
            infoPanelBG.style.top = top + 'px';
        }
    }
    else {
        const infoPanelBG = document.getElementById('info_panel_background');
        infoPanelBG.style.height = '100%';
        infoPanelBG.style.top = '0px';
    }
}

setInfoPanelPosition();

const showInfoPanel = () => {
    document.getElementById('info_panel').classList.add('visible');
    mainParams.isShowingInfoPanel = true;

    if(isMobile() !== true) {    
        const size = window.innerWidth * 3; 
        const newLeft = window.innerWidth - size / 2;   
        const newTop = window.innerWidth - size / 2;
        
        gsap.to('#info_panel_background', { duration: 0.5, left: newLeft, top: newTop })
        gsap.to('#info_text', { 
            duration: 0.5, opacity: 1.0, delay: -0.25, ease: "expo.out"        
        });
    } else {
        // need to hide mobile menu
        hideMobileMenu();

        gsap.to('#info_panel_background', { duration: 0.5, left: 0, ease: "expo.out"  })
        gsap.to('#info_text', { 
            duration: 0.5, opacity: 1.0, delay: -0.25, ease: "expo.out"        
        });
    }

    if(mainParams.shortSoundSet === true) {
        shortSound.play();
    }
}

const onInfoRevealDone = () => {
    document.getElementById('info_panel').classList.remove('visible');
    mainParams.isShowingInfoPanel = false;
}

const hideInfoPanel = () => {
    if(isMobile() !== true) {
        const size = window.innerWidth * 2;
        const left = 0 - size / 1.1;
        const top = window.innerHeight - size / 5;    
        
        gsap.to('#info_panel_background', { 
            duration: 0.65, left: left, top: top, ease: "expo.out",
        })
        gsap.to('#info_text', { 
            duration: 0.65, opacity: 0.0, ease: "expo.out", delay: -0.25,
            onComplete: onInfoRevealDone        
        });
    } else {
        const targetLeft = window.innerWidth * -1;
        gsap.to('#info_panel_background', { 
            duration: 0.5, left: targetLeft, ease: "expo.out"
        })
        gsap.to('#info_text', { 
            duration: 0.65, opacity: 0.0, ease: "expo.out", delay: -0.25,
            onComplete: onInfoRevealDone        
        });
    }

    if(mainParams.shortSoundSet === true) {
        shortSound.play();
    }
}

const showMobileMenu = () => {    
    const mobileMenu = document.getElementById("mobile_menu_container");
    mobileMenu.classList.add("visible");
    gsap.to(mobileMenu, { 
        duration: 0.5, left: 0, ease: "expo.out"
    })
}

const hideMobileMenu = () => {
    const mobileMenu = document.getElementById("mobile_menu_container");
    const targetLeft = window.innerWidth * .7 * -1;
    gsap.to(mobileMenu, { 
        duration: 0.5, left: targetLeft, ease: "expo.out",
        onComplete: function() {
            mobileMenu.classList.remove("visible");
        }
    })   
}

const hideDesktopThings = () => {
    const mediaBar = document.getElementById("media_bar");
    mediaBar.classList.add("hidden");
    mediaBar.classList.remove("visible");
};

const showDesktopThings = () => {

    // console.log("Showing desktop things...");
    const mediaBar = document.getElementById("media_bar");
    mediaBar.classList.remove("hidden");
    mediaBar.classList.add("visible");
};

/**
 * Mobile stuff
 */

const injectMobile = () => {

    const mediaPlayerMobile = document.getElementById("media_player_mobile");
    const mobileString = `
        <div class="media_player_mobile_nav off_page" id="media_player_mobile_nav"> 
            <ul class="mobile_media_player">
                <li class="section_label mobile_player_control selected"><span class="li_dot"></span>Introduction</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JOSEPH</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Incarceration</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>MICHELLE</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>OMAR</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JONATHAN</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JOSEPH</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>No End in Sight</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>CHARLES</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JOHN</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JASON</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Solitary Confinement</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>MICHELLE</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>KEN</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>JOSEPH</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Systemic Abuse</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>USMAN</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>AMINA</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Racism and Discrimination</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>ABDELRAHMAN</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Life After Detention</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>KARIM</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>TIFFANY</li>
                <li class="person_label mobile_player_control"><span class="li_dot"></span>AMINA</li>

                <li class="section_label mobile_player_control"><span class="li_dot"></span>Take Action</li>
            </ul>
        </div>
        <div class="mobile_media_player_bar">
            <div class="mobile_play_button_wrapper">
                <div class="mobile_play_button" id="play_button_mobile"></div>
            </div>
            
            <div class="mobile_section_label"><p id="mobile_section_label_p">Introduction</p></div>
            <div class="mobile_person_label hidden" id="mobile_person_label"><span class="dot"></span><p id="mobile_person_label_p">Abdelrahman</p></div>                    
            <div class="mobile_eye_button_wrapper">
                <div id="eye_button_mobile"></div>   
            </div>  
            <div class="mobile_expand_control_wrapper">
                <div class="mobile_expand_control" id="mobile_expand_control"></div>
            </div>  
                                     
        </div> 
        <div class="mobile_cc_bar" id="mobile_cc_bar">
            <p id="captions_container_mobile_p"></p>
            <div id="captions_buffer_mobile"><p id="captions_buffer_mobile_p"></p></div>
        </div>       
    `;

    mediaPlayerMobile.innerHTML = mobileString;

    let barPos = window.innerHeight - 490;
    mediaPlayerMobile.style.top = barPos + "px";
    mediaPlayerMobile.style.height = '40px';

    setTimeout(() => {
        const showMobileMediaPlayerButton = document.getElementById("mobile_expand_control");
        showMobileMediaPlayerButton.addEventListener('click', () => {    
            if(mainParams.isShowMobileMediaPlayer === true) { // hide player
                collapseMobilePlayer();
            } else{
                expandMobilePlayer();
            }
        });

        mediaPlayerMobile.classList.remove("hidden");
    }, 0.01);    

    const mobPlayButt = document.getElementById('play_button_mobile');
    mobPlayButt.addEventListener('click', () => {
        if(mainParams.shortSoundSet === true) {
            shortSound.play();
        }

        if(mainParams.isRunning == true) { // pause
            mobPlayButt.classList.add("paused");            
            mainParams.isRunning = false;            
            pauseAudio();
        }
        else {
            mobPlayButt.classList.remove("paused");            
            mainParams.isRunning = true;
            resumeAudio();
        }
    });

    const mobRevealButton = document.getElementById('eye_button_mobile');
    mobRevealButton.addEventListener('click', () => {
        if(mainParams.isShowingRevealPanel === true) {
            hideRevealPanel();
        } else {
            showRevealPanel();
        }
    });

    // show other mobile buttons
    document.getElementById("menu_button_mobile").classList.remove('hidden');

    setMobilePlayerSectionControls();
    setMobilePersonControls();

    let personNum = -1;
    if(mainParams.activeSectionNum !== 0)
        personNum = mainParams.activeVisualNum;
    setMobileMediaBar(mainParams.activeSectionNum, personNum);
   

}

const extractMobile = () => {
    // const showMobileMediaPlayerButton = document.getElementById("mobile_expand_control");
    // if(showMobileMediaPlayerButton !== null)
    //     showMobileMediaPlayerButton.removeEventListener('click');
    
    const mediaPlayerMobile = document.getElementById("media_player_mobile");
    mediaPlayerMobile.innerHTML = "";

    mediaPlayerMobile.classList.add("hidden");

    
}

const mediaPlayerMobile = document.getElementById("media_player_mobile");


const collapseMobilePlayer = () => {
    const mobileMediaPlayerNav = document.getElementById("media_player_mobile_nav"); 
    const expandControl = document.getElementById("mobile_expand_control");    
    gsap.to(mobileMediaPlayerNav, { duration: 0.5, top: `450px`, ease: "expo.out", 
        onComplete: function() {
            mobileMediaPlayerNav.classList.add("off_page");
        }
    });
    mainParams.isShowMobileMediaPlayer = false;
    expandControl.classList.remove("expanded");
}

const expandMobilePlayer = () => {
    const mobileMediaPlayerNav = document.getElementById("media_player_mobile_nav"); 
    const expandControl = document.getElementById("mobile_expand_control");
    mobileMediaPlayerNav.classList.remove("off_page");
    gsap.to(mobileMediaPlayerNav, { duration: 0.5, top: `0px`, ease: "expo.out"});
    mainParams.isShowMobileMediaPlayer = true;
    expandControl.classList.add("expanded");
}

const deselectAllControls = () => {
    const mobilePlayerControls = document.getElementsByClassName("mobile_player_control");
    for(let i = 0; i < mobilePlayerControls.length; i++) {
        mobilePlayerControls[i].classList.remove('selected');
    }
}

const setMobilePlayerSectionControls = () => {
    const mobileSectionControls = document.getElementsByClassName("section_label");
    let index = 0;
    for(let i = 0; i < mobileSectionControls.length; i++) {
        const sectionControl = mobileSectionControls[i];
        sectionControl.addEventListener('click', (pointerEvent) => {
            deselectAllControls();
            setMobileMediaBar(i);
            handleSectionButtonClick(i);
            sectionControl.classList.add('selected');
            collapseMobilePlayer();            
        });
        // change the styling
        index++;
    }
};

const setActiveVisualIndicator = (visualNum) => {
    deselectAllControls();
    const mobilePersonControls = document.getElementsByClassName("person_label");
    for(let i = 0; i < mobilePersonControls.length; i++) {
        const personControl = mobilePersonControls[i];
        if(i === visualNum) {
            personControl.classList.add('selected');
        }
    }
}

const setMobilePersonControls = () => {
    const mobilePersonControls = document.getElementsByClassName("person_label");
    let index = 0;
    for(let i = 0; i < mobilePersonControls.length; i++) {
        const personControl = mobilePersonControls[i];
        personControl.addEventListener('click', (pointerEvent) => {
            deselectAllControls();
            setMobileMediaBar(-1, i);
            handleVisualButtonClick(i);
            personControl.classList.add('selected');
            collapseMobilePlayer();            
        });
        // change the styling
        index++;
    }
};

const setMobilePlayerControls = (sectionNum, visualNum) => {
   
    //deselect all
    const mobilePlayerControls = document.getElementsByClassName('mobile_player_control');
    for(let i = 0; i < mobilePlayerControls.length; i++) {
        mobilePlayerControls[i].classList.remove('selected');
    }
    
    // set section if appropriate
    if(sectionNum !== -1) {
        const sectionLabels = document.getElementsByClassName('section_label');
        for(let a = 0; a < sectionLabels.length; a++) {
            if(a == sectionNum)
                sectionLabels[a].classList.add('selected');
        }
    }

    if(visualNum !== -1) {
        const personLabels = document.getElementsByClassName('person_label');
        for(let b = 0; b < personLabels.length; b++) {
            if(b == visualNum)
                personLabels[b].classList.add('selected');
        }
    }
}

const setMobileMediaBar = (sectionIndex = -1, personIndex = -1) => {

    // console.log("clicked: " + personIndex)
    const sectionNames = [ "Introduction", "Incarceration", "No End in Sight", "Solitary Confinement",
        "Abuses by Border Officers", "Racism and Discrimination", "Life After Detention", "Take Action"
    ];
    const peopleNames = [ "Joseph", "Michelle", "Omar", "Jonathan", "Joseph", "Charles", "John", "Jason",
        "Michelle", "Ken", "Joseph", "Usman", "Amina", "Abdelrahman", "Karim", "Tiffany", "Amina"
    ];
    const sectionLabel = document.getElementById("mobile_section_label_p");
    const personLabelWrapper = document.getElementById("mobile_person_label");
    const personLabel = document.getElementById("mobile_person_label_p");

    if(sectionIndex !== -1)
        sectionLabel.innerHTML = sectionNames[sectionIndex];

    if(personIndex === -1) {
        personLabelWrapper.classList.add("hidden");
    }
    else {
        personLabelWrapper.classList.remove("hidden");
        personLabel.innerHTML = peopleNames[personIndex];

        if(personIndex === 0) sectionIndex = 0;
        else if((personIndex > 0) && (personIndex < 5)) sectionIndex = 1;
        else if((personIndex >= 5) && (personIndex < 8)) sectionIndex = 2;
        else if((personIndex >= 8) && (personIndex < 11)) sectionIndex = 3;
        else if((personIndex >= 11) && (personIndex < 13)) sectionIndex = 4;
        else if((personIndex >= 13) && (personIndex < 14)) sectionIndex = 5;
        else if((personIndex >= 14) && (personIndex < 17)) sectionIndex = 6;

        sectionLabel.innerHTML = sectionNames[sectionIndex];
    }
}

const unhideMediaPlayer = () => {
    if(window.matchMedia('(min-width: 750px)').matches) {
        const mediaElements = document.getElementsByClassName("med_bar_hideable_area");
        for(let i = 0; i < mediaElements.length; i++) {
            mediaElements[i].classList.remove("not_visible");
        }

        const mediaBar = document.getElementById("media_bar");
        mediaBar.classList.add("black_background");
    }
}

const hideMediaPlayer = () => {
    if(window.matchMedia('(min-width: 750px)').matches) {
        const mediaElements = document.getElementsByClassName("med_bar_hideable_area");
        for(let i = 0; i < mediaElements.length; i++) {
            mediaElements[i].classList.add("not_visible");
        }

        const mediaBar = document.getElementById("media_bar");
        mediaBar.classList.remove("black_background");
    }       
}

const handleOrientationChange = (isPortrait) => {
    console.log("Is portrait:" + isPortrait);
    const mobileRotatePanel = document.getElementById('mobile_rotate_panel');
    if(isPortrait !== true) {
        mobileRotatePanel.classList.add('visible');
    } else {
        mobileRotatePanel.classList.remove('visible');
    }
}

document.addEventListener('mousemove', (event) => {
    
    mainParams.lastMoueMoveTime = 0.0;
    unhideMediaPlayer();
})

/** 
 * Event Listeners 
*/
window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // update the player visual if necessary
    if(mainParams.showingPlayer == true) {
        updatePlayerVisualOnResize();
        
    }   
    
    if(mainParams.showingVisual == true) {
        if(activeVisual != null) {
            activeVisual.changeSizes(sizes);
        }
        setPositionsOfPlayerNames();
    }

    if(mainParams.startedExperience) {
        if(isMobile() === true) {
            // console.log("It IS Mobile...");
            hideDesktopThings();
            injectMobile();
        } else {
            // console.log("It is NOT mobile");
            showDesktopThings();
            extractMobile();
        }
    }

    setInfoPanelPosition();
    setRevealPanelPosition();
});

window.addEventListener("orientationchange", function() {
    const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
	// Announce the new orientation number
	// console.log("Screen orientation: " + orientation)
    let isPortrait = true;
    if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
        isPortrait = true;
    }
    else {
        isPortrait = false;
    }

    handleOrientationChange(isPortrait);

}, false);

const infoButton = document.getElementById('info_button');
infoButton.addEventListener('click', () => {
    showInfoPanel();
});

const menuButtonMobile = document.getElementById('menu_button_mobile');
menuButtonMobile.addEventListener('click', () => {
    showMobileMenu();
});

const closeMobileMenuButton = document.getElementById('mobile_menu_close_button');
closeMobileMenuButton.addEventListener('click', () => {
    hideMobileMenu();
});

const playAudioCheckbox = document.getElementById('play_audio_checkbox');
playAudioCheckbox.addEventListener('click', () => {
    console.log("Clicked the play audio button. isChecked: " + playAudioCheckbox.checked);

    if(playAudioCheckbox.checked == false) { // pause
        // audioButton.classList.add("off");
        mainParams.volumeOn = false;
        if(activeVisual !== null) {
            activeVisual.disableAudio();
        }
       
        if(sectionSound !== null) {
            if(sectionSound.isPlaying) {
                sectionSound.stop();
            }
        }                    
    }
    else {
        if(mainParams.shortSoundSet === true) {
            shortSound.play();
        }

        // audioButton.classList.remove("off");
        mainParams.volumeOn = true;
        if(activeVisual !== null) {
            activeVisual.enableAudio();
        }

        if(mainParams.isShowingIntro === true) {
            if(sectionSound !== null) {                            
                sectionSound.play();                            
            }
        }
    }
});

const playCCCheckbox = document.getElementById('show_captions_checkbox');
playCCCheckbox.addEventListener('click', () => {
    
    const ccBarMobile = document.getElementById('mobile_cc_bar');

    if(playCCCheckbox.checked !== true) {
        ccBarMobile.classList.remove('visible');
        setCCActive(false);
    }
    else {
        ccBarMobile.classList.add('visible');
        setCCActive(true);
    }

});

const enButtonMobile = document.getElementById('mobile_lang_picker_item_english');
const frButtonMobile = document.getElementById('mobile_lang_picker_item_french');
enButtonMobile.addEventListener('click', () => {
    handleLanguageChange(EN);
});
frButtonMobile.addEventListener('click', () => {
    handleLanguageChange(FR);
});

const infoButtonMobile = document.getElementById('info_button_mobile');
infoButtonMobile.addEventListener('click', () => {
    showInfoPanel();
});

const closeInfoButton = document.getElementById('close_info_button');
closeInfoButton.addEventListener('click', () => {
    hideInfoPanel();
});

const reveal_button = document.getElementById('eye_button');
reveal_button.addEventListener('click', () => {
    showRevealPanel();
});

const closeRevealButton = document.getElementById("close_reveal_button");
closeRevealButton.addEventListener('click', () => {
    hideRevealPanel();
});

const nextButton = document.getElementById('next_button');
// nextButton.classList.add("active");
nextButton.addEventListener('click', () => {
    if(nextButton.classList.contains('active')) {
        hideIntroText();
        let nextVisualNum = 0;
        if(mainParams.activeSectionNum === 0) {
            nextVisualNum = 0;
        } else if(mainParams.activeSectionNum === 1) {
            nextVisualNum = 1
        } else if(mainParams.activeSectionNum === 2) {
            nextVisualNum = 5
        } else if(mainParams.activeSectionNum === 3) {
            nextVisualNum = 8
        } else if(mainParams.activeSectionNum === 4) {
            nextVisualNum = 11
        } else if(mainParams.activeSectionNum === 5) {
            nextVisualNum = 13
        } else if(mainParams.activeSectionNum === 6) {
            nextVisualNum = 14
        }

        mainParams.activeVisualNum = nextVisualNum;
        showVisual(nextVisualNum);

        if(isMobile() === true) {
            setActiveVisualIndicator(nextVisualNum);
            setMobileMediaBar(mainParams.activeSectionNum, nextVisualNum);        
        }
    }
});

const nextButtonWrapper = document.getElementById('next_button_wrapper');

nextButton.addEventListener('mouseenter', () => {
    if(mainParams.showingVisual !== true) {
        if(nextButton.classList.contains('active')) {
            //startButton.classList.add('button_hover');
            nextButtonWrapper.classList.add('hover');

            const nextButtonRect = nextButtonWrapper.getBoundingClientRect();
            const nextButtonWidth = nextButtonRect.width;
            if(isMobile() === false)
                gsap.to("#next_button_wrapper", { duration: 0.35, backgroundSize: `${nextButtonWidth}px 1px` })
        }
    }
});
nextButton.addEventListener('mouseleave', () => {   
    if(isMobile() === false) {
        gsap.to("#next_button_wrapper", { 
            duration: 0.35, backgroundSize: `0px 1px`,
            onComplete: () => {
                nextButtonWrapper.classList.remove('hover');
            } 
        });
    }
    //startButton.classList.remove('button_hover');
});

const playAudioButton = document.getElementById("enable_sound_button");

playAudioButton.addEventListener('mousedown', () => {
    playAudioButton.classList.remove('active');
    playAudioButton.classList.add('mouseDown');
});

playAudioButton.addEventListener('mouseup', () => { 
    
    playAudioButton.classList.remove('mouseDown');
    playAudioButton.classList.add('mouseUp');

    if(mainParams.introPageSoundSet) {
        introSound.setLoop(true);
        introSound.play();
    }

});

const disclaimerButtonWrapper = document.getElementById('disclaimer_button_wrapper');

disclaimerButtonWrapper.addEventListener('click', () => {
    // hide disclaimer page
    document.getElementById('disclaimer_panel').classList.remove('visible');

    // show main ui
    const mainUI = document.getElementById('main_ui_content');
    mainUI.classList.remove('not_on_page');

    // start experience
    startExperience();
})

disclaimerButtonWrapper.addEventListener('mouseenter', () => {
    if(mainParams.showingVisual !== true) {
        //startButton.classList.add('button_hover');
        disclaimerButtonWrapper.classList.add('hover');

        const disclaimerButtonRect = disclaimerButtonWrapper.getBoundingClientRect();
        const disclaimerButtonWidth = disclaimerButtonRect.width;
        if(isMobile() === false)
            gsap.to("#disclaimer_button_wrapper", { duration: 0.35, backgroundSize: `${disclaimerButtonWidth}px 1px` })
    }
});
disclaimerButtonWrapper.addEventListener('mouseleave', () => {   
    if(isMobile() === false) {
        gsap.to("#disclaimer_button_wrapper", { 
            duration: 0.35, backgroundSize: `0px 1px`,
            onComplete: () => {
                disclaimerButtonWrapper.classList.remove('hover');
            } 
        });
    }
    //startButton.classList.remove('button_hover');
});

/**
 * Animate
 */
const clock = new THREE.Clock()

let lastTimeCheck = 0.0;

const update = () =>
{        
    if(mainParams.isRunning == true) {
        
        const elapsedTime = clock.getElapsedTime();
        const deltaTime = elapsedTime - lastTimeCheck;
        lastTimeCheck = elapsedTime;

        if(mainParams.isShowingIntro == true) {
            if(mainParams.hasIntroPlayed == false) {
                updateIntroTextAnimation(deltaTime);
            }
            else {
                mainParams.introTimer += deltaTime;
                if(mainParams.introTimer >= mainParams.currentIntroTimerLength) {
                    mainParams.showingPlayer = false;
                    mainParams.isShowingIntro = false;
                    // advance the visual
                    //showNextVisual(); /// NEED THIS!!!
                }
            }
        }

        if(mainParams.showingVisual == true) {
            if(activeVisual != null) {
                activeVisual.update(deltaTime, elapsedTime);
            }

            mainParams.activeVisualTimer += deltaTime;
            if(mainParams.activeVisualTimer >= mainParams.activeVisualMaxTimer) {
                handleEndOfCurrentVisual();
            }
        }

        // update captions
        // if(mainParams.showingCaptions === true) {
            if(captionsManager !== null) {
                captionsManager.update(deltaTime, mainParams.activeVisualTimer);
                
                if(captionsManager.hasNextWord(mainParams.activeVisualTimer) == true) {
                    const nextWordObj = captionsManager.getNextWord();
                    if(nextWordObj !== null) {
                        let activeP; 
                        if(isMobile() !== true)
                            activeP = document.getElementById("captions_container_p");
                        else 
                            activeP = document.getElementById("captions_container_mobile_p");

                        const allCurrentSpans = document.getElementsByClassName("captions_text_fadeable");
                        for(let w = 0; w < allCurrentSpans.length; w++) {
                            const currSpan = allCurrentSpans[w];
                            currSpan.classList.remove("captions_text_fadeable");
                            currSpan.classList.remove("captions_text_fade_in");
                        }

                        if(mainParams.showingCaptions) { 
                            if(nextWordObj.isNewLine === true) { 
                                activeP.innerHTML = `<span class="captions_text_fadeable captions_text_fade_in"> ${nextWordObj.word}</span>`;
                            } else {                            
                                let activeText = activeP.innerHTML;  
                                                        
                                    activeText += `<span class="captions_text_fadeable captions_text_fade_in"> ${nextWordObj.word}</span>`;
                                    activeP.innerHTML = activeText;
                            }
                        }
                    }
                       
                }      
            }

            if(mainParams.showingCaptions === false) {
                // handle mouse stuff
                mainParams.lastMoueMoveTime += deltaTime;
                if(mainParams.lastMoueMoveTime >= mainParams.maxIdleMouseTime) {
                    
                    hideMediaPlayer();
                }
            }
        // }
    }
    
    // Update controls
    //controls.update()

    // Render
    // renderer.render(scene, camera)
    if(activeVisual != null) {
        activeVisual.render();
    }

    //stats.end();

    // Call tick again on the next frame
    window.requestAnimationFrame(update)
}


update()

