import * as THREE from 'three'
import vertexShader from './../shaders/cages/vertex.glsl'
import fragmentShader from './../shaders/cages/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Cages extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 0.0,
            cameraZ: -0.5,
            numLines: 1800,
            radius: 1.0,
            additionalRadius: 0.25,
            numPointsInLine: 20,
            cubeRotationSpeedDiv: 7.0, // higher number = slower speed
           
            surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
            depthColor: new THREE.Vector3(0.105, 0.105, 0.105),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),

            doingHeave: 0.0,
            heaveMagnitude: 0.0,
            doingInternalRoation: 0.0, // NOTE: you need to set this to 1.0 to have Jitter OR Swirl    
            doingJitter: 0.0,
            doingSwirl: 0.0,
            swirlSpeed: 1.0,
            jitterOn: false,
            jitterTimer: 0.0,
            jitterMaxTimer: 0.065,
            repeatJitterCount: 0,
            repeatJitterCountMax: 5,
            jitterPauseTimer: 0.0,
            jitterPauseTimerMax: 3.0,
            jitterPaused: false
        };

        this.lines = [];
        this.lineCube = null;
        this.water = null;
        this.geometry = null;
        this.material = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.15;
        this.unrealBloomPass.radius = 0.15;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1               
                startTime: 0.0,
                hasCameraChange: false,
                cameraZ: -0.5,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 0.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 0.0,
                doingJitter: 0.0,
                doingSwirl: 0.0,
                swirlForward: false,
                swirlSpeed: 0.0,
                cubeRotationSpeedDiv: 7.0
                                         
            },
            {  // phase 2 ---                 
                startTime: 2.0,
                hasCameraChange: false,
                cameraZ: -0.5,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 0.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 1.0,
                doingJitter: 1.0,
                doingSwirl: 0.0,
                swirlForward: false,
                swirlSpeed: 1.0,
                cubeRotationSpeedDiv: 7.0                                              
            },    
            {  // phase 3 -- zoom enclosed, twitching and rotating               
                startTime: 6.0,
                hasCameraChange: true,
                cameraZ: 1.0,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 0.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 1.0,
                doingJitter: 1.0,
                doingSwirl: 0.0,
                swirlForward: false,
                swirlSpeed: 1.0,
                cubeRotationSpeedDiv: 7.0                                              
            },  
            {  // phase 4 -- zoom to edge, twitching and rotating      
                startTime: 18.0,
                hasCameraChange: true,
                cameraZ: 1.40,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 0.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 1.0,
                doingJitter: 1.0,
                doingSwirl: 0.0,
                swirlForward: false,
                swirlSpeed: 1.0,
                cubeRotationSpeedDiv: 7.0                                              
            },       
            {  // phase 5 -- curl and swirl 
                startTime: 25.0,
                hasCameraChange: false,
                cameraZ: 1.40,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 1.0,
                heaveMagnitude: 1.2,
                heaveForward: true,
                doingInternalRoation: 1.0,
                doingJitter: 0.0,
                doingSwirl: 1.0,
                swirlForward: true,
                swirlSpeed: 3.0,
                cubeRotationSpeedDiv: 7.0                                              
            },  
            {  // phase 5 -- curl and swirl 
                startTime: 27.0,
                hasCameraChange: false,
                cameraZ: 1.40,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 1.0,
                heaveMagnitude: 1.2,
                heaveForward: true,
                doingInternalRoation: 1.0,
                doingJitter: 0.0,
                doingSwirl: 1.0,
                swirlForward: true,
                swirlSpeed: 5.0,
                cubeRotationSpeedDiv: 7.0                                              
            },  
            {  // phase 5 -- curl and swirl, zoom back 
                startTime: 39.0,
                hasCameraChange: true,
                cameraZ: 1.85,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 1.0,
                heaveMagnitude: 1.2,
                heaveForward: true,
                doingInternalRoation: 1.0,
                doingJitter: 0.0,
                doingSwirl: 1.0,
                swirlForward: true,
                swirlSpeed: 5.0,
                cubeRotationSpeedDiv: 7.0                                              
            },
            {  // phase 6 -- back out curl and swirl
                startTime: 47.0,
                hasCameraChange: false,
                cameraZ: 1.85,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 1.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 1.0,
                doingJitter: 0.0,
                doingSwirl: 1.0,
                swirlForward: false,
                swirlSpeed: 0.0,
                cubeRotationSpeedDiv: 7.0                                              
            },
            {  // phase 7 -- twitch, zoom out
                startTime: 52.0,
                hasCameraChange: true,
                cameraZ: 4.0,
                surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                hasColorChange: false,
                doingHeave: 0.0,
                heaveMagnitude: 0.0,
                heaveForward: false,
                doingInternalRoation: 1.0,
                doingJitter: 1.0,
                doingSwirl: 0.0,
                swirlForward: false,
                swirlSpeed: 0.0,
                cubeRotationSpeedDiv: 7.0                                              
            },
            {  // phase 7 -- twitch, zoom out
                startTime: 60.0,
                isEnd: true                                             
            },
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.totalRunningTime = 0.0;

    } // end constructor

    buildVisual() 
    {  
        this.lineCube = new THREE.Object3D();
        this.scene.add(this.lineCube);

        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
           
            blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
                uMaxRadius: { value: this.params.radius + this.params.additionalRadius },
                uScreenCenter: { value: new THREE.Vector2(window.innerWidth / 2.0, window.innerHeight / 2.0)},
                        
                uNumPoints: { value: (5 + (4 * this.params.numPointsInLine)) }, // 5 main vertices + 4 faces * points 
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },

                uDoingHeave: { value: this.params.doingHeave },
                uHeaveMagnitude: { value: this.params.heaveMagnitude },
                uDoingInternalRotation: { value: this.params.doingInternalRoation },    
                uDoingJitter: { value: this.params.doingJitter },
                uDoingSwirl: { value: this.params.doingSwirl },
                uSwirlSpeed: { value: this.params.swirlSpeed }
            }
        });

        for(let i = 0; i < this.params.numLines; i++) {
            let p1, p2, p3, p4, p5;
            const points = [];            
            const startFront = Math.random() <= 0.5 ? true : false;
            const movementDirection = []; // forward = 0, left = 1, backward = 2, right = 3, down = 4, up = 5
            
            if(startFront === true) {                
                /// bottom to top
                // start -- bottom front
                let leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const startVec = new THREE.Vector3 (
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius), 
                    this.params.radius + (Math.random() * this.params.additionalRadius)
                );                
                
                // top front
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const topFrontVec = new THREE.Vector3 (
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                    (1.0 * this.params.radius) + (Math.random() * this.params.additionalRadius),
                    this.params.radius + (Math.random() * this.params.additionalRadius)
                );             
                
                // top back
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const topBackVec = new THREE.Vector3 (
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                    (1.0 * this.params.radius) + (Math.random() * this.params.additionalRadius),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius)
                );    

                // back bottom
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const backBottmVec = new THREE.Vector3 (
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius)
                );

                // bottom front (start)
                // points.push(startVec);
                points.push(startVec);   
                movementDirection.push(0); 
                let moveDir = 0; 
                for(let faces = 0; faces < 4; faces++)
                {
                    let startPoint; 
                    let endPoint;
                    if(faces == 0) {
                        startPoint = startVec;
                        endPoint = topFrontVec;
                        moveDir = 0;
                    }
                    else if(faces == 1) {
                        startPoint = topFrontVec;
                        endPoint = topBackVec;
                        moveDir = 5;
                    }
                    else if(faces == 2) {
                        startPoint = topBackVec;
                        endPoint = backBottmVec;
                        moveDir = 2;
                    }
                    else {
                        startPoint = backBottmVec;
                        endPoint = startVec;
                        moveDir = 4;
                    }
                    
                    const vecDiff = new THREE.Vector3(endPoint.x - startPoint.x, endPoint.y - startPoint.y, endPoint.z - startPoint.z);

                    // add the subdivisions
                    for(let i = 0; i < this.params.numPointsInLine; i++) {
                        points.push(new THREE.Vector3(
                            startPoint.x + (vecDiff.x * i / this.params.numPointsInLine),
                            startPoint.y + (vecDiff.y * i / this.params.numPointsInLine),
                            startPoint.z + (vecDiff.z * i / this.params.numPointsInLine)
                        )); 
                        movementDirection.push(moveDir);                       
                    }    

                    // add the last point
                    points.push(endPoint);
                    movementDirection.push(4); 
                }
            }
            else {                
                // start -- bottom left
                let leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0; // NOTE: assume "left" is depth (z-axis)
                const startVec = new THREE.Vector3 (
                    -1 * (this.params.radius + (Math.random() * this.params.additionalRadius)),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius),                     
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                );                
                
                // top left
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const topLeftVec = new THREE.Vector3 (
                    -1 * (this.params.radius + (Math.random() * this.params.additionalRadius)),
                    (1.0 * this.params.radius) + (Math.random() * this.params.additionalRadius),
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                );                 
                 
                // top right
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const topRightVec = new THREE.Vector3 (
                    (this.params.radius + (Math.random() * this.params.additionalRadius)),
                    (1.0 * this.params.radius) + (Math.random() * this.params.additionalRadius),
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                );    

                // right bottom
                leftOfCenter = Math.random() <= 0.5 ? -1.0 : 1.0;
                const rightBottomVec = new THREE.Vector3 (
                    (this.params.radius + (Math.random() * this.params.additionalRadius)),
                    (-1.0 * this.params.radius) - (Math.random() * this.params.additionalRadius),
                    (leftOfCenter * Math.random() * this.params.radius) + (leftOfCenter * Math.random() * this.params.additionalRadius),
                );

                // bottom left (start)
                points.push(startVec);
                movementDirection.push(1);
                let moveDir = 1;
                for(let faces = 0; faces < 4; faces++) // forward = 0, left = 1, backward = 2, right = 3, down = 4, up = 5
                {
                    let startPoint; 
                    let endPoint;
                    if(faces == 0) {
                        startPoint = startVec;
                        endPoint = topLeftVec;
                        moveDir = 1;
                    }
                    else if(faces == 1) {
                        startPoint = topLeftVec;
                        endPoint = topRightVec;
                        moveDir = 5
                    }
                    else if(faces == 2) {
                        startPoint = topRightVec;
                        endPoint = rightBottomVec;
                        moveDir = 3;
                    }
                    else {
                        startPoint = rightBottomVec;
                        endPoint = startVec;
                        moveDir = 4
                    }
                    
                    const vecDiff = new THREE.Vector3(endPoint.x - startPoint.x, endPoint.y - startPoint.y, endPoint.z - startPoint.z);

                    // add the subdivisions
                    for(let i = 0; i < this.params.numPointsInLine; i++) {
                        points.push(new THREE.Vector3(
                            startPoint.x + (vecDiff.x * i / this.params.numPointsInLine),
                            startPoint.y + (vecDiff.y * i / this.params.numPointsInLine),
                            startPoint.z + (vecDiff.z * i / this.params.numPointsInLine)
                        ));  
                        movementDirection.push(moveDir);                      
                    }    

                    // add the last point
                    points.push(endPoint)
                    movementDirection.push(4)
                }
            }

            // set indicies and movement directions
            let indicies = new Float32Array(points.length);
            for(let index = 0; index < points.length; index++) {
                indicies[index] = index;
            }

            let directions = new Float32Array(movementDirection.length);
            for(let direction = 0; direction < movementDirection.length; direction++) {
                directions[direction] = movementDirection[direction];
            }

            const geometry = new THREE.BufferGeometry().setFromPoints( points );
            geometry.setAttribute('aIndex', new THREE.BufferAttribute(indicies, 1));
            geometry.setAttribute('aMoveDirection', new THREE.BufferAttribute(directions, 1));

            const line = new THREE.Line( geometry, this.material ); 

            // this.scene.add(line);
            this.lineCube.add(line);
            this.lines.push(line);

        }
        
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateColor(deltaTime, elapsedTime, changeDirection)
    {
        const surfaceIncr = 0.025;
        const depthIncr = 0.0125;

        if(changeDirection === "light") {
            let surface = this.material.uniforms.uSurfaceColor.value;
            if(surface.x < this.currPhase.surfaceColor.x) {
                surface.x += surfaceIncr;
                surface.y += surfaceIncr;
                surface.z += surfaceIncr;
                if(surface.x >= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.material.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.material.uniforms.uDepthColor.value;
            if(depth.x < this.currPhase.depthColor.x) {
                depth.x += depthIncr;
                depth.y += depthIncr;
                depth.z += depthIncr;
                if(depth.x >= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.material.uniforms.uDepthColor.value = depth;
            }
        }
        else { // dark
            let surface = this.material.uniforms.uSurfaceColor.value;
            if(surface.x > this.currPhase.surfaceColor.x) {
                surface.x -= surfaceIncr;
                surface.y -= surfaceIncr;
                surface.z -= surfaceIncr;
                if(surface.x <= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.material.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.material.uniforms.uDepthColor.value;
            if(depth.x > this.currPhase.depthColor.x) {
                depth.x -= depthIncr;
                depth.y -= depthIncr;
                depth.z -= depthIncr;
                if(depth.x <= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.material.uniforms.uDepthColor.value = depth;
            }
        }        
    }   

    updateHeave(deltaTime, shouldIncrease = true)
    {
        this.material.uniforms.uDoingHeave.value = 1.0;

        if(this.params.heaveMagnitude != this.currPhase.heaveMagnitude) {
            if(shouldIncrease == true) {
                this.params.heaveMagnitude += deltaTime * 0.5;
                if(this.params.heaveMagnitude > this.currPhase.heaveMagnitude) {
                    this.params.heaveMagnitude = this.currPhase.heaveMagnitude;
                }
            }
            else {
                this.params.heaveMagnitude -= deltaTime * 0.5;
                if(this.params.heaveMagnitude < this.currPhase.heaveMagnitude) {
                    this.params.heaveMagnitude = this.currPhase.heaveMagnitude;
                }
            }
            this.material.uniforms.uHeaveMagnitude.value = this.params.heaveMagnitude;
        }
    }

    updateJitter(deltaTime)
    {        
        if(this.params.jitterPaused == true) {
            this.params.jitterPauseTimer += deltaTime;
            // console.log("Updating pause timer: " + this.params.jitterPauseTimer);
            if(this.params.jitterPauseTimer >= this.params.jitterPauseTimerMax) {
                this.params.jitterPaused = false;
                this.params.jitterPauseTimer = 0.0;
                this.params.jitterOn = true;
                 console.log("Jitter UN-paused!!");
            }
        }
        else {
            this.params.jitterTimer += deltaTime;
            if(this.params.jitterTimer >= this.params.jitterMaxTimer) {
                this.params.jitterTimer = 0.0;

                if(this.params.jitterOn == true) {
                    this.params.jitterOn = false;
                    this.material.uniforms.uDoingJitter.value = 0.0;
                    this.material.uniforms.uDoingInternalRotation.value = 0.0;
                    // increment count... if max, go into pause
                    this.params.repeatJitterCount++;
                    if(this.params.repeatJitterCount >= this.params.repeatJitterCountMax) {
                        this.params.repeatJitterCount = 0;
                        this.params.jitterPaused = true;
                         console.log("Jitter paused!!");
                    }
                } 
                else {
                    this.params.jitterOn = true;
                    this.material.uniforms.uDoingJitter.value = 1.0;
                    this.material.uniforms.uDoingInternalRotation.value = 1.0;
                }
            }
        }
    }

    updateSwirl(deltaTime, shouldIncrease = true)
    {
        this.material.uniforms.uDoingSwirl.value = 1.0;
        this.material.uniforms.uDoingInternalRotation.value = 1.0;

        if(this.params.swirlSpeed != this.currPhase.swirlSpeed) {
            if(shouldIncrease == true) {
                this.params.swirlSpeed += deltaTime * 0.25;
                if(this.params.swirlSpeed > this.currPhase.swirlSpeed) {
                    this.params.swirlSpeed = this.currPhase.swirlSpeed;
                }
            }
            else {
                this.params.swirlSpeed -= deltaTime * 0.25;
                if(this.params.swirlSpeed < this.currPhase.swirlSpeed) {
                    this.params.swirlSpeed = this.currPhase.swirlSpeed;
                }
            }
            this.material.uniforms.uSwirlSpeed.value = this.params.swirlSpeed;
        }
    }

    updateRotation(deltaTime, elapsedTime, shouldIncrease = false)
    {
        if(this.params.cubeRotationSpeedDiv != this.currPhase.cubeRotationSpeedDiv) {
            if(shouldIncrease == true) {
                this.params.cubeRotationSpeedDiv -= deltaTime; // in this case, a smaller number means a faster rate
                if(this.params.cubeRotationSpeedDiv < this.currPhase.cubeRotationSpeedDiv)
                    this.params.cubeRotationSpeedDiv = this.currPhase.cubeRotationSpeedDiv;
            }
            else {
                this.params.cubeRotationSpeedDiv += deltaTime; // in this case, a smaller number means a faster rate
                if(this.params.cubeRotationSpeedDiv > this.currPhase.cubeRotationSpeedDiv)
                    this.params.cubeRotationSpeedDiv = this.currPhase.cubeRotationSpeedDiv;
            }
        }

        this.lineCube.rotation.y = elapsedTime / this.params.cubeRotationSpeedDiv;
    }

    updateCamera(deltaTime)
    {
        if(this.params.cameraZ != this.currPhase.cameraZ) {
            this.params.cameraZ += deltaTime * 0.2;
            if(this.params.cameraZ > this.currPhase.cameraZ)
                this.params.cameraZ = this.currPhase.cameraZ;
            
            this.camera.position.z = this.params.cameraZ;   
            this.camera.lookAt(0,0,0); 
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update cube rotation
        this.updateRotation(deltaTime, this.totalRunningTime);

        if(this.currPhase.hasCameraChange == true)
            this.updateCamera(deltaTime); 
        if(this.currPhase.doingHeave == 1.0)
            this.updateHeave(deltaTime, this.currPhase.heaveForward);
        if(this.currPhase.doingJitter == 1.0)
            this.updateJitter(deltaTime);
        if(this.currPhase.doingSwirl == 1.0)
            this.updateSwirl(deltaTime, this.currPhase.swirlForward);    
            
        // if(this.currPhase.doingInternalRoation != 1.0)
        //     this.material.uniforms.uDoingInternalRotation = 0.0;

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];    
                    if(this.currPhase.isEnd) {
                        if(this.currPhase.isEnd == true)
                            this.scene.remove(this.lineCube);
                    }              

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        // if(this.params.doingJitter == 1.0) {
        //     this.updateJitter(deltaTime);
        // }

        // update wave
        this.material.uniforms.uTime.value = this.totalRunningTime / 4.0;
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class