import * as THREE from 'three'
import devilsVertexShader from './../shaders/devils/devilsVertex.glsl'
import devilsFragmentShader from './../shaders/devils/devilsFragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class DevilsDoor extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            cameraX: 0,
            cameraY: 2.015,
            cameraZ: 0,
            uSmallWavesElevation: 0.0,
            uSmallWavesFrequency: 0.0,
            uSmallWavesSpeed: 0.0,
            uSmallWavesIterations: 4,
            rectWidth: 8,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
            depthColor: new THREE.Vector3(0.046, 0.046, 0.046),
            numSine: 0.0,
            numCosine: 0.0,
            elevationAddClamp: 0.04,
            elevationCutoff: 0.9,
            wireframe: true,
            areWavesChanging: true,
            squaresMulti: 3.0,
            showingSquare1: 1,
            square1Pos: -0.5,
            square1Speed: 1,
            showingSquare2: 0,
            square2Pos: -0.5,
            square2Speed: 0.55,
            showingSquare3: 0,
            square3Pos: -0.5,
            square3Speed: 0.65,
            cubeRotateSpeed: 0.0,
        }

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.2;
        this.unrealBloomPass.radius = 0.2;
        this.unrealBloomPass.threshold = 0.1;
        //this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        this.phases = [
            {  // phase 1
                startTime: 0.0,
                uSmallWavesElevation: 0.2, 
                // cameraX: 3,
                // cameraY: -2,
                // cameraZ: 4,
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 0.0,
                drawsLine3: 0.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 3.0,
                rotatesCube: false,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 2
                startTime: 5.0,
                uSmallWavesElevation: 0.2, 
                // cameraX: 3,
                // cameraY: -2,
                // cameraZ: 4,
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.046, 0.046, 0.046), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 0.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 3.25,
                rotatesCube: false,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 13.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 3.5,
                rotatesCube: false,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 18.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 3.75,
                rotatesCube: true,
                cubeRotateSpeed: 10.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 23.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.0,
                rotatesCube: true,
                cubeRotateSpeed: 20.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 28.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.25,
                rotatesCube: true,
                cubeRotateSpeed: 35.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 34.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.615,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 65.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 45.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 1.015,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: true,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 40.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 51.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.815,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: true,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 20.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 60.0,
                uSmallWavesElevation: 0.2, 
                cameraX: 0,
                cameraY: 0.515,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: false,
                hasCameraChange: true,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 65.0,
                uSmallWavesElevation: 10.0, 
                cameraX: 0,
                cameraY: 0.515,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.086, 0.086, 0.086), 
                hasColorChange: false,
                colorChangeDirection: "light",
                hasElevationChange: true,
                hasCameraChange: true,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 75.0,
                uSmallWavesElevation: 10.0, 
                cameraX: 0,
                cameraY: 0.515,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0), 
                hasColorChange: true,
                colorChangeDirection: "dark",
                hasElevationChange: true,
                hasCameraChange: false,
                drawsLine1: 1.0,
                drawsLine2: 1.0,
                drawsLine3: 1.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
            {  // phase 3
                startTime: 80.0,
                uSmallWavesElevation: 10.0, 
                cameraX: 0,
                cameraY: 0.515,
                cameraZ: 0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0), 
                hasColorChange: true,
                colorChangeDirection: "dark",
                hasElevationChange: true,
                hasCameraChange: false,
                drawsLine1: 0.0,
                drawsLine2: 0.0,
                drawsLine3: 0.0,
                drawsLine4: 0.0,
                drawsLine5: 0.0,
                squaresMulti: 4.5,
                rotatesCube: true,
                cubeRotateSpeed: 0.0,
                goReverse: false                                 
            },
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.totalRunningTime = 0.0;
    }

    buildVisual() 
    {
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water);
        }
    
        this.waterGeometry = new THREE.BoxGeometry(1, 1, 4, 100, 100, 100);
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: devilsVertexShader,
            fragmentShader: devilsFragmentShader,
            depthWrite: false,
            wireframe: true,
            blending: THREE.AdditiveBlending,
            uniforms: {
                uTime: { value: 0.0 },

                uGhostHeadPos: { value: this.ghostHead1 },

                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },
                uElevationCutoff: { value: this.params.elevationCutoff },

                uShowingSquare1: { value: this.params.showingSquare1 },
                uSquare1Pos: { value: this.params.square1Pos },
                uShowingSquare2: { value: this.params.showingSquare2 },
                uSquare2Pos: { value: this.params.square2Pos },
                uShowingSquare3: { value: this.params.showingSquare3 },
                uSquare3Pos: { value: this.params.square3Pos },
                // uSquare2Speed:
                // uSquare1Speed: { value: devilsParams.square1Speed },

                uSize: { value: 2.0  * this.renderer.getPixelRatio() }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial);
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water);

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateDevils(deltaTime, elapsedTime)
    {
        const intTime = Math.floor(elapsedTime);

        if(this.params.squaresMulti < this.currPhase.squaresMulti) {
            this.params.squaresMulti += deltaTime;
            if(this.params.squaresMulti > this.currPhase.squaresMulti)
                this.params.squaresMulti = this.currPhase.squaresMulti;
        }
    
        if(this.params.square1Pos >= 2.5) {
            this.params.square1Pos = -2.495;
        }
        this.params.square1Pos += deltaTime * this.params.square1Speed * this.params.squaresMulti;
        
        // update the position in the shader
        this.waterMaterial.uniforms.uSquare1Pos.value = this.params.square1Pos;
        this.waterMaterial.uniforms.uShowingSquare1.value = this.currPhase.drawsLine1;   

        if(this.params.square2Pos >= 1.5) {
            this.params.square2Pos = -2.485;
            //this.params.square1Pos = -2.485;
        }
        this.params.square2Pos += deltaTime * this.params.square2Speed * this.params.squaresMulti;
        
        // update the position in the shader
        this.waterMaterial.uniforms.uSquare2Pos.value = this.params.square2Pos;
        this.waterMaterial.uniforms.uShowingSquare2.value = this.currPhase.drawsLine2;

        if(this.params.square3Pos >= 1.5) {
            this.params.square3Pos = -2.485;
            //this.params.square1Pos = -2.485;
        }
        this.params.square3Pos += deltaTime * this.params.square3Speed * 3.0;
        
        // update the position in the shader
        this.waterMaterial.uniforms.uSquare3Pos.value = this.params.square3Pos;
        this.waterMaterial.uniforms.uShowingSquare3.value = this.currPhase.drawsLine3;
    }

    updateElevation(deltaTime, elapsedTime, goReverse = false)
    {        

        this.waterMaterial.uniforms.uSmallWavesIterations.value = 5;
        
        console.log("Updating phase 2: " + this.currPhaseIndex);

        if(goReverse == true) {
            if(this.params.uSmallWavesElevation > this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation -= deltaTime;
                if(this.params.uSmallWavesElevation < this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }
        else {
            if(this.params.uSmallWavesElevation < this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation += deltaTime * 7.0;
                this.params.uSmallWavesSpeed += deltaTime * 7.0;
                if(this.params.uSmallWavesElevation > this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
                this.waterMaterial.uniforms.uSmallWavesSpeed.value = this.params.uSmallWavesSpeed;
            }    
        }         
    }

    updateColor(deltaTime, elapsedTime, changeDirection)
    {

        //console.log("Updating color");
        const surfaceIncr = 0.025;
        const depthIncr = 0.0025;

        if(changeDirection === "light") {
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x < this.currPhase.surfaceColor.x) {
                surface.x += surfaceIncr;
                surface.y += surfaceIncr;
                surface.z += surfaceIncr;
                if(surface.x >= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x < this.currPhase.depthColor.x) {
                depth.x += depthIncr;
                depth.y += depthIncr;
                depth.z += depthIncr;
                if(depth.x >= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }
        else { // dark
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x > this.currPhase.surfaceColor.x) {
                surface.x -= surfaceIncr;
                surface.y -= surfaceIncr;
                surface.z -= surfaceIncr;
                if(surface.x <= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x > this.currPhase.depthColor.x) {
                depth.x -= depthIncr;
                depth.y -= depthIncr;
                depth.z -= depthIncr;
                if(depth.x <= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }        
    }

    updateCamera(deltaTime)
    {       
        console.log("updating camera...");
        
        const multi = 1.0;
        
        // if(this.camera.position.x !== this.currPhase.cameraX) {
        //     this.camera.position.x -= deltaTime * multi;
        //     if(this.camera.position.x < this.currPhase.cameraX)
        //         this.camera.position.x = this.currPhase.cameraX;
        // }        

        // if(this.camera.position.y !== this.currPhase.cameraY) {
        //     this.camera.position.y += deltaTime * multi;
        //     if(this.camera.position.y > this.currPhase.cameraY)
        //         this.camera.position.y = this.currPhase.cameraY;
        // }

        // if(this.camera.position.z !== this.currPhase.cameraZ) {
        //     this.camera.position.z -= deltaTime * multi;
        //     if(this.camera.position.z < this.currPhase.cameraZ)
        //         this.camera.position.z = this.currPhase.cameraZ;
        // }

        if(this.camera.position.y !== this.currPhase.cameraY) {
            this.camera.position.y -= deltaTime * multi;
            if(this.camera.position.y < this.currPhase.cameraY)
                this.camera.position.y = this.currPhase.cameraY;
        }
        
        this.camera.lookAt(new THREE.Vector3(0,0,0));
    }
    
    updateCubeRotation(deltaTime)
    {
        if(this.params.cubeRotateSpeed < this.currPhase.cubeRotateSpeed) {
            this.params.cubeRotateSpeed += deltaTime * 10.0;
            if(this.params.cubeRotateSpeed >= this.currPhase.cubeRotateSpeed)
                this.params.cubeRotateSpeed = this.currPhase.cubeRotateSpeed;
        }
        else if(this.params.cubeRotateSpeed > this.currPhase.cubeRotateSpeed) {
            this.params.cubeRotateSpeed -= deltaTime * 10.0;
            if(this.params.cubeRotateSpeed <= this.currPhase.cubeRotateSpeed)
                this.params.cubeRotateSpeed = this.currPhase.cubeRotateSpeed;
        }
        this.water.rotation.z -=  Math.PI * this.params.cubeRotateSpeed * .01 * deltaTime;
       
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;

        if(this.currPhaseIndex >= 0) { 
            if(this.currPhase.rotatesCube == true)
                    this.updateCubeRotation(deltaTime);
            this.growthTimer += deltaTime;
            if(this.growthTimer >= 0.1) {
                this.growthTimer -= 0.1;

                if(this.currPhase.hasColorChange == true)
                    this.updateColor(deltaTime, this.totalRunningTime, this.currPhase.colorChangeDirection);    
                if(this.currPhase.hasElevationChange == true)
                    this.updateElevation(deltaTime, this.totalRunningTime, this.currPhase.goReverse);   
                if(this.currPhase.hasCameraChange == true)
                    this.updateCamera(deltaTime);      
                       
            }
        }

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];  

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        this.updateDevils(deltaTime, this.totalRunningTime);
    }

    render()
    {
        this.renderer.render(this.scene, this.camera);
        // this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class