import * as THREE from 'three'
import waterVertexShader from './../shaders/michelleHeaving/thunderVertex.glsl'
import waterFragmentShader from './../shaders/michelleHeaving/thunderFragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Heaving extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 0.25,
            cameraZ: 0.0,
            //uBigWavesElevation: 0.2,
            uSmallWavesElevation: 0.345,
            uSmallWavesFrequency: 5.731,
            uSmallWavesSpeed: 0.241,
            uSmallWavesIterations: 4,
            rectWidth: 8,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0), // s/b 0.0
            depthColor: new THREE.Vector3(0.176, 0.176, 0.176), // s/b 0.176
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),
            unrealBloomParams: {
                strength: 0.1,
                radius: 0.2,
                threshold: 0.1,
            }
        };

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 800,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
        this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
        this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1
                cameraY: 0.25,
                startTime: 0.0,
                uSmallWavesElevation: 0.345,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.176, 0.176, 0.176),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                              
            },
            {  // phase 2 --- 
                cameraY: 1.65,
                startTime: 5.0,
                uSmallWavesElevation: 0.345,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.176, 0.176, 0.176),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                                                                  
            },            
            {  // phase 3 -- lighter
                startTime: 12.0,
                uSmallWavesElevation: 0.106,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.176, 0.176, 0.176),
                unrealBloomParams: {
                    strength: 0.3,
                    radius: 0.4,
                    threshold: 0.1
                },                                    
            },
            {  // phase 4 -- oscilate dark light
                startTime: 20.0,
                uSmallWavesElevation: 0.106,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.106, 0.106, 0.106),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                goingDarker: true                                         
            },            
            {  // phase 5 - oscilate dark light, oscilate elevation
                startTime: 29.0,
                uSmallWavesElevation: 0.706,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.106, 0.106, 0.106),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                goingDarker: true,
                goingHigher: true                                       
            },
            {  // phase 5 - oscilate dark light, oscilate elevation
                cameraY: 0.25,
                startTime: 40.0,
                uSmallWavesElevation: 0.345,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.176, 0.176, 0.176),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                goingDarker: false,
                goingHigher: false                                       
            },
            {  // phase 5 - oscilate dark light, oscilate elevation
                cameraY: 0.25,
                startTime: 60.5,
                uSmallWavesElevation: 0.345,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                goingDarker: true,
                goingHigher: false                                       
            },        
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.totalRunningTime = 0.0;

        // this.params.surfaceColor = new THREE.Vector3(0.0, 0.0, 0.0);
        // this.params.depthColor = new THREE.Vector3(0.0, 0.0, 0.0);

        // this.currSurfaceColor = this.phases[0].surfaceColor;
        // this.currDepthColor = this.phases[0].depthColor;

    } // end constructor

    buildVisual() 
    {            
        this.waterGeometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 512, 512)
        // this.waterGeometry = new THREE.PlaneGeometry(2, 2, 26, 26)
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: waterVertexShader,
            fragmentShader: waterFragmentShader,
            wireframe: true,
            side: THREE.DoubleSide,
           
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                uBigWavesElevation: { value: 0.0 },
                uBigWavesFrequency: { value: new THREE.Vector2(0, 0) },
                uBigWavesSpeed: { value: 0.0 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }
            }
        });

        // const tempMaterial = new THREE.MeshBasicMaterial( {color: 0xff0000, side: THREE.DoubleSide })
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial)
        this.water.position.set(0,0,0);
        this.water.scale.set(1,1,1);

        // const tempMesh = new THREE.Mesh(this.waterGeometry, tempMaterial)
        // tempMesh.position.set(0,0,0);
        // tempMesh.scale.set(1,1,1);
        // tempMesh.rotation.x = - Math.PI * 0.5
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water)
    
        // this.camera.position.x = 0;
        //this.camera.position.y = 0.25;
        // this.camera.position.z = 0; 

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ);
       
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateColor(deltaTime, elapsedTime, goReverse = false)
    {
        const surfaceIncr = 0.025;
        const depthIncr = 0.0125;

        if(goReverse == false) {
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x !== this.currPhase.surfaceColor.x) {
                surface.x += surfaceIncr;
                surface.y += surfaceIncr;
                surface.z += surfaceIncr;
                if(surface.x >= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x !== this.currPhase.depthColor.x) {
                depth.x += depthIncr;
                depth.y += depthIncr;
                depth.z += depthIncr;
                if(depth.x >= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }
        else { // dark
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x !== this.currPhase.surfaceColor.x) {
                surface.x -= surfaceIncr;
                surface.y -= surfaceIncr;
                surface.z -= surfaceIncr;
                if(surface.x <= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x !== this.currPhase.depthColor.x) {
                depth.x -= depthIncr;
                depth.y -= depthIncr;
                depth.z -= depthIncr;
                if(depth.x <= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }        
    }

    updateElevation(deltaTime, targetElevation, goReverse = false)
    {      
        const multi = 0.25;

        if(goReverse == true) {
            if(this.params.uSmallWavesElevation !== targetElevation) {  
                this.params.uSmallWavesElevation -= deltaTime * multi;
                if(this.params.uSmallWavesElevation < targetElevation)
                    this.params.uSmallWavesElevation = targetElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }
        else {
            if(this.params.uSmallWavesElevation !== targetElevation) {  
                this.params.uSmallWavesElevation += deltaTime * multi;
                if(this.params.uSmallWavesElevation > targetElevation)
                    this.params.uSmallWavesElevation = targetElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }         
    }

    updateBloom(deltaTime, targetBloom, goReverse = false)
    {
        const updateMulti = 0.15;

        if(goReverse == true) {
            if(this.params.unrealBloomParams.strength > targetBloom.strength) {
                this.params.unrealBloomParams.strength -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength < targetBloom.strength)
                    this.params.unrealBloomParams.strength = targetBloom.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius > targetBloom.radius) {
                this.params.unrealBloomParams.radius -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius < targetBloom.radius)
                    this.params.unrealBloomParams.radius = targetBloom.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold > targetBloom.threshold) {
                this.params.unrealBloomParams.threshold -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold < targetBloom.threshold)
                    this.params.unrealBloomParams.threshold = targetBloom.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
        else {
            if(this.params.unrealBloomParams.strength < targetBloom.strength) {
                this.params.unrealBloomParams.strength += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength > targetBloom.strength)
                    this.params.unrealBloomParams.strength = targetBloom.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius < targetBloom.radius) {
                this.params.unrealBloomParams.radius += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius > targetBloom.radius)
                    this.params.unrealBloomParams.radius = targetBloom.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold < targetBloom.threshold) {
                this.params.unrealBloomParams.threshold += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold > targetBloom.threshold)
                    this.params.unrealBloomParams.threshold = targetBloom.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
    }

    updateSurfaceColour(deltaTime, targetColour)
    {
        if(this.params.surfaceColor.x < targetColour.x) {
            this.params.surfaceColor.x += deltaTime;
            if(this.params.surfaceColor.x > targetColour.x)
                this.params.surfaceColor.x = targetColour.x

            this.params.surfaceColor.y = this.params.surfaceColor.x;
            this.params.surfaceColor.z = this.params.surfaceColor.x;
        } 
        else {
            this.params.surfaceColor.x -= deltaTime;
            if(this.params.surfaceColor.x < targetColour.x)
                this.params.surfaceColor.x = targetColour.x

            this.params.surfaceColor.y = this.params.surfaceColor.x;
            this.params.surfaceColor.z = this.params.surfaceColor.x;
        }

        this.waterMaterial.uniforms.uSurfaceColor.value = this.params.surfaceColor;
    }

    updateDepthColour(deltaTime, targetColour)
    {
        if(this.params.depthColor.x < targetColour.x) {
            this.params.depthColor.x += deltaTime;
            if(this.params.depthColor.x > targetColour.x)
                this.params.depthColor.x = targetColour.x

            this.params.depthColor.y = this.params.depthColor.x;
            this.params.depthColor.z = this.params.depthColor.x;
        } 
        else {
            this.params.depthColor.x -= deltaTime;
            if(this.params.depthColor.x < targetColour.x)
                this.params.depthColor.x = targetColour.x

            this.params.depthColor.y = this.params.depthColor.x;
            this.params.depthColor.z = this.params.depthColor.x;
        }

        this.waterMaterial.uniforms.uDepthColor.value = this.params.depthColor;
    }

    updateCamera(deltaTime, targetY, moveBack = true)
    {
        if(moveBack == true) {
            // update camera
            this.params.cameraY += deltaTime;
            if(this.params.cameraY > targetY)
                this.params.cameraY = targetY;
        }
        else {
            this.params.cameraY -= deltaTime * 0.25;
            if(this.params.cameraY < targetY)
                this.params.cameraY = targetY;
        }
        
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0);
    }
    
    oscilateDepthColor(deltaTime, lowColor, highColor, phase)
    {
        const multi = 0.05;
        if(phase.goingDarker === true) {
            this.params.depthColor.x -= deltaTime * multi;
            if(this.params.depthColor.x < lowColor.x) {
                this.params.depthColor.x = lowColor.x
                phase.goingDarker = false;
            }    

            this.params.depthColor.y = this.params.depthColor.x;
            this.params.depthColor.z = this.params.depthColor.x;
        }
        else {
            this.params.depthColor.x += deltaTime * multi;
            if(this.params.depthColor.x > highColor.x) {
                this.params.depthColor.x = highColor.x
                phase.goingDarker = true;
            }

            this.params.depthColor.y = this.params.depthColor.x;
            this.params.depthColor.z = this.params.depthColor.x;
        }

        this.waterMaterial.uniforms.uDepthColor.value = this.params.depthColor;
        
    }

    update(deltaTime, elapsedTime) 
    {    
        // if(this.currPhaseIndex >= 1) { 
        //     this.growthTimer += deltaTime;
        //     if(this.growthTimer >= 0.1) {
        //         this.growthTimer -= 0.1;
                
        //         this.updateColor(deltaTime, elapsedTime, this.currPhase.changeColorForward);
        //         this.updateElevation(deltaTime, elapsedTime, this.currPhase.goReverse);
        //         this.updateBloom(deltaTime, elapsedTime, this.currPhase.goReverse);                
        //     }
        // }
        // else {
        //     if(this.currPhaseIndex < this.phases.length)
        //         console.log("Curr phase xxx: " + this.currPhaseIndex);
        //     else if(this.currPhaseIndex === this.phases.length - 1)
        //         console.log("Is done!");
        // }

        // if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
        //     if(elapsedTime >= this.nextPhaseStartTime) {  
        //         this.currPhaseIndex += 1;

        //         console.log("Curr phase: " + this.currPhaseIndex);

        //         if(this.currPhaseIndex < this.phases.length) {
        //             this.currPhase = this.phases[this.currPhaseIndex];

        //             // if(this.currPhase.hasBigWavesChange)
        //             //     this.waterMaterial.uniforms.uBigWavesElevation.value = this.currPhase.uBigWavesElevation;

        //             if((this.currPhaseIndex + 1) < this.phases.length)
        //                 this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
        //             else {
        //                 this.isDone = true;                        
        //             }
        //         }                
        //     }
        // }
        this.totalRunningTime += deltaTime;

        if(this.totalRunningTime >= this.phases[6].startTime) {            
            
             
            
            this.updateDepthColour(deltaTime, this.phases[6].depthColor);
                        
            
                // update bloom
            //this.updateBloom(deltaTime, this.phases[2].unrealBloomParams, false);    
        }
        else if(this.totalRunningTime >= this.phases[5].startTime) {            
            
             
            this.updateElevation(deltaTime, this.phases[5].uSmallWavesElevation, true);
            this.updateDepthColour(deltaTime, this.phases[5].depthColor);
            this.updateCamera(deltaTime, this.phases[5].cameraY, false);            
            
                // update bloom
            //this.updateBloom(deltaTime, this.phases[2].unrealBloomParams, false);    
        }
        else if(this.totalRunningTime >= this.phases[4].startTime) {            
            
             
            this.updateElevation(deltaTime, this.phases[4].uSmallWavesElevation, false);
            this.oscilateDepthColor(deltaTime, this.phases[4].depthColor, this.phases[2].depthColor, this.phases[4]);            
            
                // update bloom
            //this.updateBloom(deltaTime, this.phases[2].unrealBloomParams, false);    
        }
        else if(this.totalRunningTime >= this.phases[3].startTime) {            
            
                        
            this.oscilateDepthColor(deltaTime, this.phases[3].depthColor, this.phases[2].depthColor, this.phases[3]);            
            
                // update bloom
            //this.updateBloom(deltaTime, this.phases[2].unrealBloomParams, false);    
        }
        else if(this.totalRunningTime >= this.phases[2].startTime) {
            // update bloom
            this.updateBloom(deltaTime, this.phases[2].unrealBloomParams, false);
            
        }
        else if(this.totalRunningTime >= this.phases[1].startTime) {
           
            this.cameraY != this.phases[1].cameraY;
                this.updateCamera(deltaTime * 0.15, this.phases[1].cameraY);

            // update colour
            // if(this.params.surfaceColor != this.phases[1].surfaceColor)
            //     this.updateSurfaceColour(deltaTime, this.phases[1].surfaceColor); 
            
            // if(this.params.depthColor != this.phases[1].depthColor)
            //     this.updateDepthColour(deltaTime, this.phases[1].depthColor);     
        }

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;
    }

    render()
    {
        this.effectComposer.render();
        //this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class