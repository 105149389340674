const sectionsIntroText = [ 
    { 
        "title": "INTRODUCTION",
        "header": "&#8220If we truly believed that immigration detainees feel pain, anxiety, love, and hope the way we do - incarceration would not be the solution. " + 
                "This could only be the solution if we truly believe they are not as much human as we are.&#8221",
        "subheader" : "Immigration & Refugee Lawyer, 2020",
        "timerLength" : 2.5
    },
    { 
        "title": "INCARCERATION",
        "header": "&#8220Conditions are dangerous in jail. They come out and it’s like they’ve been tortured. They are never the same.&#8221",
        "subheader" : "Immigration & Refugee Lawyer, Toronto",
        "timerLength" : 2.5
    },
    { 
        "title": "NO END IN SIGHT",
        "header": "&#8220Immigration detainees never know when–or if–their detention will end. Time in detention is amorphous, never-ending, and ill-defined.… Without the ability to plan and look ahead, we unravel as human beings.&#8221",
        "subheader" : "Dr. Efrat Arbel, Associate Professor at the University of British Columbia Allard School of Law, 2020",
        "timerLength" : 2.5
    },
    { 
        "title": "SOLITARY CONFINEMENT",
        "header": "&#8220It’s not subtle for people who are detained. It’s not like [they] have more social anxiety. [The impact of detention] really manifests itself in people shaking, screaming, not being able to sleep, not being able to function.&#8221",
        "subheader" : "Immigration & Refugee Lawyer, Toronto, 2020",
        "timerLength" : 2.5
    },
    { 
        "title": "SYSTEMIC ABUSE",
        "header": "&#8220Canada Border Services Agency imprisons people for non-criminal matters and they happen to be massively from the global south.&#8221",
        "subheader" : "Dr. Janet Cleveland, McGill University Psychologist and Academic, 2020",
        "timerLength" : 2.5
    },
    { 
        "title": "RACISM &#38 DISCRIMINATION",
        "header": "&#8220Canada is celebrated for welcoming newcomers and refugees, and for its commitments to building a multicultural, diverse and tolerant society. But if you scratch the surface, this is the hard truth: every year, Canada’s immigration and refugee asylum system detains thousands of people who are seeking protection or a better life in this country — and people with disabilities often fare the worst.&#8221",
        "subheader" : "Michael Bach, Managing Director, Institute for Research and Development on Inclusion and Society, 2021",
        "timerLength" : 2.5
    },
    { 
        "title": "LIFE AFTER DETENTION",
        "header": "&#8220[My former client who was released from immigration detention six years ago] still broke down in discussing the time that he spent in [jail] and how dehumanizing it was. He now has a wife, they just had a baby, he has opened his own business.… He’s gotten his whole life together and yet he still finds detention to be the most traumatizing aspect of his past, which includes being made an orphan as a child in Lebanon during the civil war. He says his experience in jail in Canada was the worst thing that he ever experienced. It still haunts him to this day.&#8221",
        "subheader" : "Immigration & Refugee Lawyer, Toronto, 2020",
        "timerLength" : 2.5
    },
];

const revealText = [
    {
        "text": "Despite its reputation as a refugee-welcoming and multicultural country, Canada incarcerates thousands of people on immigration-related grounds every year, including people who are fleeing persecution, those seeking employment and a better life, and people who have lived in Canada since childhood."
    },
    {
        "text": "Immigration detainees are not held on criminal charges or convictions, but many experience Canada’s most restrictive conditions of confinement, including maximum-security provincial jails and solitary confinement. They are handcuffed, shackled, searched, and restricted to small spaces with rigid routines and under constant surveillance."
    },
    {
        "text": "Canada is among the few countries in the global north without a legal limit on the length of immigration detention, meaning that under Canadian law, immigration detainees are at risk of being detained indefinitely. Human Rights Watch and Amnesty International found that since 2016 Canada has held more than 300 immigration detainees for longer than a year. The longest period of detention lasted over 11 years and involved a man with an apparent mental health condition, who was subjected to solitary confinement in jail and whose identity the government could not establish."
    },
    {
        "text": "One service provider described a case where her client was held in solitary confinement for several months, began to bang his head against the wall in frustration, refused to eat, and developed suicidal ideation. In response, Canada Border Services Agency argued at his detention review hearing that he was being &#8220uncooperative&#8221 and used this as further evidence to support his continued detention; the tribunal agreed and the man remained in detention."
    },
    {
        "text": "Nearly two decades after its establishment, Canada Border Services Agency remains the only major law enforcement agency in Canada without civilian oversight.... The agency’s unchecked exercise of its broad mandate and enforcement powers has repeatedly resulted in human rights violations in the context of immigration detention."
    },
    {
        "text": "Immigration detention has especially harmful effects on communities of color, refugee claimants, children, and families. Immigration detainees with mental health conditions face discrimination throughout the detention process: they are more likely to be detained in provincial jails, rather than immigration holding centers (which are dedicated exclusively to immigration detainees). According to researchers' analysis of CBSA data obtained through access to information requests, in 2019 the largest portion of immigration detainees held for 90 days or longer, 180 days or longer, and 270 days or longer, were from countries in Africa."
    },
    {
        "text": "Immigration detention also has long-term consequences that ripple beyond immigration detainees and affect their children, loved ones, and communities…. [It] deeply impacts individuals&#39 trust in law enforcement and the justice system, whether they experienced detention themselves or were impacted by the detention system through a loved one."
    },
    {
        "text": "Immigration detention also has long-term consequences that ripple beyond immigration detainees and affect their children, loved ones, and communities…. [It] deeply impacts individuals&#39 trust in law enforcement and the justice system, whether they experienced detention themselves or were impacted by the detention system through a loved one."
    },
];

const cc = [
    {
        "length": 44,
        "text": "I don’t want them to know me because these people have power. They can just strip you of everything, you just lose your voice, and the world goes on without you. I just want to have a simple life. I just feel like I’m too small to say anything to Canada. If you’re not aware of it, just be grateful you’re not in position to deal with this...",
        "start": 4,
        "end": 25
    },
    {
        "length": 63,
        "text": "I felt like killing myself. I felt like the world was ending for me. I wasn’t told what was happening, what I did wrong.… At least if someone could tell me what I did wrong… I thought to myself: “Why can’t I just die?” This was after I told the border officers everything that happened to me back home, and how I escaped for my life. I tried to explain, and the officer said: ‘Okay, okay, I know what you mean’. But she didn’t understand me, and she didn’t let me explain.… I couldn’t stop crying. I thought: “Maybe I should have stayed back home and died there.” It was too much. Before I came to Canada, I didn’t know what jail looked like.",
        "start": 4,
        "end": 49
    },
    {
        "length": 46,
        "text": "The first thing I saw in Canada was jail.… When we mixed with Canadian inmates in jail, they asked us why we are there. They thought we were Taliban. We explained that we are fleeing the Taliban.… I chose Canada because I thought it was welcoming to refugees. I thought Canada was better than this.… We are human like you but we have no country.",
        "start": 3,
        "end": 40
    },
    {
        "length": 20,
        "text": "Cameras cannot see showers. Showers are dangerous. People have weapons made of ceramic tiles.",
        "start": 2,
        "end": 9
    },
    {
        "length": 35,
        "text": "I watched a SWAT team come and pepper spray someone who was losing his mind and they took him away. I saw someone overdosing on fentanyl. The guards were just having a regular conversation while a nurse was trying to revive the guy. I just thought, ‘If I was dying – would anyone care?’",
        "start": 2,
        "end": 22
    },
    {
        "length": 48,
        "text": "Many people developed mental health issues in jail.… Most of the time, I would just lie there and I would feel my mind drifting. Breathe circulated air. It’s a place where you have to be very strong to avoid losing your mind and to stay the same person you were before.… When I put everything together–all the pressure and being away from my wife and kids–it was very hard to take. I just prayed.",
        "start": 2,
        "end": 38
    },
    {
        "length": 45,
        "text": "A big thing about mental health is just having goals, having something to look forward to.… When there is nothing there, when it’s open-ended, it becomes very difficult.… We didn’t feel like we had any hope and we felt like something needed to change. We felt like we needed to do something drastic. A lot of people were ready to die.… I gave myself a time limit: that if I’m not released by a certain date, I’m going to find a way.",
        "start": 3,
        "end": 39
    },
    {
        "length": 29,
        "text": "With a criminal sentence, your release date, that’s the one thing you hold on to.… It’s the one firm thing you can count on. When you don’t have that, you just spiral.… The unknown in immigration detention, it’s mental cruelty, torture. It’s beyond a human rights violation.",
        "start": 2,
        "end": 26
    },
    {
        "length": 76,
        "text": "With cameras all over you, you don’t have privacy to even go to the toilet.… I was going mad in there. I remember I was wearing black pants and I was picking at the fabric just to make the time go by. I didn’t know what to do.… You just hear the guards coming and looking at you, and then they leave. When you ask to speak to someone, your word is not heard. They don’t care about you.… You have to ask the guard for everything–it’s all up to the guards what you are allowed to do.… I started getting sick, so they called the nurse. I was getting sick because of the stress and all the pressure. I felt like my heart stopped, I didn’t know what was going on. Everything turned black. I told the nurse, but she only gave me Tylenol. … She said: 'I have no right to talk to you.'",
        "start": 3,
        "end": 68
    },    
    {
        "length": 61,
        "text": "I was in solitary confinement for two weeks. … No shower in segregation. No bed. You sleep on the floor. It was cold on the floor. You don’t get to come out. No phone. Stuck in there. No fresh air. You’re lucky is you get a shower every 72 hours but only if there is enough staff. A lot of people … don’t come out the same way. They develop anger, anxiety, stress, depression. It messes with your mind. It’s so torturous.",
        "start": 2,
        "end": 53
    },
    {
        "length": 75,
        "text": "I told the nurse I had been suicidal in the past but I got over it.… I didn’t want to lie to these people. Then I had to follow the officer. They stripped me and put me on suicide watch. I was cold on the steel bed. I asked for a blanket, and they said: “No, you’re on suicide watch.” Whenever I made any slight movement–even if I just turned from one side to another –they would come and record it.… The first time I saw a psychiatrist, I was cleared off isolation. He said: 'You have to watch the things you say.' … After I was on suicide watch, I didn’t want to tell anyone that I was struggling. I remember that steel bed–you put cadavers there, after you’re dead. I was just waiting and praying, trying to convince myself that it’s not that bad. I was thinking, 'They can’t just leave me in here.' … I didn’t feel like a human in there: I felt like a dog. The guards would just open the latch to feed me.",
        "start": 2,
        "end": 70
    },
    {
        "length": 80,
        "text": "I was taken to the hospital one time. I was having hearing problems.… I was handcuffed and I remember going into the elevator with the CBSA officer [Canada Border Services Agency] and prison guards. In the elevator, there was a kid–a young boy around three or four years old–with his father. As soon as we got into the elevator, the boy was shocked. He asked his father: 'What did this man do?' And I remember just standing there … His father couldn’t answer because he didn’t understand the situation but he probably thought I was some kind of criminal, or that I killed somebody. Who knows? … On our way back, instead of taking the same elevator, CBSA took me through the freight elevator. I asked the officer why we are going through the freight elevator. And he said: 'Out of sight – out of mind.' … That was the saddest moment of my life.",
        "start": 4,
        "end": 76
    },
    {
        "length": 95,
        "text": "I remember the border officers, with the Canadian flag stitched to their uniforms. I remember one of them bringing six sets of handcuffs, one for each of us. I remember seeing my mom handcuffed while she held my baby brother in her arms. I remember the officers taking me and my siblings away from our parents, and putting us in a different room. I remember bright lights. I remember being hungry. They only had ham sandwiches there, and we couldn’t eat ham. So we stayed hungry. I remember the officers telling us they are going to take us away from these people – referring to my parents as these people. I remember screaming and running out of the room and down a hallway, looking for my mom. I remember telling her what the officers said to us – that they would take us away. I remember her trying to soothe me. I remember sleeping in that place. I remember not knowing what was happening, and I remember being scared.",
        "start": 2,
        "end": 90
    },
    {
        "length": 156,
        "text": "I arrived at the Vancouver International Airport in 2017 seeking protection from Egypt. When I landed, I was interrogated aggressively by the CBSA. I was stripped from all my belongings, including my batteries and charger for my cochlear implant device. You see, I was born deaf. My whole life I have relied upon hearing aids to communicate and interact with others. My interrogation by CBSA lasted three days, and I spent each night in Vancouver city jail. After this, I was thrown into North Fraser Pretrial Centre (NFPC). I don’t know why Canada softens the name by calling this 'detention'. After a number of days, I managed to explain my hearing problem to the nurse.  She gave me a set of disposable batteries. When they ran out, I would go for days without hearing. I had no way of using a phone. So, I would have to ask my friend to try to help me. He would make phone calls on my behalf. I had to trust him because I could not hear the conversations he was having with the lawyers and agencies. I would have to lip read what he was saying and place my hand on his shoulders to feel the vibrations through my bones to make out whatever words I could. You would expect such society to be welcoming to modern day immigrants and it would shock any decent person to know that the first people a newcomer meets in Canada are members of the Canada Border Services Agency (CBSA), an organization whose motto is 'Protection, Service, Integrity', but operates under racist, bigoted, and xenophobic tendencies in all reality.",
        "start": 2,
        "end": 149
    },
    {
        "length": 62,
        "text": "I haven’t recovered from immigration detention. You never recover. The damage is done forever. Sometimes I feel like I’m there again. You have to force yourself to accept this reality, even if it’s unacceptable. Detention harmed my mental health so much that it hurt my physical health. Every day now I have pain.… People are hurting in there.… It’s difficult to remember. Three years sitting there for nothing.",
        "start": 2,
        "end": 56
    },
    {
        "length": 42,
        "text": "I begged the officers to let me be with my children but they just refused. It will take a long time to take care of my children’s mental health. Being separated from us hurt them very much. … It’s very hard to ease their pain. They haven’t learned yet how to protect themselves because they are very young. … They just keep asking me every day to promise that I won’t disappear and I keep telling them, ‘Don’t worry, I’ll be here.’",
        "start": 2,
        "end": 35
    },
    {
        "length": 93,
        "text": "After we were released from detention, I remember my parents giving us a little speech. They said, 'you are here for a better future, you are safe here. Don’t worry about what happened to us. We are just going to move forward. We will do our best to give you a good life.' For years, we didn’t talk about what happened to us, and I tried to wipe the memories away. But our introduction to Canada set the precedent that we were not welcomed. The way we were treated – being arrested and detained – gave us an idea that we are different. Those memories still haunt us. And seeing the damage detention has done to my parents pains me even more. Those officers demonstrated to us that they had absolutely authority over us, and it didn’t matter that we were law-abiding, that we were seeking refuge, that we were just kids. Because of the way we were introduced to Canada, we’ve lived with a lot of uncertainty and fear – like it could all be taken away from us in an instant. My family is still coming to terms with what happened to us.",
        "start": 2,
        "end": 82
    },
];

const cc_french = [
    {
        "length": 44,
        "text": "Je ne veux pas qu’ils entendent parler de moi car ces gens-là ont du pouvoir. Ils peuvent tout vous prendre, vous perdez votre voix, et le monde continue de tourner sans vous. Je veux juste avoir une vie simple. Je me sens trop infime pour dire quoi que ce soit au Canada. Si c’est une situation que vous ne connaissez pas, félicitez- vous de ne pas y être confronté....",
        "start": 3,
        "end": 25
    },
    {
        "length": 63,
        "text": "J’avais envie de me tuer. C’était la fin du monde pour moi. Personne ne m’a expliqué ce qui se passait, ce que j’avais fait de mal ... Si au moins quelqu’un avait pu me dire ce que j’avais fait de mal.... Je me suis dit : « Pourquoi est-ce que je ne peux pas simplement mourir ? » J’ai raconté [à l’agente de l’ASFC] tout ce qui m’était arrivé dans mon pays, et comment j’avais fui pour survivre. J’ai essayé d’expliquer, et l’agente a dit : « OK, OK, je vois ce que vous voulez dire. » Mais elle ne m’a pas comprise, et elle ne m’a pas laissé lui expliquer ... Je n’arrivais pas à m’arrêter de pleurer. Je me suis alors dit que j’aurais peut-être mieux fait de rester là-bas et d’y mourir. C’était trop pour moi. Avant mon arrivée au Canada, je ne savais pas à quoi ressemblait la prison.",
        "start": 4,
        "end": 49
    },
    {
        "length": 46,
        "text": "La première chose que j’ai vue en arrivant au Canada, c’est une prison ... Lorsque nous étions mélangés à des prisonniers canadiens, ils nous demandaient pourquoi nous étions là. Ils pensaient que nous étions des talibans. Nous leur avons expliqué que nous fuyions les talibans.... J’ai choisi le Canada car je croyais que les réfugiés y étaient bienvenus. Je me faisais une meilleure idée du Canada.... Nous sommes des êtres humains, tout comme vous, mais nous n’avons pas de pays.",
        "start": 3,
        "end": 40
    },
    {
        "length": 20,
        "text": "Les caméras [de surveillance] ne filment pas les douches. Les douches sont un endroit dangereux. Les gens ont des armes [fabriquées avec] des carreaux de céramique.",
        "start": 2,
        "end": 9
    },
    {
        "length": 35,
        "text": "J’ai vu une unité SWAT [groupe tactique d’intervention] faire irruption et asperger de gaz poivre quelqu’un qui perdait la tête, puis l’emmener. J’ai vu quelqu’un faire une overdose de fentanyl [un médicament opioïde]. Les gardiens poursuivaient leur conversation comme si de rien était pendant qu’une infirmière essayait de ranimer ce gars. Je me suis dit : Si je mourais, qui s’en soucierait ? ",
        "start": 2,
        "end": 22
    },
    {
        "length": 48,
        "text": "Beaucoup de gens ont développé des problèmes de santé mentale en prison.... La plupart du temps, je restais juste allongé et je sentais mon esprit divaguer. Je restais là à respirer l’air brassé par la ventilation. C’est un endroit où il faut être très fort pour ne pas perdre la tête et rester la même personne qu’avant.... Quand je repense à tout ça– toute la pression et le fait d’être séparé de ma femme et de mes enfants – c’était vraiment dur à supporter. Je n’avais rien d’autre à faire que de prier",
        "start": 2,
        "end": 38
    },
    {
        "length": 45,
        "text": "L’une des choses essentielles pour la santé mentale est d’avoir des objectifs, d’avoir quelque chose à attendre.... Quand il n’y a plus rien, quand c’est sans fin, ça devient très difficile.... Nous n’avions plus d’espoir et nous avions le sentiment que quelque chose devait changer. Nous avions l’impression de devoir faire quelque chose de radical. Beaucoup de gens étaient prêts à mourir.... Je me suis donné un délai : si je n’étais pas libéré avant telle date, je trouverai un moyen [de me suicider]",
        "start": 3,
        "end": 39
    },
    {
        "length": 29,
        "text": "Lorsque vous êtes condamné à une peine d’emprisonnement, la date de votre libération est votre seule bouée.... C’est la seule chose à laquelle vous pouvez vous raccrocher. Sans cela, vous partez à la dérive.... Pour les migrants détenus, l’inconnu est une torture, c’est de la cruauté mentale. C’est pire qu’une violation des droits humains.",
        "start": 2,
        "end": 26
    },
    {
        "length": 76,
        "text": "Avec ces caméras tout autour de vous, vous n’avez aucune intimité, même pour aller aux toilettes.... Je devenais folle là-dedans. Je me souviens que j’avais un pantalon noir et que je triturais le tissu juste pour passer le temps. Je ne savais pas quoi faire.... Vous entendez juste les gardiens venir vous regarder, puis repartir. Quand vous demandez à parler à quelqu’un, on ne vous écoute pas. Ils n’en ont rien à faire de vous.... Dès que voulez quelque chose, vous devez demander aux gardiens – ce sont eux qui décident ce à quoi vous avez droit... J’ai commencé à me sentir mal, alors ils ont appelé l’infirmière. J’allais mal à cause du stress et de toute cette pression. J’ai eu l’impression que mon cœur s’arrêtait, je ne savais pas ce qui se passait. Tout est devenu noir. Je l’ai dit à l’infirmière mais elle m’a juste donné du Tylenol [paracétamol].... Elle m’a dit : Je n’ai pas le droit de vous parler",
        "start": 3,
        "end": 68
    },    
    {
        "length": 61,
        "text": "J’ai été en isolement solitaire pendant deux semaines. (…) [Il n’y a] pas de douches en isolement. Pas de lit. On dort par terre. Le sol était froid. On n’a pas le droit de sortir. Pas de téléphone. On est coincé là-dedans. Sans air frais. Avec un peu de chance, vous pouvez avoir une douche toutes les 72 heures, mais seulement s’il y a assez de personnel. La plupart des gens ... en ressortent changés. Ils développent de la colère, de l’anxiété, du stress, une dépression. Ça vous ravage le cerveau. C’est de la torture",
        "start": 2,
        "end": 53
    },
    {
        "length": 75,
        "text": "J’ai dit à l’infirmière que j’avais été suicidaire par le passé mais que je m’en étais sorti.... Je ne voulais pas mentir à ces gens. Ensuite j’ai dû suivre le fonctionnaire. Ils m’ont déshabillé et m’ont mis sous surveillance pour risque de suicide. J’avais froid sur le lit métallique. J’ai demandé une couverture mais ils [les gardiens] ont dit : « Non, tu es sous surveillance pour risque de suicide. » Dès que je faisais un petit mouvement – même quand je ne faisais que me retourner sur le lit –, ils venaient et le notaient.... Dès que j’ai vu un psychiatre, mon isolement a été levé. Celui-ci m’a averti : « Faites attention à ce que vous dites. » ... Après avoir été placé sous surveillance pour risque de suicide, je ne voulais dire à personne que je n’allais pas bien. Je me souviens de ce lit en métal – ce type de lit sert à poser les cadavres, c’est là qu’on vous met quand vous êtes mort. Je ne faisais qu’attendre et prier, j’essayais de me convaincre que la situation n’était pas si grave. Je me disais qu’ils ne pourraient pas me laisser ici.... Je ne me sentais pas comme un être humain dans cette prison : je me sentais comme un chien. Les gardiens ouvraient la porte uniquement pour me nourrir. ",
        "start": 2,
        "end": 70
    },
    {
        "length": 80,
        "text": "Une fois, on m’a emmené à l’hôpital. J’avais des problèmes d’audition.... J’étais menotté et je me souviens avoir pris l’ascenseur avec l’agent de l’ASFC et des gardiens de prison. Dans l’ascenseur, il y avait un gamin – un petit garçon de trois ou quatre ans – avec son père. Quand nous sommes entrés dans l’ascenseur, il a été choqué. Il a demandé à son père : Qu’est-ce qu’il a fait le monsieur ? Et je me souviens que je suis resté là sans rien dire, dans cet ascenseur... Son père n’a pas pu répondre car il ne comprenait pas la situation, mais il a probablement pensé que j’étais un genre de criminel, ou que j’avais tué quelqu’un. Qui sait ? ... Au retour, au lieu de prendre le même ascenseur, [l’agent de l’ASFC] m’a fait passer par le monte-charge. Je lui ai demandé pourquoi nous passions par là et il a dit : Loin des yeux, loin du cœur. ... Ça a été le moment le plus triste de ma vie.",
        "start": 4,
        "end": 76
    },
    {
        "length": 95,
        "text": "Je me souviens des agents frontaliers, avec le drapeau canadien cousu sur leurs uniformes. Je me souviens que l'un d'entre eux avait apporté six paires de menottes, une pour chacun d'entre nous. Je me souviens avoir vu ma mère menottée alors qu'elle tenait mon petit frère dans ses bras. Je me souviens que les officiers nous ont emmenés, moi et mes frères et sœurs, loin de nos parents, et nous ont mis dans une autre pièce. Je me souviens de lumières vives. Je me souviens d'avoir eu faim. Ils n'avaient que des sandwiches au jambon, et nous ne pouvions pas en manger. Alors on restait affamés. Je me souviens que les officiers nous ont dit qu'ils allaient nous emmener loin de ces gens - en faisant référence à mes parents comme à ces gens. Je me souviens avoir crié et couru hors de la pièce et dans un couloir, à la recherche de ma mère. Je me souviens lui avoir dit ce que les officiers nous avaient dit - qu'ils allaient nous emmener. Je me souviens qu'elle essayait de me calmer. Je me souviens avoir dormi dans cet endroit. Je me souviens que je ne savais pas ce qui se passait et que j'avais peur.",
        "start": 2,
        "end": 90
    },
    {
        "length": 156,
        "text": "Je suis arrivé d’Égypte à l'aéroport international de Vancouver en 2017 pour demander l’asile politique. Après avoir atteri, j'ai été interrogé de manière agressive par l'ASFC. On m'a dépouillé de tous mes biens, y compris les piles et le chargeur de mon appareil d'implant cochléaire. Voyez-vous, je suis né sourd. Toute ma vie, j'ai eu besoin d'appareils auditifs pour communiquer et interagir avec les autres. Mon interrogatoire par l'ASFC a duré trois jours, et chaque nuit, j’ai été détenu dans la prison municipale de Vancouver. Après cela, j'ai été envoyé au North Fraser Pretrial Centre (NFPC). Je ne sais pas pourquoi le Canada euphémise cette réalité en l'appelant 'détention'. Après plusieurs jours, j'ai réussi à expliquer mon problème d'audition à l'infirmière.  Elle m'a donné un jeu de piles jetables. Lorsqu'elles étaient épuisées, je devais rester plusieurs jours sans pouvoir entendre. Je n'avais aucun moyen d'utiliser un téléphone. Je devais donc demander à mon ami d'essayer de m'aider. Il passait des appels téléphoniques en mon nom. Je devais lui faire confiance car je ne pouvais pas entendre les conversations qu'il avait avec les avocats et les agences. Je devais lire sur ses lèvres et placer ma main sur ses épaules pour sentir les vibrations à travers mes os afin de comprendre les quelques les mots que je pouvais. On s'attendrait à ce qu'une société comme le Canada soit accueillante pour les immigrants. Toute personne décente serait choquée de savoir que les premières personnes qu'un nouvel arrivant rencontre au Canada sont des membres de l'Agence des services frontaliers du Canada (ASFC), une organisation dont la devise est « Protection, Service, Intégrité », mais qui fonctionne en réalité selon des tendances racistes, bigotes et xénophobes.",
        "start": 2,
        "end": 149
    },
    {
        "length": 62,
        "text": "Je ne me suis pas remis de la détention. On ne s’en remet jamais. Les dégâts sont irréversibles. Parfois j’ai l’impression d’y être de nouveau. Il faut se forcer à accepter cette réalité, même si elle est inacceptable. La détention a tellement détruit ma santé mentale que ça a des répercussions sur ma santé physique. Maintenant je souffre quotidiennement.... Comme si on me faisait du mal à l’intérieur.... C’est difficile d’y repenser. Trois ans à rester assis là-bas pour rien.",
        "start": 2,
        "end": 56
    },
    {
        "length": 42,
        "text": "J’ai supplié les agents de me laisser avec mes enfants, mais ils ont refusé. Il va falloir beaucoup de temps pour que la santé mentale de mes enfants se rétablisse. Être séparés de nous leur a fait beaucoup de mal.... C’est très difficile d’apaiser leur douleur. Ils n’ont pas encore appris comment se protéger car ils sont très jeunes.... Ils continuent de me demander tous les jours de leur promettre que je ne vais pas disparaître, et je leur répète : Ne vous inquiétez pas, je serai toujours là.",
        "start": 2,
        "end": 35
    },
    {
        "length": 93,
        "text": "Après notre libération, je me souviens que mes parents nous ont fait un petit discours. Ils nous ont dit : « Vous êtes ici pour un avenir meilleur, vous êtes en sécurité ici. Ne vous inquiétez pas de ce qui nous est arrivé. Nous allons simplement aller de l'avant. Nous ferons de notre mieux pour vous donner une bonne vie. » Pendant des années, nous n'avons pas parlé de ce qui nous était arrivé, et j'ai essayé d'effacer les souvenirs. Mais notre arrivée au Canada a créé un précédent : nous n'étions pas les bienvenus. La façon dont nous avons été traités – l’arrestation et la détention - nous a donné l'impression que nous étions différents. Ces souvenirs nous hantent encore. Et voir les dommages que la détention a causés à mes parents me fait encore plus mal. Ces agents nous ont montré qu'ils avaient une autorité absolue sur nous, et que peu importait que nous soyons respectueux de la loi, que nous cherchions un refuge ou que nous n’étions que des enfants. À cause de ces premières expériences du Canada, nous avons vécu dans l'incertitude et la peur, comme si tout pouvait nous être enlevé en un instant. Ma famille est encore en train d'essayer d’accepter ce qui nous est arrivé. ...",
        "start": 2,
        "end": 82
    },
];

const mediaControl = 
  `
  <div></div>
  <div class="media_spacer"></div>
    <div class="media_player">
        <div class="media_player_container" id="main_media_player_container">
            <div class="navigation_button" id="home_button">INFO</div>
            <div class="player_spacer"></div> <!-- just a spacer -->
            <div class="player_section" id="player_section1">
                <div class="player_section_title">
                    <p>Incarceration</p>                            
                </div>
                <div class="player_main_lines" id="player_lines_1"></div>                        
            </div>
            <div class="player_section" id="player_section2">
                <div class="player_section_title">                            
                    <p>No End In Sight</p>
                </div>
                <div class="player_main_lines" id="player_lines_2"></div>                        
            </div>
            <div class="player_section" id="player_section3">
                <div class="player_section_title">
                    <p>Solitary Confinement</p>
                </div>
                <div class="player_main_lines" id="player_lines_3"></div>                         
            </div>
            <div class="player_section" id="player_section4">
                <div class="player_section_title">                            
                    <p>Abuses by Border Officers</p>
                </div>
                <div class="player_main_lines"  id="player_lines_4"></div>                        
            </div>
            <div class="player_section" id="player_section5">
                <div class="player_section_title">                            
                    <p>Racism & Discrimination</p>
                </div>
                <div class="player_main_lines"  id="player_lines_5"></div> 
            </div>
            <div class="player_section" id="player_section6">
                <div class="player_section_title">                           
                    <p>Life After Detention</p>
                </div>
                <div class="player_main_lines"  id="player_lines_6"></div>                        
            </div>
            <div class="player_sub_button" id="pause_button"></div> <!-- pause -->
            <div class="player_sub_button" id="audio_button"></div> <!-- volume -->
            <div class="cc_spacer"></div>
            <div class="navigation_button" id="cc_button">CC</div>
        </div>
    </div>
  `

export { sectionsIntroText as SectionsIntroText } 
export { mediaControl as MediaControl }
export { revealText as RevealText }
export { cc as CC }
export { cc_french as CC_FRENCH }