import * as THREE from 'three'
import cubeVertexShader from './../shaders/michelleCube/cubeVertex.glsl'
import cubeFragmentShader from './../shaders/michelleCube/cubeFragment.glsl'
import puddleVertexShader from './../shaders/michelleCube/vertexPuddle.glsl'
import puddleFragmentShader from './../shaders/michelleCube/fragmentPuddle.glsl'
import bugVertexShader from './../shaders/mindBugs/vertex.glsl'
import bugFragmentShader from './../shaders/mindBugs/fragment.glsl'
// 
// 

import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'
import MindBug from './mindBug.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import gsap from "gsap";

export default class MichelleCube extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            cameraX: 0,
            cameraY: -0.325,
            cameraZ: 0,
            uSmallWavesElevation: 0.122, // 0.822,
            uSmallWavesFrequency: 2.96,
            uSmallWavesSpeed: 1.3,
            uSmallWavesIterations: 1,
            rectWidth: 8,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(5.0 / 255.0, 5.0 / 255.0, 5.0 / 255.0), //'#2d2d2d', //#0d0d0d
            depthColor: new THREE.Vector3(0.0 / 255.0, 0.0 / 255.0, 0.0 / 255.0), //'#1d1d1d',
            numSine: 2.9,
            numCosine: 1.7,
            elevationAddClamp: 0.48,
            wireframe: true,
            areWavesChanging: true,
            isExpanding: false,
            pointsWidth: 500,
            pointsHeight: 500,
            numLinesPerStar: 40,
        }

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        this.puddles = [];
        this.bugs = [];
        this.maxBugs = 20;
        this.bugsAddTimer = 0.0;
        this.maxAddBugsTimer = 0.15;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.25;
        this.unrealBloomPass.radius = 0.35;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        this.phases; 

        this.currPhaseIndex; // = 0;
        this.currPhase; // = this.phases[0];
        this.nextPhaseStartTime; // = this.phases[1].startTime;
        this.isDone = false;

        this.totalRunningTime = 0.0;    
    }

    buildVisual() 
    {
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water);
        }
    
        this.waterGeometry = new THREE.BoxGeometry(1, 1, 2.75, 100, 100, 100);
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: cubeVertexShader,
            fragmentShader: cubeFragmentShader,
            depthWrite: false,
            wireframe: true,
            blending: THREE.AdditiveBlending,
            uniforms: {
                uTime: { value: 0.0 },

                uGhostHeadPos: { value: this.ghostHead1 },

                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },

                uSize: { value: 2.0  * this.renderer.getPixelRatio() }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial);
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water);

        this.buildEyes();

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)

        this.phases = [
            {  // phase 1                
                startTime: 0.0,
                                               
            },
            {  // color transition                
                startTime: 0.25,
                changesColor: true,
                colorTweens: [
                    {                        
                        param: this.params.surfaceColor,
                        instruction: { duration: 5.0, x: 40.0 / 255.0, y: 40.0 / 255.0, z: 40.0 / 255.0, ease: 'expo.out' }                          
                    }, 
                    {                        
                        param: this.params.depthColor,
                        instruction: { duration: 5.0, x: 29.0 / 255.0, y: 29.0 / 255.0, z: 29.0 / 255.0, ease: 'expo.out', delay: -3.0 }                          
                    },
                ]                               
            },
            {  // show eyes        
                startTime: 4.0,
                animatesEyes: true,
                eyeShowLength: 1.25,
                stdEyeDelay: 0.225,
                numEyesToShow: 30
            },     
            {  // do bubbles
                startTime: 8.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 3.0, value: 0.322, ease: 'expo.out' }                          
                    }
                ]
            },       
            {  // start adding bugs           
                startTime: 12.0,
                addsBugs: true,                
            },
            {  // stop bugs           
                startTime: 22.0,
                addsBugs: false,                
            },
            {  // show eyes        
                startTime: 24.0,
                animatesEyes: true,
                eyeShowLength: 0.85,
                stdEyeDelay: 0.105,
                numEyesToShow: 10
            }, 
            { // go white
                startTime: 26.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 3.0, value: 0.0, ease: 'expo.out' }                          
                    }
                ]
            },  
            {  // do bubbles
                startTime: 32.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 7.0, value: 0.422, ease: 'expo.out' }                          
                    }
                ]
            },  
            {  // do bubbles
                startTime: 42.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 7.0, value: 0.522, ease: 'expo.out' }                          
                    }
                ]
            },  
            {  // start adding bugs           
                startTime: 52.0,
                addsBugs: true,                
            },
            {  // stop bugs           
                startTime: 57.0,
                addsBugs: false,                
            },
            { // go very still
                startTime: 58.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 21.5, value: 0.0, ease: 'expo.out' }                          
                    }
                ]
            },
            {  // go very dark              
                startTime: 58.25,
                changesColor: true,
                colorTweens: [
                    {                        
                        param: this.params.surfaceColor,
                        instruction: { duration: 1.0, x: 15.0 / 255.0, y: 15.0 / 255.0, z: 15.0 / 255.0, ease: 'expo.out' }                          
                    }, 
                    {                        
                        param: this.params.depthColor,
                        instruction: { duration: 1.0, x: 10.0 / 255.0, y: 10.0 / 255.0, z: 10.0 / 255.0, ease: 'expo.out', delay: -3.0 }                          
                    },
                ]                               
            },
            // {  // color transition -- back to regular               
            //     startTime: 60,
            //     changesColor: true,
            //     colorTweens: [
            //         {                        
            //             param: this.params.surfaceColor,
            //             instruction: { duration: 3.0, x: 40.0 / 255.0, y: 40.0 / 255.0, z: 40.0 / 255.0, ease: 'expo.out' }                          
            //         }, 
            //         {                        
            //             param: this.params.depthColor,
            //             instruction: { duration: 3.0, x: 29.0 / 255.0, y: 29.0 / 255.0, z: 29.0 / 255.0, ease: 'expo.out', delay: -3.0 }                          
            //         },
            //     ]                               
            // },
            {  // do bubbles -- getting very crazy
                startTime: 60.0,
                changesElevation: true,
                elevationTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSmallWavesElevation,
                        instruction: { duration: 13.0, value: 0.822, ease: 'expo.out' }                          
                    }
                ]
            },  
            {  // color transition -- fade to black        
                startTime: 73,
                changesColor: true,
                colorTweens: [
                    {                        
                        param: this.params.surfaceColor,
                        instruction: { duration: 8.0, x: 5.0 / 255.0, y: 5.0 / 255.0, z: 5.0 / 255.0, ease: 'expo.out' }                          
                    }, 
                    {                        
                        param: this.params.depthColor,
                        instruction: { duration: 8.0, x: 5.0 / 255.0, y: 5.0 / 255.0, z: 5.0 / 255.0, ease: 'expo.out', delay: -3.0 }                          
                    },
                ]                               
            },
        ];

        this.currPhaseIndex = 0;
        this.currPhase = this.phases[0];
        this.nextPhaseStartTime = this.phases[1].startTime;
    }

    buildEyes()
    {
        // Geometry
        const puddleGeometry = new THREE.PlaneGeometry(1, 1, 32, 32);    

        // Material
        const puddleMaterial = new THREE.ShaderMaterial({
            vertexShader: puddleVertexShader,
            fragmentShader: puddleFragmentShader,
            depthWrite: true,
            depthTest: true,
            blending: THREE.AdditiveBlending,        
            vertexColors: true,
            alphaToCoverage: true,
            transparent: true,
            uniforms: {
                uScale: { value: 1.0 },
                uThicknessScalar: { value: 0.025 },
                uColor: { value: new THREE.Color(0.4, 0.4, 0.4) },
                uAlpha: { value: 1.0 }
            }        
        });

        let nextX = 0;
        let nextY = 0.5;
        let nextZ = 0
        for(let i = 0; i < 10; i++) {
            
            const puddleMaterial = new THREE.ShaderMaterial({
                vertexShader: puddleVertexShader,
                fragmentShader: puddleFragmentShader,
                depthWrite: true,
                depthTest: true,
                blending: THREE.AdditiveBlending,        
                vertexColors: true,
                alphaToCoverage: true,
                transparent: true,
                uniforms: {
                    uScale: { value: 1.0 },
                    uThicknessScalar: { value: 0.025 },
                    uColor: { value: new THREE.Color(0.4, 0.4, 0.4) },
                    uAlpha: { value: 1.0 }
                }        
            });

            const puddleMesh = new THREE.Mesh(puddleGeometry, puddleMaterial)
            this.scene.add(puddleMesh)

            this.puddles.push(puddleMesh);

            nextX = (Math.random() * 0.6) - 0.3;
            nextZ = (Math.random() * 0.6) - 0.3;

            puddleMesh.position.set(nextX, nextY, nextZ);
            puddleMesh.scale.set(0.2, 0.2, 0.2);
            puddleMesh.rotation.x =  Math.PI * 0.5;

            puddleMesh.material.uniforms.uAlpha.value = 0.0;
        }

        nextX = 0.5;
        nextY = 0;
        nextZ = 0.5
        for(let i = 0; i < 10; i++) {

            const puddleMaterial = new THREE.ShaderMaterial({
                vertexShader: puddleVertexShader,
                fragmentShader: puddleFragmentShader,
                depthWrite: true,
                depthTest: true,
                blending: THREE.AdditiveBlending,        
                vertexColors: true,
                alphaToCoverage: true,
                transparent: true,
                uniforms: {
                    uScale: { value: 1.0 },
                    uThicknessScalar: { value: 0.025 },
                    uColor: { value: new THREE.Color(0.4, 0.4, 0.4) },
                    uAlpha: { value: 1.0 }
                }        
            });

            const puddleMesh = new THREE.Mesh(puddleGeometry, puddleMaterial)
            this.scene.add(puddleMesh)

            const pushIndex = Math.round(Math.random() * 10);

            this.puddles.splice(pushIndex, 0, puddleMesh);
            //this.puddles.push(puddleMesh);

            nextY = (Math.random() * 0.6) - 0.3 + 0.3;
            nextZ = (Math.random() * 0.7) - 0.35;

            puddleMesh.position.set(nextX, nextY, nextZ);
            puddleMesh.scale.set(0.2, 0.2, 0.2);
            puddleMesh.rotation.y =  Math.PI * 1.5;

            puddleMesh.material.uniforms.uAlpha.value = 0.0;
        }

        nextX = -0.5;
        nextY = 0;
        nextZ = 0.5
        for(let i = 0; i < 10; i++) {

            const puddleMaterial = new THREE.ShaderMaterial({
                vertexShader: puddleVertexShader,
                fragmentShader: puddleFragmentShader,
                depthWrite: true,
                depthTest: true,
                blending: THREE.AdditiveBlending,        
                vertexColors: true,
                alphaToCoverage: true,
                transparent: true,
                uniforms: {
                    uScale: { value: 1.0 },
                    uThicknessScalar: { value: 0.025 },
                    uColor: { value: new THREE.Color(0.4, 0.4, 0.4) },
                    uAlpha: { value: 1.0 }
                }        
            });

            const puddleMesh = new THREE.Mesh(puddleGeometry, puddleMaterial)
            this.scene.add(puddleMesh)

            const pushIndex = Math.round(Math.random() * 20);

            this.puddles.splice(pushIndex, 0, puddleMesh);

            //this.puddles.push(puddleMesh);

            nextY = (Math.random() * 0.6) - 0.3 + 0.3;
            nextZ = (Math.random() * 0.7) - 0.35;

            puddleMesh.position.set(nextX, nextY, nextZ);
            puddleMesh.scale.set(0.2, 0.2, 0.2);
            puddleMesh.rotation.y =  Math.PI * 0.5;

            puddleMesh.material.uniforms.uAlpha.value = 0.0;
        }

        // this.puddles[0].material.uniforms.uAlpha.value = 1.0;
        // puddleMesh.material.uniforms.uThicknessScalar.value = 50.0 * 0.075;
        // puddleMesh.material.uniforms.uScale.value = 10.0 * 25.0;    
        // puddleMesh.material.uniforms.uAlpha.value = 0.8;
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    addBug(bugPos, isBursting = true)
    {        
        const material = new THREE.ShaderMaterial({
            vertexShader: bugVertexShader,
            fragmentShader: bugFragmentShader,
            side: THREE.DoubleSide,
            uniforms: {
                uMaxLength: { value: 1.5 },
                uTime: { value: 0.0 },
                uCenter: { value: bugPos }
            }
        });

        const geometry = new THREE.BufferGeometry();            
        const positionsArray = new Float32Array(this.params.numLinesPerStar * 2 * 3); // 20 lines, 2 vertices per line, 3 positions per vertex

        for(let i = 0; i < this.params.numLinesPerStar; i++) {
            const i6 = i * 6;
            /// Vertex #1
            // all lines start at center
            positionsArray[i6] = 0.0; // x
            positionsArray[i6 + 1] = 0.0; // y
            positionsArray[i6 + 2] = 0.0; // z
            /// Vertex #2
            positionsArray[i6 + 3] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // x
            positionsArray[i6 + 4] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // y
            positionsArray[i6 + 5] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // z
        }

        const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);
        geometry.setAttribute('position', positionsAttribute);

        const mesh = new THREE.LineSegments(geometry, material)
        const bug = new MindBug(0.0, 0.25);   
        if(isBursting == true)
            bug.setState("bursting");  
        else
            bug.setState("stay");       
        bug.add(mesh);

        bug.setPosition(bugPos);       

        this.bugs.push(bug);        
        this.scene.add(bug);
    }

    runEyesAppear(showLength, itemDelay, numEyes)
    {
        let currItemDelay = 0;

        for(let i = 0; i < this.puddles.length; i++) {
            const t1 = gsap.timeline();
            t1.to(this.puddles[i].material.uniforms.uAlpha, { duration: showLength, value: 1.0, ease: 'expo.inOut', delay: currItemDelay}) 
                .to(this.puddles[i].material.uniforms.uAlpha, { duration: showLength / 2.0, value: 0.0, ease: 'expo.inOut'}) 
                .to(this.puddles[i].scale, { duration: showLength, x: 0.5, y: 0.5, ease: 'expo.inOut', delay: currItemDelay}, -showLength * 2)
                .to(this.puddles[i].scale, { duration: showLength / 2, x: 0, y: 0, ease: 'linear', delay: currItemDelay},)
            currItemDelay += itemDelay;
        }
    }  

    doBubbleTransition(timelineSequences)
    {
        const timeline = gsap.timeline();
        for(let i = 0; i < timelineSequences.length; i++) {     
            
            let tween = gsap.to(timelineSequences[i].param, timelineSequences[i].instruction);
            timeline.add(tween);
        }
    }

    doColorTransition(timelineSequences)
    {
        const timeline = gsap.timeline();
        for(let i = 0; i < timelineSequences.length; i++) {     
            
            let tween = gsap.to(timelineSequences[i].param, timelineSequences[i].instruction);
            timeline.add(tween);
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;

        if(this.currPhase.addsBugs === true) {
            this.bugsAddTimer += deltaTime;
            if(this.bugsAddTimer >= this.maxAddBugsTimer)
            {
                if(this.bugs.length < this.maxBugs) {
                    const bugX = (Math.random() * 0.6) - 0.3;
                    const bugY = (Math.random() * 0.6) - 0.3;
                    const bugZ = (Math.random() * 0.6) - 0.3;

                    this.addBug(new THREE.Vector3(bugX, bugY, bugZ));
                }

                this.bugsAddTimer = 0.0;
            }          
        }

        if(this.currPhase.changesColor === true) {
            this.waterMaterial.uniforms.uSurfaceColor.value = this.params.surfaceColor;
            this.waterMaterial.uniforms.uDepthColor.value = this.params.depthColor;
        }

        if(this.params.areWavesChanging === true) {
            //console.log("waves are changing");
            if(this.params.isExpanding === true) {
                this.params.numSine += 0.01;
                this.params.numCosine += 0.01;
                if((this.params.numCosine > 3.5) || (this.params.numSine > 3.5)) {
                    this.params.isExpanding = false;
                }
            }
            else {
                this.params.numSine -= 0.01;
                this.params.numCosine -= 0.01;
                if((this.params.numCosine < 0) || (this.params.numSine < 0)) {
                    this.params.isExpanding = true;
                }
            }
            this.waterMaterial.uniforms.uNumSines.value = this.params.numSine;
            this.waterMaterial.uniforms.uNumCosines.value = this.params.numCosine;
        }

        // update phases    
        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                //console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex]; 

                    if(this.currPhase.changesColor === true) {
                        this.doColorTransition(this.currPhase.colorTweens);
                    }

                    // if(this.currPhase.animatesEyes === true) {
                    //     this.runEyesAppear(this.currPhase.eyeShowLength, this.currPhase.stdEyeDelay, this.currPhase.numEyesToShow);
                    // }

                    if(this.currPhase.changesElevation === true) {
                        this.doBubbleTransition(this.currPhase.elevationTweens);
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        // update active bugs
        for(let x = 0; x < this.bugs.length; x++) {
            this.bugs[x].update(deltaTime);
        }

        // remove any dead bugs
        for(let y = (this.bugs.length - 1); y >= 0; y--) {
            let bug = this.bugs[y];
            if(bug.isDead) {
                this.scene.remove(bug);
                this.bugs.splice(y, 1);
            }
        }
    }

    render()
    {
        // this.renderer.render(this.scene, this.camera);
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class