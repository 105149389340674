import * as THREE from 'three'
import vertexShader from './../shaders/confinement_lines/vertex.glsl'
import fragmentShader from './../shaders/confinement_lines/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class ConfinementLines extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 0,
            cameraZ: 0,            
            surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            depthColor: new THREE.Vector3(0.13, 0.13, 0.13),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),
            numRings: 30,
            numLinesPerRing: 12,
            radius: 7,
            startZ: 50.0
        };

        this.rings = [];
        this.geometry = null;
        this.material = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.9;
        this.unrealBloomPass.radius = 1.1;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        this.totalRunningTime = 0.0;

    } // end constructor

    buildVisual() 
    { 
        // if(this.water !== null)
        // {
        //     this.geometry.dispose();
        //     this.material.dispose();
        //     this.scene.remove(this.water)
        // }
    
        this.geometry = new THREE.PlaneGeometry(10, 0.35, 64, 64);  
    
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true,
            //side: THREE.DoubleSide,
            blending: THREE.AdditiveBlending,
            //vertexColors: true
        });
    
        // lines
        for(let i = 0; i < this.params.numRings; i++)
        {
            const ring = new THREE.Object3D();
            ring.position.set(0.0, 0.0, 0.0);
            this.scene.add(ring);
            this.rings.push(ring);

            const ringAngle = i * (360.0 / this.params.numRings);
            const ringAngleRadians = ringAngle * Math.PI / 180.0;

            ring.rotation.z = ringAngleRadians;

            let nextX = -5.0;
            let nextY = 0.0;
            let nextZ = -i * 4.0 + this.params.startZ;    
            let angle = 0.0;

            for(let y = 0; y < this.params.numLinesPerRing; y++)
            {
                const line = new THREE.Mesh( this.geometry, this.material );
                ring.add( line );

                angle = y * (360.0 / this.params.numLinesPerRing);
                const angleRadians = angle * Math.PI / 180.0;
                nextX = this.params.radius * Math.sin(angleRadians);
                nextY = this.params.radius * Math.cos(angleRadians);

                line.position.set(nextX, nextY, nextZ);

                //const lineRotation =     
                line.rotation.z = 1.0 - angleRadians;
            }   
        }
    
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        for(let i = 0; i < this.rings.length; i++)
        {
            const ring = this.rings[i];
            ring.rotation.z += deltaTime;
            ring.position.z += deltaTime * 2.0;
            if(ring.position.z > 9.0)
                ring.postion = -1 * 4.0 * this.params.numRings + this.params.startZ;    

            let children = ring.children;
            for(let y = 0; y < children.length; y++)
            {
                const line = children[y];
                line.rotation.z += deltaTime * 1.25;
            }
        }
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class