

export const normalizeData = (filteredData) => {
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map(n => n * multiplier);
}

  
export const filterData = (rawData, numAudioSamples) => {
    // sampleRate = audioBuffer.duration / numAudioSamples;
    // rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
    // numAudioSamples = rawData.length;
    // const samples = 300; // Number of samples we want to have in our final data set
    const blockSize = Math.floor(rawData.length / numAudioSamples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < numAudioSamples; i++) {
    let blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    //let filteredData = rawData;
    return filteredData;
}