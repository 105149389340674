import * as THREE from 'three'
import vertexShader from './../shaders/tiffany/vertex.glsl'
import fragmentShader from './../shaders/tiffany/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class TiffanyAudio extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 7,
            cameraZ: 0,

            /* Abel Stuff    
            numPlayerBars: 30,
            numLinesPerBar: 30,
            numPointsInLine: 9,
            staffWidth: 3.0,

            numLines: 1800,
            radius: 0.35,
            additionalRadius: 0.15,
            shortRadius: 0.1,
            additionalShortRadius: 0.25,
            */

            uSmallWavesElevation: 0.715,
            uSmallWavesFrequency: 1.312,
            uSmallWavesSpeed: 1.3,
            uSmallWavesIterations: 4,
            rectWidth: 4,
            rectHeight: 4,
            surfaceColor: new THREE.Vector3(0.059, 0.059, 0.059),
            depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
            numSine: 2.0,
            numCosine: 2.0,
            elevationAddClamp: 3.0,
            wireframe: true,
            areWavesChanging: false
            
        };

        this.lines = [];
        this.lineStaff = null;
        this.water = null;
        this.geometry = null;
        this.material = null;

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        // this.audioListener = new THREE.AudioListener();
        // this.camera.add(this.audioListener);    
        // this.sound = new THREE.Audio(this.audioListener);
        // this.scene.add(this.sound);
        // this.soundSet = false;
        // this.soundPlaying = false;

        this.sampleTimer = 0.0;
        this.sampleRate = null;
        this.currSampleIndex = 0;
        this.rawAudioData = null;
        this.normalizedAudioData = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.3;
        this.unrealBloomPass.radius = 0.325;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        /// phases
        this.phases = [
            {  // phase 1               
                startTime: 0.0,
                // hasCameraChange: false,
                // cameraZ: -0.5,
                // surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                // depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                // hasColorChange: false,
                // doingHeave: 0.0,
                // heaveMagnitude: 0.0,
                // heaveForward: false,
                // doingInternalRoation: 0.0,
                // doingJitter: 0.0,
                // doingSwirl: 0.0,
                // swirlForward: false,
                // swirlSpeed: 0.0,
                // cubeRotationSpeedDiv: 7.0
                                         
            },
            {  // phase 2 ---                 
                startTime: 2.0,
                // hasCameraChange: false,
                // cameraZ: -0.5,
                // surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                // depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                // hasColorChange: false,
                // doingHeave: 0.0,
                // heaveMagnitude: 0.0,
                // heaveForward: false,
                // doingInternalRoation: 1.0,
                // doingJitter: 1.0,
                // doingSwirl: 0.0,
                // swirlForward: false,
                // swirlSpeed: 1.0,
                // cubeRotationSpeedDiv: 7.0                                              
            },               
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.barMaterials = [];
        this.barSizes = [];

        // document.getElementById("main_body").addEventListener('click', () => { /// NEED THIS!!!
        //     this.playAudio();
        // })

        this.totalRunningTime = 0.0;

    } // end constructor

    // playAudio()
    // {
    //     if(this.soundSet) {
    //         this.sound.play();
    //         this.soundPlaying = true;
    //     }
    // }

    buildVisual() 
    {  
        this.geometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 256, 256);
        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            wireframe: this.params.wireframe,
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                uGhostHeadPos: { value: this.ghostHead1 },
    
                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },

                uCurrentRadius: { value: 0.0 }
            }
        });

        this.water = new THREE.Mesh(this.geometry, this.material);
        this.water.rotation.x = - Math.PI * 0.5;
        this.scene.add(this.water);

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ); 
        this.camera.lookAt(0,0,0);       
    }

    updateCamera(deltaTime)
    {
        if(this.params.cameraZ != this.currPhase.cameraZ) {
            this.params.cameraZ += deltaTime * 0.2;
            if(this.params.cameraZ > this.currPhase.cameraZ)
                this.params.cameraZ = this.currPhase.cameraZ;
            
            this.camera.position.z = this.params.cameraZ;    
            this.camera.lookAt(0,0,0);
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        // update cube rotation
        // this.updateRotation(deltaTime, elapsedTime);

        // if(this.currPhase.hasCameraChange == true)
        //     this.updateCamera(deltaTime); 
        // if(this.currPhase.doingHeave == 1.0)
        //     this.updateHeave(deltaTime, this.currPhase.heaveForward);
        // if(this.currPhase.doingJitter == 1.0)
        //     this.updateJitter(deltaTime);
        // if(this.currPhase.doingSwirl == 1.0)
        //     this.updateSwirl(deltaTime, this.currPhase.swirlForward);  

        // if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
        //     if(elapsedTime >= this.nextPhaseStartTime) {  
        //         this.currPhaseIndex += 1;

        //         console.log("Curr phase: " + this.currPhaseIndex);

        //         if(this.currPhaseIndex < this.phases.length) {
        //             this.currPhase = this.phases[this.currPhaseIndex];    
        //             if(this.currPhase.isEnd) {
        //                 if(this.currPhase.isEnd == true)
        //                     this.scene.remove(this.lineCube);
        //             }              

        //             if((this.currPhaseIndex + 1) < this.phases.length)
        //                 this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
        //             else {
        //                 this.isDone = true;                        
        //             }
        //         }                
        //     }
        // }
        
        // update wave
        //this.material.uniforms.uTime.value = elapsedTime / 4.0;
        
        this.totalRunningTime += deltaTime;

        // update audio
        if(this.soundPlaying == true) {
            this.sampleTimer += deltaTime;
            if(this.sampleTimer >= this.sampleRate) {
                if(this.currSampleIndex < this.rawAudioData.length) {                    
                    this.material.uniforms.uCurrentRadius.value = this.normalizedAudioData[this.currSampleIndex];                 

                    this.currSampleIndex++;        
                }
                this.sampleTimer = 0.0;
            }            
        }

        this.material.uniforms.uTime.value = this.totalRunningTime;

        if(this.params.areWavesChanging === true) {
           
            // if(this.params.isExpanding === true) {
            //     this.params.numSine += 0.01;
            //     this.params.numCosine += 0.01;
            //     if((this.params.numCosine > 4.0) || (this.params.numSine > 4.0)) {
            //         this.params.isExpanding = false;
            //     }
            // }
            // else {
            //     this.params.numSine -= 0.01;
            //     this.params.numCosine -= 0.01;
            //     if((this.params.numCosine < 0) || (this.params.numSine < 0)) {
            //         this.params.isExpanding = true;
            //     }
            // }
            this.material.uniforms.uNumSines.value = params.numSine;
            this.material.uniforms.uNumCosines.value = params.numCosine;
        }
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
        
        this.sampleRate = sampleRate;        
        this.rawAudioData = rawAudioData;
        this.normalizedAudioData = normalizedAudioData;
    }

    render()
    {
        this.effectComposer.render();
        // this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class