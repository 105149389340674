import * as THREE from 'three'
import vertexShader from './../shaders/Tunnel/vertex.glsl'
import fragmentShader from './../shaders/Tunnel/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'
import gsap from "gsap";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Tunnel extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            numRings: 200,
            pointsPerRing: 360.0 / 2.0,
            radius: 3.5,
            size: 1.5,
            spaceBetweenRings: 0.15,
            startZ: 9.0,
            farthestZ: 0.0,
            ringsSpeed: 0.5,
            branches: 3,
            spin: 1,
            randomness: 0.5,
            randomnessPower: 3,
            insideColor: '#ff6030',
            outsideColor: '#1b3984',
            gsapStarted: false,

            nextRingScale: 1.0,

            baseScale: 1.0,
            baseAlpha: 0.0,

            uSmallWavesElevation: 0.345,
            uSmallWavesFrequency: 5.731,
            uSmallWavesSpeed: 0.241,
            uSmallWavesIterations: 4,

            startRotationTime: 48.0,
            doingRotation: false,
            rotationMultiplier: 1.0,

            doingFadeOut: false
        };

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.5;
        this.unrealBloomPass.radius = 0.5;
        this.unrealBloomPass.threshold = 0.1;
        // this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

       
        this.rings = [];
        this.materials = [];

        this.points = null;
        this.geometry = null;
        this.material = null;

        this.goingDown = false;
        this.downCount = 0.0;
        this.maxDownCount = 3.0;

        this.goingUp = true;
        this.upCount = 0.0;
        this.maxUpCount = 3.0;

        this.nextRingStart = new THREE.Vector3(0, 0);
        this.cameraPosition = new THREE.Vector3(0, 0, 0);

        this.doneFadeIn = false;

        this.phases = [
            {  // phase 1                
                startTime: 0.0,
                doesSpeedChange: false,  
            },
            {  // speed change             
                startTime: 0.5,
                doesSpeedChange: true,
                speedTween: [{
                    param: this.params, 
                    instruction: { duration: 14.5, ringsSpeed: 5.0, ease: 'power1.in'}
                }]                

            },            
            {  // rotation change             
                startTime: 19.0,
                doesRotationChange: true,
                rotationTweens: [
                    {
                        param: this.params,
                        instruction: { duration: 3.0, rotationMultiplier: 0.5, ease: 'power.in' }
                    },
                    {
                        param: this.params,
                        instruction: { duration: 0.75, rotationMultiplier: 0.0, ease: 'power.in' }
                    }
                ],                
            },            
            {  // rotation change             
                startTime: 22.75,
                doesRotationChange: false,  
            },
            {  // speed change                
                startTime: 23.0,
                doesSpeedChange: true,
                speedTween: [{
                    param: this.params, 
                    instruction: { duration: 2.0, ringsSpeed: 7.0, ease: 'power1.in'}
                }]                                             
            },                   
            {  // movement change              
                startTime: 25.0,
                doesSpeedChange: false,                
                doesMovementChange: true,
                movementTweens: [
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 2.0, x: -3, y: -1, ease: 'sine.inOut' }
                    },
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 2.0, x: 3, y: 1, ease: 'sine.inOut' }
                    },
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 1.0, x: 0, y: 0, ease: 'sine.inOut' }
                    },                    
                ],
                doesCameraChange: true,
                cameraTweens: [
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 2.0, x: -3, y: -1, ease: 'sine.inOut', delay: 4 }
                    },
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 2.0, x: 3, y: 1, ease: 'sine.inOut' }
                    },
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 1.0, x: 0, y: 0, ease: 'sine.inOut' }
                    },                     
                ]                                
            },
            {  // movement change              
                startTime: 32.0,
                doesSpeedChange: false,                
                doesMovementChange: true,
                movementTweens: [
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 2.0, x: -1, y: -3, ease: 'sine.inOut' }
                    },
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 2.0, x: 1, y: 3, ease: 'sine.inOut' }
                    },
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 1.0, x: 0, y: 0, ease: 'sine.inOut' }
                    },                    
                ],
                doesCameraChange: true,
                cameraTweens: [
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 2.0, x: -1, y: -3, ease: 'sine.inOut', delay: 4 }
                    },
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 2.0, x: 1, y: 3, ease: 'sine.inOut' }
                    },
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 1.0, x: 0, y: 0, ease: 'sine.inOut' }
                    },                     
                ]                                
            },           
            {  // movement change              
                startTime: 38.0,
                doesSpeedChange: false,                
                doesMovementChange: true,
                movementTweens: [
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 1.5, x: -3, y: 2, ease: 'sine.inOut' }
                    },
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 1.5, x: 3, y: -2, ease: 'sine.inOut' }
                    },                    
                    {
                        param: this.nextRingStart, 
                        instruction: { duration: 1.5, x: 0, y: 0, ease: 'sine.inOut' }
                    },                    
                ],
                doesCameraChange: true,
                cameraTweens: [
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 1.5, x: -3, y: 2, ease: 'sine.inOut', delay: 4 }
                    },
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 1.5, x: 3, y: -2, ease: 'sine.inOut' }
                    },                   
                    {
                        param: this.cameraPosition, 
                        instruction: { duration: 1.5, x: 0, y: 0, ease: 'sine.inOut' }
                    },                     
                ]                                
            },    
            {  // rotation change             
                startTime: 47.5,
                doesRotationChange: true,
                rotationTweens: [
                    {
                        param: this.params,
                        instruction: { duration: 3.0, rotationMultiplier: 3.0, ease: 'power.in' }
                    },   
                    {
                        param: this.params,
                        instruction: { duration: 1.0, rotationMultiplier: 0.0, ease: 'power.in' }
                    },                  
                ],                
            },        
            {  // speed change             
                startTime: 51.5,
                doesAlphaChange: true,
                // alphaTween: [{
                //     param: this.params, 
                //     instruction: { duration: 2.0, baseAlpha: 0.0, ease: 'linear'}
                // }]                

            }, 
        ];

       
        this.currPhaseIndex = 0;
        this.currPhase = this.phases[0];
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.isDone = false;

        this.totalRunningTime = 0.0;
    }

    buildVisual() 
    {
        // if(this.points !== null)
        // {
        //     this.geometry.dispose()
        //     this.material.dispose()
        //     this.scene.remove(this.points)
        // }

        this.params.farthestZ = this.params.startZ - ((this.params.numRings) * this.params.spaceBetweenRings);

        let startZ = this.params.startZ;

        for(let ringInd = 0; ringInd < this.params.numRings; ringInd++) {    
            
            const geometry = new THREE.BufferGeometry();
            const ring = new THREE.Object3D();

            const positions = new Float32Array(this.params.pointsPerRing * 3);    
            const scales = new Float32Array(this.params.pointsPerRing * 1);
            
            let angle = 0;
            let i3 = 0;            
            
            for(let i = 0; i < this.params.pointsPerRing; i++)
            {
                let i3 = i * 3;

                const angle = i * (360.0 / this.params.pointsPerRing);
                const angleRadians = angle * (Math.PI / 180.0);
            
                // Position   
                positions[i3    ] = Math.cos(angleRadians) * this.params.radius;
                positions[i3 + 1] = Math.sin(angleRadians) * this.params.radius;
                positions[i3 + 2] = 0.0;

                scales[i] = Math.max(0.3, Math.pow(Math.random() + 0.3, 5.0)); // + 0.5;
            }

            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
            geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));
        
            const material = new THREE.ShaderMaterial({
                depthWrite: false,
                blending: THREE.AdditiveBlending,
                vertexColors: true,
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                uniforms:
                {
                    uSize: { value: 25.0  * this.renderer.getPixelRatio() },  ////// <-- NOTE: need to set the pixel ratio for high def screens
                    uTime: { value: 0.0 },
                    uRadius: { value: this.params.radius },

                    uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                    uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                    uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                    uSmallWavesIterations: { value: this.params.uSmallWavesIterations },

                    uBaseScale: { value: this.params.baseScale },
                    uBaseAlpha: { value: this.params.baseAlpha }
                }
            });

            ring.position.z = startZ;
    
            startZ -= this.params.spaceBetweenRings;   
                    
            const points = new THREE.Points(geometry, material);
            ring.add(points);

            this.materials.push(material);
            this.rings.push(ring);

            this.scene.add(ring);
        }

        this.camera.position.x = 0
        this.camera.position.y = 0
        this.camera.position.z = 8;

        this.cameraPosition.x = 0;
        this.cameraPosition.y = 0;
        this.cameraPosition.z = 8;

        this.camera.lookAt(0,0,0);

    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateCameraPosition(deltaTime, elapsedTime)
    {
        let roundedElapsed = Math.round(elapsedTime);

        if(elapsedTime > 10)
        {
            if((roundedElapsed % 15) == 0)
            {
                gsap.to(this.camera.position, {
                    x: -2.25,
                    y: -2.25,
                    duration: 0.65,
                    ease: "linear"            
                })
            }
            else if((roundedElapsed % 10) == 0)
            {
                gsap.to(this.camera.position, {
                    x: 0,
                    y: 0,
                    duration: 0.65,
                    ease: "linear"            
                })
            }
            else if((roundedElapsed % 5) == 0)
            {
                gsap.to(this.camera.position, {
                    x: 2.25,
                    y: 2.25,
                    duration: 0.65,
                    ease: "linear"            
                })
            }
        }
    }

    updateRingPositions(deltaTime)
    {
        if(this.isDone === false)
        {
            for(let i = 0; i < this.params.numRings; i++)
            {            
                let newPositionZ = this.rings[i].position.z + (deltaTime * this.params.ringsSpeed);
                
                if(newPositionZ >= 9.0) {                    
                    
                    const diff = newPositionZ - 9.0;
                    newPositionZ = this.params.farthestZ + diff;// geometry.attributes.position.array[lastPos] - parameters.spaceBetweenRings;                    
                    
                    this.rings[i].position.x = this.nextRingStart.x;
                    this.rings[i].position.y = this.nextRingStart.y;
                    this.rings[i].position.z = newPositionZ;     
                    
                    this.rings[i].scale.x = this.params.nextRingScale;
                    this.rings[i].scale.y = this.params.nextRingScale;
                }   
                else {
                    this.rings[i].position.z = newPositionZ;   
                }  
                
                if(this.params.doingRotation === true)
                    this.rings[i].rotation.z -= deltaTime * this.params.rotationMultiplier;
            } 
        }
    }

    updateCamera()
    {
        this.camera.position.x = this.cameraPosition.x;
        this.camera.position.y = this.cameraPosition.y;
        this.camera.position.z = this.cameraPosition.z;

        this.camera.lookAt(new THREE.Vector3(this.nextRingStart.x, this.nextRingStart.y, this.nextRingStart.z));
    }    
    
    doFadeIn()
    {
        let delay = 0.0;
        for(let i = 0; i < this.params.numRings; i++)
        {            
            const ring = this.rings[i];
            const ringPoints = ring.children[0];
            const t1 = gsap.timeline();
                t1.to(ringPoints.material.uniforms.uBaseAlpha, { duration: 1.0, value: 1.0, ease: 'sine.in'}, delay) 
            
            delay += 0.0067;
        }
    }

    doFadeOut()
    {        
        console.log("doing fade out...");

        for(let i = 0; i < this.params.numRings; i++)
        {            
            const ring = this.rings[i];
            const ringPoints = ring.children[0];           
            ringPoints.material.uniforms.uBaseAlpha.value = this.params.baseAlpha;            
        }

        this.params.baseAlpha -= 0.0067;

        if(this.params.baseAlpha <= 0.0)            
            this.isDone = true;
    }

    runGsapTimeline(timelineSequences)
    {
        const timeline = gsap.timeline();
        for(let i = 0; i < timelineSequences.length; i++) {     
            
            let tween = gsap.to(timelineSequences[i].param, timelineSequences[i].instruction);
            timeline.add(tween);
        }
    }
    
    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        if(this.doneFadeIn === false) {
            this.doFadeIn();
            this.doneFadeIn = true;
        }

        if(this.params.doingFadeOut === true) {
            this.doFadeOut();
        }

        // if(elapsedTime >= this.params.startRotationTime)
        //     this.params.doingRotation = true;

        // if(this.params.gsapStarted === false) {
        //     this.runGsap();
        // }

        this.updateRingPositions(deltaTime);
        if(this.currPhase.doesCameraChange === true)
            this.updateCamera();

        // this.material.uniforms.uTime.value = elapsedTime;

        let i3 = 0;

        // update phases    
        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];                   

                    if(this.currPhase.doesSpeedChange === true) {
                        this.runGsapTimeline(this.currPhase.speedTween);
                    }  
                    // if(this.currPhase.doesScaleChange === true) {
                    //     this.runGsapTimeline(this.currPhase.scaleTween);
                    // }            
                    if(this.currPhase.doesMovementChange === true) {
                        this.runGsapTimeline(this.currPhase.movementTweens);
                    }      
                    if(this.currPhase.doesCameraChange === true) {
                        this.runGsapTimeline(this.currPhase.cameraTweens);
                    }  
                    if(this.currPhase.doesRotationChange === true) {
                        this.params.doingRotation = true;
                        this.runGsapTimeline(this.currPhase.rotationTweens);
                    } else {
                        this.params.doingRotation = false;
                    }

                    if(this.currPhase.doesAlphaChange === true) {
                        console.log("Does alpha change");
                        this.params.doingFadeOut = true;
                        this.params.baseAlpha = 1.0;
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    // else {
                    //     this.isDone = true;                        
                    // }
                }                
            }
        }
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.points !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.points);
        }
    }

} // end class