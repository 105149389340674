import * as THREE from 'three'
import waterVertexShader from './../shaders/Amina/vertex.glsl'
import waterFragmentShader from './../shaders/Amina/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'
import gsap from "gsap";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Spirits extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 6.25,
            cameraZ: 0,
            uSmallWavesElevation: 1.0, // 0.853,
            uSmallWavesFrequency: 1.1,
            uSmallWavesSpeed: 0.271,
            uSmallWavesIterations: 5,
            rectWidth: 12,
            rectHeight: 12,
            maxSurfaceColor: new THREE.Vector3(65.0 / 255.0, 65.0 / 255.0, 65.0 / 255.0),
            surfaceColor: new THREE.Vector3(65.0 / 255.0, 65.0 / 255.0, 65.0 / 255.0),
            // surfaceColor: new THREE.Vector3(25.0 / 255.0, 25.0 / 255.0, 25.0 / 255.0),
            maxDepthColor: new THREE.Vector3(45.0 / 255.0, 45.0 / 255.0, 45.0 / 255.0),
            depthColor: new THREE.Vector3(45.0 / 255.0, 45.0 / 255.0, 45.0 / 255.0),
            // depthColor: new THREE.Vector3(12.0 / 255.0, 12.0 / 255.0, 12.0 / 255.0),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),
            colorDropMultiplier: 2.0,
            smallWavesElevationMultiplier: 4.0,
            elevationClampMultiplier: 6.0,
            numSine: 0.13,
            numCosine: 0.41,
            elevationAddClamp: 2.35, //0.97,
            elevationAddClampMax: 2.35,
            wireframe: false,
            areWavesChanging: false,
            doneEnding: false
        };

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.2;
        this.unrealBloomPass.radius = 0.2;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1
                uSmallWavesElevation: 0.2,
                uSmallWavesFrequency: 0.5,
                uSmallWavesSpeed: 0.2,
                uSmallWavesIterations: 4,
                startTime: 0.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.13, 0.13, 0.13),  
                hasBigWavesChange: true,  
                hasColorChange: false,
                hasElevationChange: false,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                 
            },
            {  // phase 2 --- 
                uBigWavesElevation: -2.0,
                uSmallWavesElevation: 0.515,
                uSmallWavesFrequency: 0.0,
                uSmallWavesSpeed: 0.00,
                uSmallWavesIterations: 4,
                startTime: 10.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.13, 0.13, 0.13), 
                hasBigWavesChange: true,     
                hasColorChange: false,
                colorChangeDirection: "moot",
                hasElevationChange: true,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                                  
            },
            
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        // this.currSurfaceColor = this.phases[0].surfaceColor;
        // this.currDepthColor = this.phases[0].depthColor;

        this.audioListener = new THREE.AudioListener();
        this.camera.add(this.audioListener);    
        this.sound = new THREE.Audio(this.audioListener);
        this.scene.add(this.sound);
        this.soundSet = false;
        this.soundPlaying = false;

        this.sampleTimer = 0.0;
        this.sampleRate = null;
        this.currSampleIndex = 0;
        this.rawAudioData = null;
        this.normalizedAudioData = null;

        // document.getElementById("main_body").addEventListener('click', () => {
        //     this.playAudio();
        // })

        this.totalRunningTime = 0.0;

    } // end constructor

    playAudio()
    {
        if(this.soundSet) {
            this.sound.play();
            this.soundPlaying = true;
        }
    }

    buildVisual() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    
        this.waterGeometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 256, 256)

        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: waterVertexShader,
            fragmentShader: waterFragmentShader,
            wireframe: this.params.wireframe,
            blending: THREE.AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                // uGhostHeadPos: { value: this.ghostHead1 },
    
                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial)
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water)
    
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    updateColor(deltaTime)
    {
        const surfaceIncr = this.params.colorDropMultiplier * deltaTime;
        const depthIncr = this.params.colorDropMultiplier * deltaTime;   
            
        let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
        
        surface.x -= surfaceIncr;
        surface.y -= surfaceIncr;
        surface.z -= surfaceIncr;
        if(surface.x <= 0.0)
            surface = 0.0;
        
        this.waterMaterial.uniforms.uSurfaceColor.value = new THREE.Vector3(surface.x, surface.y, surface.z);
       
        let depth = this.waterMaterial.uniforms.uDepthColor.value;
        
        depth.x -= depthIncr;
        depth.y -= depthIncr;
        depth.z -= depthIncr;
        if(depth.x <= 0.0)
            depth = 0.0;
        
        this.waterMaterial.uniforms.uDepthColor.value = new THREE.Vector3(depth.x, depth.y, depth.z);;        
               
    }

    updateElevation(deltaTime)
    {   
        this.params.uSmallWavesElevation -= deltaTime * this.params.smallWavesElevationMultiplier;
        if(this.params.uSmallWavesElevation <= 0.5) {
            this.params.uSmallWavesElevation = 0.5;
        }
        this.params.elevationAddClamp -= deltaTime * this.params.elevationClampMultiplier;
        if(this.params.elevationAddClamp <= 0.75) {
            this.params.elevationAddClamp = 0.75
        }

        this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;   
        this.waterMaterial.uniforms.uElevationAddClamp.value = this.params.elevationAddClamp;
    }

    updatePhaseDecrease(deltaTime, elapsedTime)
    {
        this.growthTimer += deltaTime;
        if(this.growthTimer >= 0.1) {
            this.growthTimer -= 0.1;
       
            this.waterMaterial.uniforms.uBigWavesElevation.value = -2.00;
            this.waterMaterial.uniforms.uSurfaceColor.value = new THREE.Color(this.params.surfaceColorBlack);
            //this.waterMaterial.uniforms.uDepthColor.value = new THREE.Color(this.params.surfaceColorBlack);
            
              
        }   
    }

    setSoundIntensity(soundIntensity)
    {
        soundIntensity += 1.5;        

        const newSurfaceColor = new THREE.Vector3(this.params.maxSurfaceColor.x * soundIntensity,
            this.params.maxSurfaceColor.y * soundIntensity,
            this.params.maxSurfaceColor.z * soundIntensity);

        const newDepthColor = new THREE.Vector3(this.params.maxDepthColor.x * soundIntensity,
            this.params.maxDepthColor.y * soundIntensity,
            this.params.maxDepthColor.z * soundIntensity);

        // increase the colour intensity
        // this.waterMaterial.uniforms.uSurfaceColor.value = newSurfaceColor;
        // this.waterMaterial.uniforms.uDepthColor.value = newDepthColor;
   
        this.waterMaterial.uniforms.uSmallWavesElevation.value = soundIntensity; // the max is 1   
        this.waterMaterial.uniforms.uElevationAddClamp.value = this.params.elevationAddClampMax * soundIntensity;

    }

    update(deltaTime, elapsedTime) 
    {   
        this.totalRunningTime += deltaTime;

        if(this.soundPlaying == true) {
            // this.updateColor(deltaTime);
            this.updateElevation(deltaTime);
        }

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;

        // update audio
        if(this.soundPlaying == true) {
            this.sampleTimer += deltaTime;
            if(this.sampleTimer >= this.sampleRate) {
                if(this.currSampleIndex < this.rawAudioData.length) {
                    // set the current sound intensity            
                    this.setSoundIntensity(this.normalizedAudioData[this.currSampleIndex]);                   

                    this.currSampleIndex++;        
                }
                this.sampleTimer = 0.0;
            }            
        }

        if(this.totalRunningTime >= 92.0) {
            if(this.params.doneEnding === false) {
                this.runEnding();
            }
        }
    }

    runEnding()
    {
        console.log("Running ending...");
        this.params.doneEnding = true;
        const t1 = gsap.timeline();
        t1.to(this.waterMaterial.uniforms.uSurfaceColor.value, { duration: 2.0, x: 0.0, y: 0.0, z: 0.0, ease: 'expo.out'}) 
        .to(this.waterMaterial.uniforms.uDepthColor.value, { duration: 2.0, x: 0.0, y: 0.0, z: 0.0, ease: 'expo.out'}, -2.0) 
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        console.log("Sound set!");

        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
        
        this.sampleRate = sampleRate;        
        this.rawAudioData = rawAudioData;
        this.normalizedAudioData = normalizedAudioData;
    }

    render()
    {
        this.effectComposer.render();
        // this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class