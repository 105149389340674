import * as THREE from 'three'
import waterVertexShader from './../shaders/spirits/vertex.glsl'
import waterFragmentShader from './../shaders/spirits/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Spirits extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 0.2,
            cameraZ: 1.2,
            uBigWavesElevation: 0.2,
            uSmallWavesElevation: 0.2,
            uSmallWavesFrequency: 0.5,
            uSmallWavesSpeed: 0.2,
            uSmallWavesIterations: 4,
            rectWidth: 4,
            rectHeight: 2,
            surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            depthColor: new THREE.Vector3(0.13, 0.13, 0.13),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0)
        };

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.8;
        this.unrealBloomPass.radius = 1.0;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1
                uSmallWavesElevation: 0.2,
                uSmallWavesFrequency: 0.5,
                uSmallWavesSpeed: 0.2,
                uSmallWavesIterations: 4,
                startTime: 0.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.13, 0.13, 0.13),  
                hasBigWavesChange: true,  
                hasColorChange: false,
                hasElevationChange: false,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                 
            },
            {  // phase 2 --- 
                uBigWavesElevation: -2.0,
                uSmallWavesElevation: 0.615,
                uSmallWavesFrequency: 0.0,
                uSmallWavesSpeed: 0.00,
                uSmallWavesIterations: 4,
                startTime: 10.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.13, 0.13, 0.13), 
                hasBigWavesChange: true,     
                hasColorChange: false,
                colorChangeDirection: "moot",
                hasElevationChange: true,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                                  
            },
            {  // phase 3 --- going darker
                uBigWavesElevation: -2.0,
                uSmallWavesElevation: 0.515,
                uSmallWavesFrequency: 0.0,
                uSmallWavesSpeed: 0.00,
                uSmallWavesIterations: 4,
                startTime: 34.0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0), 
                hasBigWavesChange: false,     
                hasColorChange: true,
                colorChangeDirection: "dark",
                hasElevationChange: false,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                                  
            },
            {  // phase 4
                uBigWavesElevation: 0.25,
                uSmallWavesElevation: 0.515,
                uSmallWavesFrequency: 1.112,
                uSmallWavesSpeed: 0.7,
                uSmallWavesIterations: 4,
                startTime: 36.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.23, 0.23, 0.23),  
                hasBigWavesChange: true,   
                hasColorChange: true,
                colorChangeDirection: "light",
                hasElevationChange: true,
                hasFrequencyChange: true,
                hasSpeedChange: true,
                goReverse: false                                      
            },
            // {  // phase 5 -- start to go nuts
            //     uBigWavesElevation: 0.25,
            //     uSmallWavesElevation: 0.615,
            //     uSmallWavesFrequency: 1.112,
            //     uSmallWavesSpeed: 1.3,
            //     uSmallWavesIterations: 4,
            //     startTime: 34.0,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.48, 0.48, 0.48),  
            //     hasBigWavesChange: false,   
            //     hasColorChange: true,
            //     colorChangeDirection: "light",
            //     hasElevationChange: false,
            //     hasFrequencyChange: false,
            //     hasSpeedChange: false,
            //     goReverse: false                                      
            // },            
            // {  // phase 6 - //////////////// start going down
            //     uBigWavesElevation: 0.25,
            //     uSmallWavesElevation: 0.515,
            //     uSmallWavesFrequency: 1.112,
            //     uSmallWavesSpeed: 1.3,
            //     uSmallWavesIterations: 4,
            //     startTime: 45.0,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.23, 0.23, 0.23),  
            //     hasBigWavesChange: false,   
            //     hasColorChange: true,
            //     colorChangeDirection: "dark",
            //     hasElevationChange: true,
            //     hasFrequencyChange: false,
            //     hasSpeedChange: false,
            //     goReverse: false                                      
            // },
            {  // phase 7 -- going dark
                uBigWavesElevation: 0.25,
                uSmallWavesElevation: 0.515,
                uSmallWavesFrequency: 1.112,
                uSmallWavesSpeed: 1.3,
                uSmallWavesIterations: 4,
                startTime: 75.0,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0),  
                hasBigWavesChange: false,   
                hasColorChange: true,
                colorChangeDirection: "dark",
                hasElevationChange: false,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                      
            },
            {  // phase 8 -- reappear in slow form
                uBigWavesElevation: 0.2,
                uSmallWavesElevation: 0.2,
                uSmallWavesFrequency: 0.5,
                uSmallWavesSpeed: 0.2,
                uSmallWavesIterations: 4,
                startTime: 77.0,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.13, 0.13, 0.13), 
                hasBigWavesChange: true,     
                hasColorChange: true,
                colorChangeDirection: "light",
                hasElevationChange: true,
                hasFrequencyChange: true,
                hasSpeedChange: true,
                goReverse: true                                            
            },
            {  // phase 9 -- go dark
                uBigWavesElevation: 0.2,
                uSmallWavesElevation: 0.2,
                uSmallWavesFrequency: 0.5,
                uSmallWavesSpeed: 0.2,
                uSmallWavesIterations: 4,
                startTime: 93.5,
                surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0), 
                hasBigWavesChange: false,     
                hasColorChange: true,
                colorChangeDirection: "dark",
                hasElevationChange: false,
                hasFrequencyChange: false,
                hasSpeedChange: false,
                goReverse: false                                             
            },
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.totalRunningTime = 0.0;

    } // end constructor

    buildVisual() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    
        this.waterGeometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 256, 256)
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: waterVertexShader,
            fragmentShader: waterFragmentShader,
            wireframe: true,
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
        
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial)
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water)
    
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateColor(deltaTime, elapsedTime, changeDirection)
    {
        const surfaceIncr = 0.025;
        const depthIncr = 0.0125;

        if(changeDirection === "light") {
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x < this.currPhase.surfaceColor.x) {
                surface.x += surfaceIncr;
                surface.y += surfaceIncr;
                surface.z += surfaceIncr;
                if(surface.x >= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x < this.currPhase.depthColor.x) {
                depth.x += depthIncr;
                depth.y += depthIncr;
                depth.z += depthIncr;
                if(depth.x >= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }
        else { // dark
            let surface = this.waterMaterial.uniforms.uSurfaceColor.value;
            if(surface.x > this.currPhase.surfaceColor.x) {
                surface.x -= surfaceIncr;
                surface.y -= surfaceIncr;
                surface.z -= surfaceIncr;
                if(surface.x <= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.waterMaterial.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.waterMaterial.uniforms.uDepthColor.value;
            if(depth.x > this.currPhase.depthColor.x) {
                depth.x -= depthIncr;
                depth.y -= depthIncr;
                depth.z -= depthIncr;
                if(depth.x <= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = depth;
            }
        }        
    }

    updateElevation(deltaTime, elapsedTime, goReverse = false)
    {        
        console.log("Updating phase 2: " + this.currPhaseIndex);

        if(goReverse == true) {
            if(this.params.uSmallWavesElevation > this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation -= deltaTime;
                if(this.params.uSmallWavesElevation < this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }
        else {
            if(this.params.uSmallWavesElevation < this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation += deltaTime;
                if(this.params.uSmallWavesElevation > this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }         
    }

    updateFrequency(deltaTime, elapsedTime, goReverse = false)
    {
        if(goReverse == true) {
            if(this.params.uSmallWavesFrequency > this.currPhase.uSmallWavesFrequency) {
                // this.params.uSmallWavesFrequency += deltaTime;
                // if(this.params.uSmallWavesFrequency > this.currPhase.uSmallWavesFrequency)
                    this.params.uSmallWavesFrequency = this.currPhase.uSmallWavesFrequency;

                this.waterMaterial.uniforms.uSmallWavesFrequency.value = this.params.uSmallWavesFrequency;
            }
        }
        else {
            if(this.params.uSmallWavesFrequency < this.currPhase.uSmallWavesFrequency) {
                // this.params.uSmallWavesFrequency += deltaTime;
                // if(this.params.uSmallWavesFrequency > this.currPhase.uSmallWavesFrequency)
                    this.params.uSmallWavesFrequency = this.currPhase.uSmallWavesFrequency;

                this.waterMaterial.uniforms.uSmallWavesFrequency.value = this.params.uSmallWavesFrequency;
            }
        }
    }

    updateSpeed(deltaTime, elapsedTime, goReverse = false)
    {
        if(goReverse == true) {
            if(this.params.uSmallWavesSpeed > this.currPhase.uSmallWavesSpeed) {
                // this.params.uSmallWavesSpeed += deltaTime;
                // if(this.params.uSmallWavesSpeed > this.currPhase.uSmallWavesSpeed)
                    this.params.uSmallWavesSpeed = this.currPhase.uSmallWavesSpeed;

                this.waterMaterial.uniforms.uSmallWavesSpeed.value = this.params.uSmallWavesSpeed;     
            }  
        }
        else {
            if(this.params.uSmallWavesSpeed < this.currPhase.uSmallWavesSpeed) {
                // this.params.uSmallWavesSpeed += deltaTime;
                // if(this.params.uSmallWavesSpeed > this.currPhase.uSmallWavesSpeed)
                    this.params.uSmallWavesSpeed = this.currPhase.uSmallWavesSpeed;

                this.waterMaterial.uniforms.uSmallWavesSpeed.value = this.params.uSmallWavesSpeed;     
            }  
        }
    }

    updatePhaseDecrease(deltaTime, elapsedTime)
    {
        this.growthTimer += deltaTime;
        if(this.growthTimer >= 0.1) {
            this.growthTimer -= 0.1;
       
            this.waterMaterial.uniforms.uBigWavesElevation.value = -2.00;
            this.waterMaterial.uniforms.uSurfaceColor.value = new THREE.Color(this.params.surfaceColorBlack);
            //this.waterMaterial.uniforms.uDepthColor.value = new THREE.Color(this.params.surfaceColorBlack);

            console.log("Updating phase 2: " + elapsedTime);
              
        }   
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;    

        if(this.currPhaseIndex >= 1) { // phase 2 
            this.growthTimer += deltaTime;
            if(this.growthTimer >= 0.1) {
                this.growthTimer -= 0.1;

                if(this.currPhase.hasColorChange == true)
                    this.updateColor(deltaTime, this.totalRunningTime, this.currPhase.colorChangeDirection);    
                if(this.currPhase.hasElevationChange == true)
                    this.updateElevation(deltaTime, this.totalRunningTime, this.currPhase.goReverse);
                if(this.currPhase.hasFrequencyChange == true)
                    this.updateFrequency(deltaTime, this.totalRunningTime, this.currPhase.goReverse);
                if(this.currPhase.hasSpeedChange == true)
                    this.updateSpeed(deltaTime, this.totalRunningTime, this.currPhase.goReverse);
            }
        }
        else {
            if(this.currPhaseIndex < this.phases.length)
                console.log("Curr phase xxx: " + this.currPhaseIndex);
            else if(this.currPhaseIndex === this.phases.length - 1)
                console.log("Is done!");
        }

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];

                    if(this.currPhase.hasBigWavesChange)
                        this.waterMaterial.uniforms.uBigWavesElevation.value = this.currPhase.uBigWavesElevation;

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class