import * as THREE from 'three'

export default class MindBugs extends THREE.Object3D
{
    constructor(startScale, maxScale)
    {
        super();

        this.params = {           
            scalePowerBurst: 3.5,
            maxScaleBurst: maxScale,
            rotationSpeed: 50.0,
        }

        this.isExpanding = true;
        this.myScale = startScale;
        this.isRotating = true;
        this.state = "bursting";
        this.isDead = false;
        this.age = 0.0;        

        this.xRotationDirection = Math.random() < 0.5 ? -1.0 : 1.0; // left or right
        this.yRotationDirection = Math.random() < 0.5 ? -1.0 : 1.0; // left or right
    }

    setIsRotating(isRotating)
    {
        this.isRotating = isRotating;
    }

    setIsExpanding(isExpanding)
    {
        this.isExpanding = isExpanding;
    }

    setState(state)
    {
        this.state = state;
    }

    setPosition(pos)
    {
        this.position.x = pos.x; 
        this.position.y = pos.y;
        this.position.z = pos.z;
    }

    update(deltaTime)
    {
        this.age += deltaTime;

        this.updateRoation(deltaTime);

        if(this.state === "bursting") {
            if(this.isExpanding == true) {
                this.myScale += Math.pow(this.age, this.params.scalePowerBurst);
                if(this.myScale >= this.params.maxScaleBurst) {
                    this.myScale = this.params.maxScaleBurst;
                    this.isExpanding = false;
                    this.age /= 2.0;
                }
            }
            else {
                this.myScale -= Math.pow(this.age, this.params.scalePowerBurst);
                if(this.myScale <= 0.0) {
                    this.myScale = 0.0;
                    this.isDead = true;
                }
            }
            this.scale.set(this.myScale, this.myScale, this.myScale);
        }
        else if(this.state === "stay") {
            if(this.isExpanding == true) {
                this.myScale += Math.pow(this.age, this.params.scalePowerBurst);
                if(this.myScale >= this.params.maxScaleBurst) {
                    this.myScale = this.params.maxScaleBurst;
                    this.isExpanding = false;
                    this.age /= 2.0;
                }
            }
            this.scale.set(this.myScale, this.myScale, this.myScale);
        }
        else if(this.state === "kill") {
            this.myScale -= Math.pow(this.age, this.params.scalePowerBurst);
            if(this.myScale <= 0.0) {
                this.myScale = 0.0;
                this.isDead = true;
            }
            this.scale.set(this.myScale, this.myScale, this.myScale);
        }
    }

    updateRoation(deltaTime)
    {
        this.rotateX(this.xRotationDirection * deltaTime * this.params.rotationSpeed * Math.PI / 190.0);
        this.rotateY(this.yRotationDirection * deltaTime * this.params.rotationSpeed * Math.PI / 190.0)
    }
    
}
