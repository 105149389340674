import * as THREE from 'three'
import cubeVertexShader from './../shaders/cube/cubeVertex.glsl'
import cubeFragmentShader from './../shaders/cube/cubeFragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

export default class Cube extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            cameraX: 0,
            cameraY: 1.775,
            cameraZ: 0,
            uSmallWavesElevation: 0.0075,
            uSmallWavesFrequency: 10.0,
            uSmallWavesSpeed: 40.0,
            uSmallWavesIterations: 5,
            rectWidth: 8,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(0.002, 0.002, 0.002),
            depthColor: new THREE.Vector3(0.083, 0.083, 0.083),
            numSine: 0.48,
            numCosine: 0,
            maxCosine: 3,
            cosineGrowthSpeed: 0.02,
            elevationAddClamp: 4.0,
            wireframe: true,
            areWavesChanging: true,
            isExpanding: false,
            pointsWidth: 500,
            pointsHeight: 500,

            currPulse: 0
        }

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        this.phases = [
            {   // phase 1
                cameraY: 1.875,
                hasCameraChange: false,
                maxCosine: 3,
                numPulses: 2,
                isEnd: false
            },
            {   
                cameraY: 1.275,
                hasCameraChange: true,
                maxCosine: 5,
                numPulses: 2,
                isEnd: false
            },
            {   
                cameraY: 1.275,
                hasCameraChange: false,
                maxCosine: 7,
                numPulses: 2,
                isEnd: false
            },
            {   
                cameraY: 1.275,
                hasCameraChange: false,
                maxCosine: 9,
                numPulses: 2,
                isEnd: false
            },
            {   
                cameraY: 1.275,
                hasCameraChange: false,
                maxCosine: 0,
                numPulses: 0,
                isEnd: true
            },
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;

        this.totalRunningTime = 0.0;
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    buildVisual() 
    {
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water);
        }
    
        this.waterGeometry = new THREE.BoxGeometry(1, 1, 1, 100, 100, 100);
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: cubeVertexShader,
            fragmentShader: cubeFragmentShader,
            depthWrite: false,
            wireframe: true,
            blending: THREE.AdditiveBlending,
            uniforms: {
                uTime: { value: 0.0 },

                uGhostHeadPos: { value: this.ghostHead1 },

                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 },

                uSize: { value: 2.0  * this.renderer.getPixelRatio() }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial);
        //this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water);

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)
    }

    updateCamera(deltaTime, elapsedTime)
    {
        this.camera.position.y -= deltaTime;
        if(this.camera.position.y < this.currPhase.cameraY)
            this.camera.position.y = this.currPhase.cameraY;
    }

    updateHeartPulses()
    {
        this.params.currPulse += 1;
        if(this.params.currPulse == this.currPhase.numPulses) {
            this.currPhaseIndex += 1;
            if(this.currPhaseIndex < this.phases.length) {
                this.currPhase = this.phases[this.currPhaseIndex];
                
                if(this.currPhase.isEnd) { // if at the end, make everything black
                    this.waterMaterial.uniforms.uDepthColor.value = new THREE.Vector3(0.0, 0.0, 0.0);
                }
                else {
                    this.params.currPulse = 0;
                    this.params.maxCosine = this.currPhase.maxCosine;
                }
            }
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;

        if(this.currPhase.hasCameraChange == true) {
            if(this.camera.position.y != this.currPhase.cameraY)
                this.updateCamera(deltaTime);
        }

        if(this.params.areWavesChanging === true) {
            //console.log("waves are changing");
            if(this.params.isExpanding === true) {
                //this.params.numSine += 0.01;
                this.params.numCosine += this.params.cosineGrowthSpeed;
                if((this.params.numCosine > this.params.maxCosine) || (this.params.numSine > 3.5)) {
                    this.params.isExpanding = false;
                }
            }
            else {
                //this.params.numSine -= 0.01;
                this.params.numCosine -= this.params.cosineGrowthSpeed;
                if((this.params.numCosine < 0) || (this.params.numSine < 0)) {
                    this.params.isExpanding = true;
                   // console.log("End cycle: " + elapsedTime);
                    this.updateHeartPulses();
                }
            }
            this.waterMaterial.uniforms.uNumSines.value = this.params.numSine;
            this.waterMaterial.uniforms.uNumCosines.value = this.params.numCosine;
        }
    }

    render()
    {
        this.renderer.render(this.scene, this.camera);
        // this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class