import * as THREE from 'three'
import waterVertexShader from './../shaders/jasonThunder/thunderVertex.glsl'
import waterFragmentShader from './../shaders/jasonThunder/thunderFragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Thunder extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: -4,
            cameraY: -0.75,
            cameraZ: 0.0,
            //uBigWavesElevation: 0.2,
            uSmallWavesElevation: 0.506,
            uSmallWavesFrequency: 2.96,
            uSmallWavesSpeed: 0.795,
            uSmallWavesIterations: 4,
            rectWidth: 12,
            rectHeight: 12,
            surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),
            unrealBloomParams: {
                strength: 0.1,
                radius: 0.2,
                threshold: 0.1,
            }
        };

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 800,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
        this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
        this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1
                startTime: 0.0,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                  
                changeColorForward: true,
                elevationForward: false,
                unrealForward: false                              
            },
            {  // phase 1
                startTime: 1.5,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.235, 0.235, 0.235),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                  
                changeColorForward: true,
                elevationForward: false,
                unrealForward: false                              
            },            
            {  // phase 2 --- 
                startTime: 5.0,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.465, 0.465, 0.465),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                changeColorForward: true,
                elevationForward: false,
                unrealForward: false                                                  
            },
            {  // phase 2 --- 
                startTime: 13.0,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.235, 0.235, 0.235),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },  
                changeColorForward: false,
                elevationForward: false,
                unrealForward: false                                                  
            },
            {  // phase 1
                startTime: 15.0,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.15, 0.15, 0.15),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                  
                changeColorForward: false,
                elevationForward: false,
                unrealForward: false                              
            },
            {  // phase 1
                startTime: 17.0,
                uSmallWavesElevation: 0.506,
                surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
                depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
                unrealBloomParams: {
                    strength: 0.1,
                    radius: 0.2,
                    threshold: 0.1,
                },                  
                changeColorForward: false,
                elevationForward: false,
                unrealForward: false                              
            },
            // {  // phase 3 - want to oscilate here (into thunder) ////////////////////
            //     startTime: 20.0,
            //     uSmallWavesElevation: 0.106,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.365, 0.365, 0.365),
            //     unrealBloomParams: {
            //         strength: 0.1,
            //         radius: 0.2,
            //         threshold: 0.1,
            //     },  
            //     changeColorForward: true,  
            //     goReverse: false                                                
            // },
            // {  // phase 3 -- thunder
            //     startTime: 10.0,
            //     uSmallWavesElevation: 0.106,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.365, 0.365, 0.365),
            //     unrealBloomParams: {
            //         strength: 0.1,
            //         radius: 0.2,
            //         threshold: 0.1,
            //     },  
            //     changeColorForward: false,
            //     elevationForward: false,
            //     unrealForward: false                                           
            // },
            // {  // phase 4 --  go nuts
            //     startTime: 30.0,
            //     uSmallWavesElevation: 0.906,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.65, 0.65, 0.65),
            //     unrealBloomParams: {
            //         strength: 0.6,
            //         radius: 0.6,
            //         threshold: 0.1,
            //     },
            //     changeColorForward: true,                  
            //     elevationForward: true,
            //     unrealForward: true                                         
            // },            
            // {  // phase 5 - //////////////// start going down
            //     startTime: 45.0,
            //     uSmallWavesElevation: 0.906,
            //     surfaceColor: new THREE.Vector3(1.0, 1.0, 1.0),
            //     depthColor: new THREE.Vector3(0.55, 0.55, 0.55),
            //     unrealBloomParams: {
            //         strength: 0.45,
            //         radius: 0.45,
            //         threshold: 0.1,
            //     },  
            //     changeColorForward: false, 
            //     elevationForward: false,
            //     unrealForward: false                                       
            // },
            // {  // phase 6 -- going dark
            //     startTime: 55.0,
            //     uSmallWavesElevation: 0.306,
            //     surfaceColor: new THREE.Vector3(0.0, 0.0, 0.0),
            //     depthColor: new THREE.Vector3(0.0, 0.0, 0.0),
            //     unrealBloomParams: {
            //         strength: 0.45,
            //         radius: 0.45,
            //         threshold: 0.1,
            //     },  
            //     changeColorForward: false,
            //     elevationForward: false,
            //     unrealForward: false                                          
            // }            
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        // this.params.surfaceColor = new THREE.Vector3(0.0, 0.0, 0.0);
        // this.params.depthColor = new THREE.Vector3(0.0, 0.0, 0.0);

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.totalRunningTime = 0.0;

    } // end constructor

    buildVisual() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    
        this.waterGeometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 512, 512)
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: waterVertexShader,
            fragmentShader: waterFragmentShader,
            wireframe: true,
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                uBigWavesElevation: { value: 0.0 },
                uBigWavesFrequency: { value: new THREE.Vector2(0, 0) },
                uBigWavesSpeed: { value: 0.0 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial)
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water)
    
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.rotation.y = 90 * -Math.PI / 180.0
        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    updateColor(deltaTime, elapsedTime, goForward = true)
    {
        const surfaceIncr = 0.025;
        const depthIncr = 0.0125;

        if(goForward == true) {
            
            // if(this.params.surfaceColor.x !== this.currPhase.surfaceColor.x) {
            //     this.params.surfaceColor.x += surfaceIncr;
            //     this.params.surfaceColor.y += surfaceIncr;
            //     this.params.surfaceColor.z += surfaceIncr;
            //     if(this.params.surfaceColor.x >= this.currPhase.surfaceColor.x)
            //         this.params.surfaceColor.x = this.currPhase.surfaceColor;
                
            //     this.waterMaterial.uniforms.uSurfaceColor.value = this.params.surfaceColor.x;
            // }            
            if(this.params.depthColor.x < this.currPhase.depthColor.x) {
                this.params.depthColor.x += depthIncr;
                this.params.depthColor.y += depthIncr;
                this.params.depthColor.z += depthIncr;
                if(this.params.depthColor.x > this.currPhase.depthColor.x)
                    this.params.depthColor = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = this.params.depthColor;
            }
        }
        else { // dark            
            // if(this.params.surfaceColor.x !== this.currPhase.surfaceColor.x) {
            //     this.params.surfaceColor.x -= surfaceIncr;
            //     this.params.surfaceColor.y -= surfaceIncr;
            //     this.params.surfaceColor.z -= surfaceIncr;
            //     if(this.params.surfaceColor.x <= this.currPhase.surfaceColor.x)
            //         this.params.surfaceColor = this.currPhase.surfaceColor;
                
            //     this.waterMaterial.uniforms.uSurfaceColor.value = this.params.surfaceColor;
            // }            
            if(this.params.depthColor.x > this.currPhase.depthColor.x) {
                this.params.depthColor.x -= depthIncr;
                this.params.depthColor.y -= depthIncr;
                this.params.depthColor.z -= depthIncr;
                if(this.params.depthColor.x < this.currPhase.depthColor.x)
                    this.params.depthColor = this.currPhase.depthColor;
                
                this.waterMaterial.uniforms.uDepthColor.value = this.params.depthColor;
            }
        }        
    }

    updateElevation(deltaTime, elapsedTime, goReverse = false)
    {        
        console.log("Updating phase 2: " + this.currPhaseIndex);

        if(goReverse == true) {
            if(this.params.uSmallWavesElevation !== this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation -= deltaTime;
                if(this.params.uSmallWavesElevation < this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }
        else {
            if(this.params.uSmallWavesElevation !== this.currPhase.uSmallWavesElevation) {  
                this.params.uSmallWavesElevation += deltaTime;
                if(this.params.uSmallWavesElevation > this.currPhase.uSmallWavesElevation)
                    this.params.uSmallWavesElevation = this.currPhase.uSmallWavesElevation;
                
                this.waterMaterial.uniforms.uSmallWavesElevation.value = this.params.uSmallWavesElevation;    
            }    
        }         
    }

    updateBloom(deltaTime, elapsedTime, goReverse = false)
    {
        const updateMulti = 3.0;

        if(goReverse == true) {
            if(this.params.unrealBloomParams.strength > this.currPhase.unrealBloomParams.strength) {
                this.params.unrealBloomParams.strength -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength < this.currPhase.unrealBloomParams.strength)
                    this.params.unrealBloomParams.strength = this.currPhase.unrealBloomParams.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius > this.currPhase.unrealBloomParams.radius) {
                this.params.unrealBloomParams.radius -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius < this.currPhase.unrealBloomParams.radius)
                    this.params.unrealBloomParams.radius = this.currPhase.unrealBloomParams.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold > this.currPhase.unrealBloomParams.threshold) {
                this.params.unrealBloomParams.threshold -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold < this.currPhase.unrealBloomParams.threshold)
                    this.params.unrealBloomParams.threshold = this.currPhase.unrealBloomParams.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
        else {
            if(this.params.unrealBloomParams.strength < this.currPhase.unrealBloomParams.strength) {
                this.params.unrealBloomParams.strength += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength > this.currPhase.unrealBloomParams.strength)
                    this.params.unrealBloomParams.strength = this.currPhase.unrealBloomParams.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius < this.currPhase.unrealBloomParams.radius) {
                this.params.unrealBloomParams.radius += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius > this.currPhase.unrealBloomParams.radius)
                    this.params.unrealBloomParams.radius = this.currPhase.unrealBloomParams.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold < this.currPhase.unrealBloomParams.threshold) {
                this.params.unrealBloomParams.threshold += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold > this.currPhase.unrealBloomParams.threshold)
                    this.params.unrealBloomParams.threshold = this.currPhase.unrealBloomParams.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        if(this.currPhaseIndex >= 1) { 
            this.growthTimer += deltaTime;
            // if(this.growthTimer >= 0.1) {
            //     this.growthTimer -= 0.1;
                
                this.updateColor(deltaTime, this.totalRunningTime, this.currPhase.changeColorForward);
                //this.updateElevation(deltaTime, elapsedTime, this.currPhase.goReverse);
                //this.updateBloom(deltaTime, elapsedTime, this.currPhase.goReverse);                
            // }
        }        

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];

                    // if(this.currPhase.hasBigWavesChange)
                    //     this.waterMaterial.uniforms.uBigWavesElevation.value = this.currPhase.uBigWavesElevation;

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;
    }

    render()
    {
        this.effectComposer.render();
        //this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class