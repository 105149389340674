import * as THREE from 'three'
import vertexShader from './../shaders/caterpillar/vertex.glsl'
import fragmentShader from './../shaders/caterpillar/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'
import gsap from "gsap";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class Caterpillar extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            numSpokesPerWheel: 30,
            spokeRadius: 2.0,
            spokeGapRadius: 1.0,
            numSpokes: 80,
            gapBetweenSpokes: 0.0725,
            commonAlphaOffset: 0.2,
            commonAlphaOffset2: 0.0,
            flashedAlpha: false,
            
        }

        this.spokes = [];

        this.spokes1 = [];
        this.spokes2 = [];

        this.worm = null;
        this.star = null;
        this.star2 = null;
        this.geometry = null;
        this.material = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 1.0;
        this.unrealBloomPass.radius = 0.8;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        /// Phases     
        this.phases = [
            {  // phase 1                
                startTime: 0.0,
                changesWave1Alpha: false,
                opacity1: 0.2,
                opactiy1Duration: 2.0,
                changesWave2Alpha: false,
                opacity2: 0.0,   
                opactiy2Duration: 2.0,
                doesFlash: false,   
                maxFlashTimer: 3.0,
                changesCameraPos: false,         
                cameraChangeDuration: 0                               
            },
            {  // fade in wave 1             
                startTime: 3.0,
                changesWave1Alpha: true,
                opacity1: 0.6,
                opactiy1Duration: 2.0,
                changesWave2Alpha: false,
                opacity2: 0.0,   
                opactiy2Duration: 2.0,
                doesFlash: false,   
                maxFlashTimer: 3.0,
                changesCameraPos: false,  
                cameraChangeDuration: 0                             
            },
            {  // flash wave 1               
                startTime: 11.0,
                changesWave1Alpha: false,
                opacity1: 0.6,
                opactiy1Duration: 2.0,
                changesWave2Alpha: false,
                opacity2: 0.0,   
                opactiy2Duration: 2.0,
                doesFlash: true,   
                maxFlashTimer: 1.5,
                changesCameraPos: false,  
                cameraChangeDuration: 0                             
            },
            {  // dim wave 1 a little, and move camera                
                startTime: 17.0,
                changesWave1Alpha: true,
                opacity1: 0.4,
                opactiy1Duration: 2.0,
                changesWave2Alpha: false,
                opacity2: 0.0,   
                opactiy2Duration: 2.0,
                doesFlash: false,   
                maxFlashTimer: 2.0,
                changesCameraPos: true, 
                cameraTargetPos: new THREE.Vector3(0, 0, 4),  
                cameraChangeDuration: 4                             
            },
            {  // do flashes wave 1               
                startTime: 22.0,
                changesWave1Alpha: true,
                opacity1: 0.6,
                opactiy1Duration: 3.0,
                changesWave2Alpha: false,
                opacity2: 0.4,   
                opactiy2Duration: 2.0,
                doesFlash: true,   
                maxFlashTimer: 2.0,
                changesCameraPos: false,                    
                cameraChangeDuration: 0                              
            },
            {  // dim wave 1 a little, and move camera back               
                startTime: 28.0,
                changesWave1Alpha: true,
                opacity1: 0.4,
                opactiy1Duration: 2.0,
                changesWave2Alpha: false,
                opacity2: 0.0,   
                opactiy2Duration: 2.0,
                doesFlash: false,   
                maxFlashTimer: 2.0,
                changesCameraPos: true, 
                cameraTargetPos: new THREE.Vector3(0, -5, 0),  
                cameraChangeDuration: 3                             
            },
            {  // start wave 2            
                startTime: 32.0,
                changesWave1Alpha: true,
                opacity1: 0.4,
                opactiy1Duration: 3.0,
                changesWave2Alpha: true,
                opacity2: 0.4,   
                opactiy2Duration: 3.0,
                doesFlash: false,   
                maxFlashTimer: 2.0,
                changesCameraPos: false,                 
                cameraChangeDuration: 0                             
            },
            {  // do flashes       
                startTime: 42.0,
                changesWave1Alpha: false,
                opacity1: 0.4,
                opactiy1Duration: 3.0,
                changesWave2Alpha: false,
                opacity2: 0.4,   
                opactiy2Duration: 4.0,
                doesFlash: true,   
                maxFlashTimer: 1.5,
                changesCameraPos: false,                 
                cameraChangeDuration: 0                             
            },
            {  // flash more       
                startTime: 52.0,
                changesWave1Alpha: true,
                opacity1: 0.3,
                opactiy1Duration: 1.5,
                changesWave2Alpha: true,
                opacity2: 0.3,   
                opactiy2Duration: 1.5,
                doesFlash: true,   
                maxFlashTimer: 0.75,
                changesCameraPos: false,                 
                cameraChangeDuration: 0                             
            },           
            {  // fade out - flash like crazy     
                startTime: 57.0,
                changesWave1Alpha: true,
                opacity1: 0.15,
                opactiy1Duration: 5.0,
                changesWave2Alpha: true,
                opacity2: 0.15,   
                opactiy2Duration: 5.0,
                doesFlash: true,   
                maxFlashTimer: 0.5,
                changesCameraPos: false,                 
                cameraChangeDuration: 0                            
            },
            {  // fade out      
                startTime: 62.0,
                changesWave1Alpha: true,
                opacity1: 0.0,
                opactiy1Duration: 3,
                changesWave2Alpha: true,
                opacity2: 0.0,   
                opactiy2Duration: 3,
                doesFlash: false,   
                maxFlashTimer: 2.0,
                changesCameraPos: false,                 
                cameraChangeDuration: 0                            
            },
        ];
            
        this.currPhaseIndex = 0;
        this.currPhase = this.phases[0];
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.isDone = false;

        this.doingFlashes = false;
        this.flashTimer = 0.0;
        this.maxFlashTimer = 3.0;

        this.totalRunningTime = 0.0;
    }


    buildVisual() 
    {
        if(this.star !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.star);
            this.scene.remove(this.worm);            
        }

        this.worm = new THREE.Object3D();
        this.worm2 = new THREE.Object3D();
        this.scene.add(this.worm);
        this.scene.add(this.worm2);
    
        for(let worm = 0; worm < 2; worm++) {

            let nextZ = -1.0 * (this.params.numSpokes / 2.0) * this.params.gapBetweenSpokes;
            let nextSegmentRotation = 0.0;

            let alphaOffset = this.params.commonAlphaOffset;
            if(worm === 1) {
                alphaOffset = this.params.commonAlphaOffset2;
            }
        
            for(let spokeI = 0; spokeI < this.params.numSpokes; spokeI++) {
        
                nextZ += this.params.gapBetweenSpokes;
        
                this.geometry = new THREE.BufferGeometry()
                const positionsArray = new Float32Array(this.params.numSpokesPerWheel * 2 * 3) // 20 lines, 2 vertices per line, 3 positions per vertex
        
                for(let i = 0; i < this.params.numSpokesPerWheel; i++)
                {
                    const nextAngle = i * (360.0 / this.params.numSpokesPerWheel);   
                    const angleRadians = nextAngle * (Math.PI / 180.0); 
        
                    const i6 = i * 6;
                    /// Vertex #1
                    // all lines start at center
                    positionsArray[i6] = 0.0; // x
                    positionsArray[i6 + 1] = 0.0; // y
                    positionsArray[i6 + 2] = nextZ; // z
                    /// Vertex #2
                    positionsArray[i6 + 3] = this.params.spokeRadius * Math.cos(angleRadians); // x
                    positionsArray[i6 + 4] = this.params.spokeRadius * Math.sin(angleRadians); // y
                    positionsArray[i6 + 5] = nextZ; // z
                }
                const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3)
                this.geometry.setAttribute('position', positionsAttribute)
        
                const material = new THREE.ShaderMaterial({
                    vertexShader: vertexShader,
                    fragmentShader: fragmentShader,
                    side: THREE.DoubleSide,
                    transparent: true,
                    uniforms: {
                        uMaxLength: { value: this.params.spokeRadius },
                        uTime: { value: 0.0 },
                        uCenter: { value: new THREE.Vector3(0.0, 0.0, 0.0) },
                        uSpokeGapRadius: { value: 1.5 },
                        uResolution: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
                        uCommonAlphaOffset: { value: alphaOffset },
                        uAlphaOffset: { value: 0.0 }
                    }
                });
        
                this.mesh = new THREE.LineSegments(this.geometry, material)
                this.star = new THREE.Object3D();
                this.star2 = new THREE.Object3D();

                if(worm === 0) {
                    this.star.add(this.mesh);
                    this.scene.add(this.star);
                    this.spokes1.push(this.mesh);
                }
                else {
                    
                    this.star2.add(this.mesh);
                    this.star2.rotation.y = 90.0;
                    this.scene.add(this.star2);
                    this.spokes2.push(this.mesh);
                }
                //worm.add(star);
                
        
                this.spokes.push({
                    object: this.mesh,
                    isExpanding: true,
                    starScale: 0.0
                });
            }
        }
        this.worm.rotation.y = 90.0; 
        this.worm2.rotation.y = 0.0;

        this.camera.position.x = 0; //-3;
        this.camera.position.y = -5; //-2;
        this.camera.position.z = 0; //7;
        
        // this.camera.rotation.x = 0
        this.camera.rotation.y = 100;
        // this.camera.rotation.z = 90;

        this.camera.lookAt(0, 0, 0)
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    flashAlpha() {

        let delay = 0;
        for(let i = 0; i < this.spokes.length; i++) {
            const spoke = this.spokes[i].object;
            const t1 = gsap.timeline();
            t1.to(spoke.material.uniforms.uCommonAlphaOffset, { duration: 0.125, value: 0.6, ease: 'sine.inOut'}, delay) 
                .to(spoke.material.uniforms.uCommonAlphaOffset, { duration: 0.125, value: 0.0, ease: 'sine.inOut'}) 
            delay += 0.005;

        }

    }

    animateWaveAlpha(waveNum, targetAlpha, targetDuration)
    {
        // console.log("Updating wave 1 alpha");

        let spokes;
        if(waveNum === 0) {
            spokes = this.spokes1;
        } else {
            spokes = this.spokes2;
        }
       
        for(let i = 0; i < spokes.length; i++) {
            
            const spoke = spokes[i];            
            const t1 = gsap.timeline();
            t1.to(spoke.material.uniforms.uAlphaOffset, { duration: targetDuration, value: targetAlpha, ease: 'linear'}); 
        }
    }

    lookAtCenter()
    {

    }

    animateCameraPosition(targetPosition, targetDuration)
    {
        // console.log("animating camera");
        const t1 = gsap.timeline();
        t1.to(this.camera.position, { duration: targetDuration, x: targetPosition.x, ease: 'linear'});
        
        const t2 = gsap.timeline();
        t2.to(this.camera.position, { duration: targetDuration, y: targetPosition.y, ease: 'linear'});

        const t3 = gsap.timeline();
        t3.to(this.camera.position, { duration: targetDuration, z: targetPosition.z, ease: 'linear'});
        
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update flash if necessary
        if(this.doingFlashes === true) {
            this.flashTimer += deltaTime;
            if(this.flashTimer >= this.maxFlashTimer) {
                this.flashAlpha();
                this.flashTimer = 0.0;
            }    
        }        
        
        if(this.currPhase.changesCameraPos === true) {
            this.camera.lookAt(0,0,0);
        }

        // update spokes    
        for(let i = 0; i < this.spokes.length; i++) {
            const spoke = this.spokes[i].object;
            spoke.material.uniforms.uTime.value = this.totalRunningTime + i * this.params.gapBetweenSpokes;
            spoke.rotation.z += Math.sin(deltaTime / 3.0);
        }

        // update phases    
        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];                   

                    if(this.currPhase.changesWave1Alpha === true) {
                        this.animateWaveAlpha(0, this.currPhase.opacity1, this.currPhase.opactiy1Duration);
                    }
                    if(this.currPhase.changesWave2Alpha === true) {
                        this.animateWaveAlpha(1, this.currPhase.opacity2, this.currPhase.opactiy2Duration);
                    }
                    if(this.currPhase.doesFlash === true) {
                        this.doingFlashes = true;
                        this.flashTimer = 0.0;
                        this.maxFlashTimer = this.currPhase.maxFlashTimer;
                    } else {
                        this.doingFlashes = false;
                    }

                    if(this.currPhase.changesCameraPos === true) {
                        this.animateCameraPosition(this.currPhase.cameraTargetPos, this.currPhase.cameraChangeDuration);
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }
    }

    render()
    {
        // this.renderer.render(this.scene, this.camera);
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.star !== null)
        {     
            this.geometry.dispose();
            this.material.dispose();
            for( let i = this.scene.children.length - 1; i >= 0; i--) { 
                const obj = this.scene.children[i];
                this.scene.remove(obj); 
           }        
        }
    }

} // end class