import * as THREE from 'three'
import vertexShader from './../shaders/mindBugs/vertex.glsl'
import fragmentShader from './../shaders/mindBugs/fragment.glsl'
import { AdditiveBlending, Vector3 } from 'three'
import BaseVisual from './baseVisual'
import MindBug from './mindBug.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class MindBugs extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: 0,
            cameraY: 0,
            cameraZ: 8,
            numLinesPerStar: 40,
            unrealBloomParams: {
                strength: 1.0,
                radius: 1.0,
                threshold: 0.175,
            },
            maxBugs: 150,
            startedDeathSequence: false
        };

        this.bugs = [];       
        

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 800,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
        this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
        this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        // uBigWavesElevation: 0.2,
        // uSmallWavesElevation: 0.00,
        // uSmallWavesFrequency: 0.0,
        // uSmallWavesSpeed: 0.00,

        /// phases
        this.phases = [
            {  // phase 1
                startTime: 0.0,
                spawnRate: 1.25,
                makeBursts: true,
                killAll: false                           
            },
            // {  // phase 2
            //     startTime: 5.0,
            //     spawnRate: 0.75,
            //     makeBursts: true,
            //     killAll: false                                                
            // },   
            {  // phase 3
                startTime: 5.0,
                spawnRate: 0.5,
                makeBursts: true,
                killAll: false                                                
            }, 
            {  // phase 4
                startTime: 10.0,
                spawnRate: 0.125,
                makeBursts: true,
                killAll: false                                                
            },
            {  // phase 5
                startTime: 16.0,
                spawnRate: 0.065,
                makeBursts: true,
                killAll: false                                                
            },
            // {  // phase 6
            //     startTime: 20.0,
            //     spawnRate: 0.065,
            //     makeBursts: true,
            //     killAll: false                                                
            // },
            {  // phase 7
                startTime: 20.0,
                spawnRate: 0.0325,
                makeBursts: true,
                killAll: false                                                
            },
            {  // phase 7
                startTime: 28.0,
                spawnRate: 0.0325,
                makeBursts: false,
                killAll: false                                                
            },
            {
                startTime: 35.0,
                spawnRate: 0.0,
                makeBursts: false,
                killAll: true
            }
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.totalRunningTime = 0.0;

    } // end constructor

    buildVisual() 
    {            
        this.addBug(new THREE.Vector3(0,0,0), true);
    
        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ);
        this.camera.lookAt(0, 0, 0);
    } 
    
    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
    }

    addBug(bugPos, isBursting = true)
    {
        const material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            uniforms: {
                uMaxLength: { value: 1.5 },
                uTime: { value: 0.0 },
                uCenter: { value: bugPos }
            }
        });

        const geometry = new THREE.BufferGeometry();            
        const positionsArray = new Float32Array(this.params.numLinesPerStar * 2 * 3); // 20 lines, 2 vertices per line, 3 positions per vertex

        for(let i = 0; i < this.params.numLinesPerStar; i++) {
            const i6 = i * 6;
            /// Vertex #1
            // all lines start at center
            positionsArray[i6] = 0.0; // x
            positionsArray[i6 + 1] = 0.0; // y
            positionsArray[i6 + 2] = 0.0; // z
            /// Vertex #2
            positionsArray[i6 + 3] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // x
            positionsArray[i6 + 4] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // y
            positionsArray[i6 + 5] = 0.25 + (Math.random() * 1.25) * (Math.random() > 0.5? 1.0: -1.0); // z
        }

        const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);
        geometry.setAttribute('position', positionsAttribute);

        const mesh = new THREE.LineSegments(geometry, material)
        const bug = new MindBug(0.0, 3.0);   
        if(isBursting == true)
            bug.setState("bursting");  
        else
            bug.setState("stay");       
        bug.add(mesh);

        bug.setPosition(bugPos);

        this.bugs.push(bug);
        this.scene.add(bug);
    }

    updateBloom(deltaTime, elapsedTime, goReverse = false)
    {
        const updateMulti = 3.0;

        if(goReverse == true) {
            if(this.params.unrealBloomParams.strength > this.currPhase.unrealBloomParams.strength) {
                this.params.unrealBloomParams.strength -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength < this.currPhase.unrealBloomParams.strength)
                    this.params.unrealBloomParams.strength = this.currPhase.unrealBloomParams.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius > this.currPhase.unrealBloomParams.radius) {
                this.params.unrealBloomParams.radius -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius < this.currPhase.unrealBloomParams.radius)
                    this.params.unrealBloomParams.radius = this.currPhase.unrealBloomParams.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold > this.currPhase.unrealBloomParams.threshold) {
                this.params.unrealBloomParams.threshold -= deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold < this.currPhase.unrealBloomParams.threshold)
                    this.params.unrealBloomParams.threshold = this.currPhase.unrealBloomParams.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
        else {
            if(this.params.unrealBloomParams.strength < this.currPhase.unrealBloomParams.strength) {
                this.params.unrealBloomParams.strength += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.strength > this.currPhase.unrealBloomParams.strength)
                    this.params.unrealBloomParams.strength = this.currPhase.unrealBloomParams.strength;

                this.unrealBloomPass.strength = this.params.unrealBloomParams.strength;
            }

            if(this.params.unrealBloomParams.radius < this.currPhase.unrealBloomParams.radius) {
                this.params.unrealBloomParams.radius += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.radius > this.currPhase.unrealBloomParams.radius)
                    this.params.unrealBloomParams.radius = this.currPhase.unrealBloomParams.radius;

                this.unrealBloomPass.radius = this.params.unrealBloomParams.radius;
            }

            if(this.params.unrealBloomParams.threshold < this.currPhase.unrealBloomParams.threshold) {
                this.params.unrealBloomParams.threshold += deltaTime * updateMulti;
                if(this.params.unrealBloomParams.threshold > this.currPhase.unrealBloomParams.threshold)
                    this.params.unrealBloomParams.threshold = this.currPhase.unrealBloomParams.threshold;

                this.unrealBloomPass.threshold = this.params.unrealBloomParams.threshold;
            }
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime; 

        this.growthTimer += deltaTime;
        if(this.growthTimer >= this.currPhase.spawnRate) {
            this.growthTimer -= this.currPhase.spawnRate;
            
            if((this.bugs.length < this.params.maxBugs) && (this.params.startedDeathSequence != true)) {
                const spawnPos = new Vector3(Math.random() * 10.0 - 5.0, Math.random() * 8.0 - 4.0, 0.0);
                this.addBug(spawnPos, this.currPhase.makeBursts);
            }            
        }
        

        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];
                    if(this.currPhase.killAll == true) {
                        this.params.startedDeathSequence = true;
                        for(let y = 0; y < this.bugs.length; y++) {
                            this.bugs[y].setState("kill");
                        }
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }

        // update active bugs
        for(let x = 0; x < this.bugs.length; x++) {
            this.bugs[x].update(deltaTime);
        }

        // remove any dead bugs
        for(let y = (this.bugs.length - 1); y >= 0; y--) {
            let bug = this.bugs[y];
            if(bug.isDead) {
                this.scene.remove(bug);
                this.bugs.splice(y, 1);
            }
        }
    }

    render()
    {
        this.effectComposer.render();
        //this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        
    }

} // end class