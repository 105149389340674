import * as THREE from 'three'
import vertexShader from './../shaders/abdel/vertex.glsl'
import fragmentShader from './../shaders/abdel/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

export default class AbdelAudio extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes); 

        this.params = {
            cameraX: -0.25,
            cameraY: 0.15,
            cameraZ: -0.15,

            numPlayerBars: 30,
            numLinesPerBar: 30,
            numPointsInLine: 9,
            staffWidth: 3.0,

            numLines: 1800,
            radius: 0.35,
            additionalRadius: 0.15,
            shortRadius: 0.1,
            additionalShortRadius: 0.25,
            
            cubeRotationSpeedDiv: 7.0, // higher number = slower speed
           
            surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
            depthColor: new THREE.Vector3(0.105, 0.105, 0.105),
            surfaceColorBlack: new THREE.Vector3(0.0, 0.0, 0.0),

            doingHeave: 0.0,
            heaveMagnitude: 0.0,
            doingInternalRoation: 0.0, // NOTE: you need to set this to 1.0 to have Jitter OR Swirl    
            doingJitter: 0.0,
            doingSwirl: 0.0,
            swirlSpeed: 1.0,
            jitterOn: false,
            jitterTimer: 0.0,
            jitterMaxTimer: 0.065,
            repeatJitterCount: 0,
            repeatJitterCountMax: 5,
            jitterPauseTimer: 0.0,
            jitterPauseTimerMax: 3.0,
            jitterPaused: false
        };

        this.lines = [];
        this.lineStaff = null;
        this.water = null;
        this.geometry = null;
        this.material = null;
        
        // extra audio parameters
        this.sampleTimer = 0.0;
        this.sampleRate = null;
        this.currSampleIndex = 0;
        this.rawAudioData = null;
        this.normalizedAudioData = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.15;
        this.unrealBloomPass.radius = 0.15;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        /// phases
        this.phases = [
            {  // phase 1               
                startTime: 0.0,
                // hasCameraChange: false,
                // cameraZ: -0.5,
                // surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                // depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                // hasColorChange: false,
                // doingHeave: 0.0,
                // heaveMagnitude: 0.0,
                // heaveForward: false,
                // doingInternalRoation: 0.0,
                // doingJitter: 0.0,
                // doingSwirl: 0.0,
                // swirlForward: false,
                // swirlSpeed: 0.0,
                // cubeRotationSpeedDiv: 7.0
                                         
            },
            {  // phase 2 ---                 
                startTime: 2.0,
                // hasCameraChange: false,
                // cameraZ: -0.5,
                // surfaceColor: new THREE.Vector3(0.855, 0.855, 0.855),
                // depthColor: new THREE.Vector3(0.105, 0.105, 0.105),                  
                // hasColorChange: false,
                // doingHeave: 0.0,
                // heaveMagnitude: 0.0,
                // heaveForward: false,
                // doingInternalRoation: 1.0,
                // doingJitter: 1.0,
                // doingSwirl: 0.0,
                // swirlForward: false,
                // swirlSpeed: 1.0,
                // cubeRotationSpeedDiv: 7.0                                              
            },               
        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;
        this.growthTimer = 0.0;

        this.currSurfaceColor = this.phases[0].surfaceColor;
        this.currDepthColor = this.phases[0].depthColor;

        this.barMaterials = [];
        this.barSizes = [];

        // document.getElementById("main_body").addEventListener('click', () => { /// NEED!!!
        //     this.playAudio();
        // })

        this.totalRunningTime = 0.0;

    } // end constructor


    buildVisual() 
    {  
        this.lineStaff = new THREE.Object3D();
        this.scene.add(this.lineStaff);

        let barStart = -0.5 * this.params.staffWidth;
        const barWidth = this.params.staffWidth / this.params.numPlayerBars;
        const gapInLines = barWidth / this.params.numLinesPerBar;

        for(let bar = 0; bar < this.params.numPlayerBars; bar++) {            
            
            const barMaterial = new THREE.ShaderMaterial({
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                depthWrite: false,
                vertexColors: true,
                blending: AdditiveBlending,
                uniforms: {
                    uTime: { value: 0 },
                    uMaxRadius: { value: this.params.radius + this.params.additionalRadius },
                    uScreenCenter: { value: new THREE.Vector2(window.innerWidth / 2.0, window.innerHeight / 2.0)},                        
                    uCurrentRadius: { value: 1.0 },    
    
                    uDepthColor: { value: this.params.depthColor },
                    uSurfaceColor: { value: this.params.surfaceColor },
                    uColorOffset: { value: 0.08 },
                    uColorMultiplier: { value: 5 },
    
                }
            });
            
            this.barMaterials.push(barMaterial);
            this.barSizes.push(0); // all bars start at 0 in size

            let nextLineX = barStart + (bar * barWidth);
            let nextX = nextLineX;
            for(let lineIndex = 0; lineIndex < this.params.numLinesPerBar; lineIndex++) {                
                
                let points = [];
                let xOffSet = (Math.random() < 0.5 ? -1.0 : 1.0) * Math.random() * gapInLines;
                //start top left
                let startPoint = new THREE.Vector3(
                    nextX + xOffSet,
                    this.params.radius + (Math.random() * this.params.additionalRadius),
                    this.params.radius + (Math.random() * this.params.additionalRadius),
                );
                points.push(startPoint);

                for(let pointInd = 1; pointInd < (this.params.numPointsInLine - 1); pointInd++) {

                    // x
                    xOffSet = (Math.random() < 0.5 ? -1.0 : 1.0) * Math.random() * gapInLines * 20.75; 
                    const xPos = nextX + ((pointInd - 1) * gapInLines) + xOffSet;

                    // y
                    let yPos;
                    let negY = 1.0;
                    if((pointInd == 4) || (pointInd == 5) || (pointInd == 6)) 
                        negY = -1.0;
                    else if((pointInd == 3) || (pointInd == 7))
                        negY = Math.random() > 0.5 ? 1.0 : -1.0;

                    if((pointInd % 2) == 0)
                        yPos = negY * (this.params.radius + (Math.random() * this.params.additionalRadius));
                    else {
                        yPos = negY * (this.params.radius + (Math.random() * this.params.additionalRadius));
                        // yPos = negY * (this.params.shortRadius + (Math.random() * this.params.additionalShortRadius));
                    }
                           
                    // z
                    let zPos;
                    let negZ = 1.0;
                    if((pointInd == 2) || (pointInd == 3) || (pointInd == 4)) 
                        negZ = -1.0;
                    else if((pointInd == 1) || (pointInd == 5)) {
                        negZ = Math.random() > 0.5 ? 1.0 : -1.0;
                    }    

                    if((pointInd % 2) == 0)
                        zPos = negZ * (this.params.radius + (Math.random() * this.params.additionalRadius));
                    else {
                        zPos = negZ * (this.params.radius + (Math.random() * this.params.additionalRadius));
                        // zPos = negZ * (this.params.shortRadius + (Math.random() * this.params.additionalShortRadius));
                    }
                       
                    const point = new THREE.Vector3(xPos, yPos, zPos);
                    points.push(point);
                }
                // add the first point as the last point
                points.push(startPoint); 

                const geometry = new THREE.BufferGeometry().setFromPoints( points );
                const line = new THREE.Line( geometry, barMaterial );

                this.lineStaff.add(line);
                this.lines.push(line); 
               
                this.lineStaff.rotation.y = Math.PI * 0.5;

                nextX += gapInLines;
            }
        }

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0,0,0);
        
    }

    updateColor(deltaTime, elapsedTime, changeDirection)
    {
        const surfaceIncr = 0.025;
        const depthIncr = 0.0125;

        if(changeDirection === "light") {
            let surface = this.material.uniforms.uSurfaceColor.value;
            if(surface.x < this.currPhase.surfaceColor.x) {
                surface.x += surfaceIncr;
                surface.y += surfaceIncr;
                surface.z += surfaceIncr;
                if(surface.x >= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.material.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.material.uniforms.uDepthColor.value;
            if(depth.x < this.currPhase.depthColor.x) {
                depth.x += depthIncr;
                depth.y += depthIncr;
                depth.z += depthIncr;
                if(depth.x >= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.material.uniforms.uDepthColor.value = depth;
            }
        }
        else { // dark
            let surface = this.material.uniforms.uSurfaceColor.value;
            if(surface.x > this.currPhase.surfaceColor.x) {
                surface.x -= surfaceIncr;
                surface.y -= surfaceIncr;
                surface.z -= surfaceIncr;
                if(surface.x <= this.currPhase.surfaceColor.x)
                    surface = this.currPhase.surfaceColor;
                
                this.material.uniforms.uSurfaceColor.value = surface;
            }
            let depth = this.material.uniforms.uDepthColor.value;
            if(depth.x > this.currPhase.depthColor.x) {
                depth.x -= depthIncr;
                depth.y -= depthIncr;
                depth.z -= depthIncr;
                if(depth.x <= this.currPhase.depthColor.x)
                    depth = this.currPhase.depthColor;
                
                this.material.uniforms.uDepthColor.value = depth;
            }
        }        
    }   

    updateHeave(deltaTime, shouldIncrease = true)
    {
        this.material.uniforms.uDoingHeave.value = 1.0;

        if(this.params.heaveMagnitude != this.currPhase.heaveMagnitude) {
            if(shouldIncrease == true) {
                this.params.heaveMagnitude += deltaTime * 0.5;
                if(this.params.heaveMagnitude > this.currPhase.heaveMagnitude) {
                    this.params.heaveMagnitude = this.currPhase.heaveMagnitude;
                }
            }
            else {
                this.params.heaveMagnitude -= deltaTime * 0.5;
                if(this.params.heaveMagnitude < this.currPhase.heaveMagnitude) {
                    this.params.heaveMagnitude = this.currPhase.heaveMagnitude;
                }
            }
            this.material.uniforms.uHeaveMagnitude.value = this.params.heaveMagnitude;
        }
    }

    updateJitter(deltaTime)
    {        
        if(this.params.jitterPaused == true) {
            this.params.jitterPauseTimer += deltaTime;
            // console.log("Updating pause timer: " + this.params.jitterPauseTimer);
            if(this.params.jitterPauseTimer >= this.params.jitterPauseTimerMax) {
                this.params.jitterPaused = false;
                this.params.jitterPauseTimer = 0.0;
                this.params.jitterOn = true;
                 //console.log("Jitter UN-paused!!");
            }
        }
        else {
            this.params.jitterTimer += deltaTime;
            if(this.params.jitterTimer >= this.params.jitterMaxTimer) {
                this.params.jitterTimer = 0.0;

                if(this.params.jitterOn == true) {
                    this.params.jitterOn = false;
                    this.material.uniforms.uDoingJitter.value = 0.0;
                    this.material.uniforms.uDoingInternalRotation.value = 0.0;
                    // increment count... if max, go into pause
                    this.params.repeatJitterCount++;
                    if(this.params.repeatJitterCount >= this.params.repeatJitterCountMax) {
                        this.params.repeatJitterCount = 0;
                        this.params.jitterPaused = true;
                         //console.log("Jitter paused!!");
                    }
                } 
                else {
                    this.params.jitterOn = true;
                    this.material.uniforms.uDoingJitter.value = 1.0;
                    this.material.uniforms.uDoingInternalRotation.value = 1.0;
                }
            }
        }
    }

    updateSwirl(deltaTime, shouldIncrease = true)
    {
        this.material.uniforms.uDoingSwirl.value = 1.0;
        this.material.uniforms.uDoingInternalRotation.value = 1.0;

        if(this.params.swirlSpeed != this.currPhase.swirlSpeed) {
            if(shouldIncrease == true) {
                this.params.swirlSpeed += deltaTime * 0.25;
                if(this.params.swirlSpeed > this.currPhase.swirlSpeed) {
                    this.params.swirlSpeed = this.currPhase.swirlSpeed;
                }
            }
            else {
                this.params.swirlSpeed -= deltaTime * 0.25;
                if(this.params.swirlSpeed < this.currPhase.swirlSpeed) {
                    this.params.swirlSpeed = this.currPhase.swirlSpeed;
                }
            }
            this.material.uniforms.uSwirlSpeed.value = this.params.swirlSpeed;
        }
    }

    updateRotation(deltaTime, elapsedTime, shouldIncrease = false)
    {
        if(this.params.cubeRotationSpeedDiv != this.currPhase.cubeRotationSpeedDiv) {
            if(shouldIncrease == true) {
                this.params.cubeRotationSpeedDiv -= deltaTime; // in this case, a smaller number means a faster rate
                if(this.params.cubeRotationSpeedDiv < this.currPhase.cubeRotationSpeedDiv)
                    this.params.cubeRotationSpeedDiv = this.currPhase.cubeRotationSpeedDiv;
            }
            else {
                this.params.cubeRotationSpeedDiv += deltaTime; // in this case, a smaller number means a faster rate
                if(this.params.cubeRotationSpeedDiv > this.currPhase.cubeRotationSpeedDiv)
                    this.params.cubeRotationSpeedDiv = this.currPhase.cubeRotationSpeedDiv;
            }
        }

        this.lineStaff.rotation.y = elapsedTime / this.params.cubeRotationSpeedDiv;
    }

    updateCamera(deltaTime)
    {
        if(this.params.cameraZ != this.currPhase.cameraZ) {
            this.params.cameraZ += deltaTime * 0.2;
            if(this.params.cameraZ > this.currPhase.cameraZ)
                this.params.cameraZ = this.currPhase.cameraZ;
            
            this.camera.position.z = this.params.cameraZ;    
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        // update cube rotation
        // this.updateRotation(deltaTime, elapsedTime);

        // if(this.currPhase.hasCameraChange == true)
        //     this.updateCamera(deltaTime); 
        // if(this.currPhase.doingHeave == 1.0)
        //     this.updateHeave(deltaTime, this.currPhase.heaveForward);
        // if(this.currPhase.doingJitter == 1.0)
        //     this.updateJitter(deltaTime);
        // if(this.currPhase.doingSwirl == 1.0)
        //     this.updateSwirl(deltaTime, this.currPhase.swirlForward);  

        // if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
        //     if(elapsedTime >= this.nextPhaseStartTime) {  
        //         this.currPhaseIndex += 1;

        //         console.log("Curr phase: " + this.currPhaseIndex);

        //         if(this.currPhaseIndex < this.phases.length) {
        //             this.currPhase = this.phases[this.currPhaseIndex];    
        //             if(this.currPhase.isEnd) {
        //                 if(this.currPhase.isEnd == true)
        //                     this.scene.remove(this.lineCube);
        //             }              

        //             if((this.currPhaseIndex + 1) < this.phases.length)
        //                 this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
        //             else {
        //                 this.isDone = true;                        
        //             }
        //         }                
        //     }
        // }
        
        // update wave
        //this.material.uniforms.uTime.value = elapsedTime / 4.0;
        
        this.totalRunningTime += deltaTime;

        // update audio
        if(this.soundPlaying == true) {
            this.sampleTimer += deltaTime;
            if(this.sampleTimer >= this.sampleRate) {
                if(this.currSampleIndex < this.rawAudioData.length) {
                    // update next with last
                    for(let i = (this.barSizes.length - 1); i > 0; i--) {
                        this.barSizes[i] = this.barSizes[i - 1];
                        this.barMaterials[i].uniforms.uCurrentRadius.value = this.barSizes[i];
                    }
                    // populate first with the 'next' audio sample                
                    
                    this.barSizes[0] = this.normalizedAudioData[this.currSampleIndex];
                    this.barMaterials[0].uniforms.uCurrentRadius.value = this.barSizes[0];
                    // console.log("Bar size 0: " + this.barSizes)

                    this.currSampleIndex++;        
                }
                this.sampleTimer = 0.0;
            }            
        }
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
        
        this.sampleRate = sampleRate;        
        this.rawAudioData = rawAudioData;
        this.normalizedAudioData = normalizedAudioData;
    }

    render()
    {
        this.effectComposer.render();
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.geometry.dispose();
            this.material.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class