import * as THREE from 'three'
import waterVertexShader from './../shaders/Gobular/vertex.glsl'
import waterFragmentShader from './../shaders/Gobular/fragment.glsl'
import { AdditiveBlending } from 'three'
import BaseVisual from './baseVisual'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import gsap from "gsap";

export default class Gobular extends BaseVisual
{
    constructor(scene, renderer, camera, sizes)
    {
        super(scene, renderer, camera, sizes);

        this.params = {
            cameraX: 0,
            cameraY: 4,
            cameraZ: 0,
            uSmallWavesElevation: 0.168,
            uSmallWavesFrequency: 1.574,
            uSmallWavesSpeed: 1.811,
            uSmallWavesIterations: 5,
            rectWidth: 12,
            rectHeight: 8,
            surfaceColor: new THREE.Vector3(5.0 / 255.0, 5.0 / 255.0, 5.0 / 255.0),
            depthColor: new THREE.Vector3(5.0 / 255.0, 5.0 / 255.0, 5.0 / 255.0),
            numSine: 0.78,
            numCosine: 1.05,
            elevationAddClamp: 0.45,
            wireframe: true,
            areWavesChanging: true
        }

        this.ghostHead1 = new THREE.Vector3(0, 1, 0);

        this.water = null;
        this.waterGeometry = null;
        this.waterMaterial = null;

        /// Render target
        let RenderTargetClass = null;

        if(this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
        }
        else {
            RenderTargetClass = THREE.WebGLRenderTarget;
        }


        this.renderTarget = new RenderTargetClass(
            800, 600,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat,
                encoding: THREE.sRGBEncoding
            }
        );

        /// Composer
        this.effectComposer = new EffectComposer(this.renderer, this.renderTarget);
        this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.effectComposer.setSize(this.sizes.width, this.sizes.height);

        /// Passes
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.effectComposer.addPass(this.renderPass);

        this.unrealBloomPass = new UnrealBloomPass();
        this.unrealBloomPass.enabled = true;
        this.unrealBloomPass.strength = 0.5;
        this.unrealBloomPass.radius = 0.5;
        this.unrealBloomPass.threshold = 0.1;
        this.effectComposer.addPass(this.unrealBloomPass);    

        // Anti-aliasing pass <--- YOU NEED TO ADD as the LAST pass
        if(this.renderer.getPixelRatio() === 1 && !this.renderer.capabilities.isWebGL2) {
            this.smaaPass = new SMAAPass();
            this.effectComposer.addPass(this.smaaPass);
        }

        this.totalRunningTime = 0.0;

        this.audioListener = new THREE.AudioListener();
        this.camera.add(this.audioListener);    
        this.sound = new THREE.Audio(this.audioListener);
        this.scene.add(this.sound);
        this.soundSet = false;
        this.soundPlaying = false;

        this.sampleTimer = 0.0;
        this.sampleRate = null;
        this.currSampleIndex = 0;
        this.rawAudioData = null;
        this.normalizedAudioData = null;

        this.phases;

        this.currPhaseIndex;;    
        this.currPhase;
        this.isDone;
        this.nextPhaseStartTime;
        this.growthTimer = 0.0;
    }

    playAudio()
    {
        if(this.soundSet) {
            this.sound.play();
            this.soundPlaying = true;
        }
    }

    buildVisual() 
    {
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water);
        }
    
        this.waterGeometry = new THREE.PlaneGeometry(this.params.rectWidth, this.params.rectHeight, 256, 256);
    
        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: waterVertexShader,
            fragmentShader: waterFragmentShader,
            wireframe: this.params.wireframe,
            //blending: AdditiveBlending,
            uniforms: {
                uTime: { value: 0 },
    
                uGhostHeadPos: { value: this.ghostHead1 },
    
                uNumSines: { value: this.params.numSine},
                uNumCosines: { value: this.params.numCosine },
                uElevationAddClamp: { value: this.params.elevationAddClamp },
                    
                uBigWavesElevation: { value: 0.2 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: this.params.uSmallWavesElevation },
                uSmallWavesFrequency: { value: this.params.uSmallWavesFrequency },
                uSmallWavesSpeed: { value: this.params.uSmallWavesSpeed },
                uSmallWavesIterations: { value: this.params.uSmallWavesIterations },
        
                uDepthColor: { value: this.params.depthColor },
                uSurfaceColor: { value: this.params.surfaceColor },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }
            }
        });
    
        this.water = new THREE.Mesh(this.waterGeometry, this.waterMaterial);
        this.water.rotation.x = - Math.PI * 0.5
        this.scene.add(this.water);

        this.camera.position.set(this.params.cameraX, this.params.cameraY, this.params.cameraZ)
        this.camera.lookAt(0, 0, 0)

        this.phases = [
            {  // phase 1               
                startTime: 0.0,
                
                                         
            },
            {  // phase 2 ---                 
                startTime: 0.05,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSurfaceColor.value,
                        instruction: { duration: 5.0, x: 0.24, y: 0.24, z: 0.24, ease: 'linear' }                          
                    },                                       
                ]                                             
            },
            {  // phase 2 ---                 
                startTime: 25.5,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uNumSines,
                        instruction: { duration: 1.0, value: 1.05, ease: 'expo.out' }                          
                    },
                    {                        
                        param: this.waterMaterial.uniforms.uElevationAddClamp,
                        instruction: { duration: 1.0, value: 0.75, ease: 'expo.out', delay: -1.0 }                          
                    },                    
                ]                                             
            },  
            {  // phase 2 ---                 
                startTime: 38.5,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uNumSines,
                        instruction: { duration: 1.0, value: 0.78, ease: 'expo.out' }                          
                    },
                    {                        
                        param: this.waterMaterial.uniforms.uElevationAddClamp,
                        instruction: { duration: 1.0, value: 0.35, ease: 'expo.out', delay: -1.0 }                          
                    },                    
                ]                                             
            },  
            {  // phase 2 ---                 
                startTime: 45.0,
                changesSines: true,
                sineTweens: [
                    {                        
                        param: this.waterMaterial.uniforms.uSurfaceColor.value,
                        instruction: { duration: 3.0, x: 0.005, y: 0.005, z: 0.005, ease: 'linear' }                          
                    },                                       
                ]                                             
            },

        ];

        this.currPhaseIndex = 0;    
        this.currPhase = this.phases[0];
        this.isDone = false;
        this.nextPhaseStartTime = this.phases[1].startTime;        
    }

    doGsapTransition(timelineSequences)
    {
        console.log("Doing gsap: " + this.currPhaseIndex);

        const timeline = gsap.timeline();
        for(let i = 0; i < timelineSequences.length; i++) {     
            
            let tween = gsap.to(timelineSequences[i].param, timelineSequences[i].instruction);
            timeline.add(tween);
        }
    }

    update(deltaTime, elapsedTime) 
    {    
        this.totalRunningTime += deltaTime;

        // update wave
        this.waterMaterial.uniforms.uTime.value = this.totalRunningTime;

        if(this.params.areWavesChanging === true) {
           // console.log("waves are changing");
            if(this.params.isExpanding === true) {
                this.params.numSine += 0.01;
                this.params.numCosine += 0.01;
                if((this.params.numCosine > 4.0) || (this.params.numSine > 4.0)) {
                    this.params.isExpanding = false;
                }
            }
            else {
                this.params.numSine -= 0.01;
                this.params.numCosine -= 0.01;
                if((this.params.numCosine < 0) || (this.params.numSine < 0)) {
                    this.params.isExpanding = true;
                }
            }
            this.waterMaterial.uniforms.uNumSines.value = this.params.numSine;
            this.waterMaterial.uniforms.uNumCosines.value = this.params.numCosine;
        }
        if(this.isDone != null && ((this.currPhaseIndex + 1) < this.phases.length)) {    
            
            if(this.totalRunningTime >= this.nextPhaseStartTime) {  
                this.currPhaseIndex += 1;

                console.log("Curr phase: " + this.currPhaseIndex);

                if(this.currPhaseIndex < this.phases.length) {
                    this.currPhase = this.phases[this.currPhaseIndex];    
                    
                    if(this.currPhase.changesSines === true) {
                        this.doGsapTransition(this.currPhase.sineTweens);
                    }

                    if((this.currPhaseIndex + 1) < this.phases.length)
                        this.nextPhaseStartTime = this.phases[this.currPhaseIndex + 1].startTime;
                    else {
                        this.isDone = true;                        
                    }
                }                
            }
        }
    }

    setSound(soundBuffer, sampleRate, rawAudioData, normalizedAudioData)
    {
        this.sound.setBuffer(soundBuffer);
        this.soundSet = true;
        
        this.sampleRate = sampleRate;        
        this.rawAudioData = rawAudioData;
        this.normalizedAudioData = normalizedAudioData;
    }

    render()
    {
        this.renderer.render(this.scene, this.camera);
    }

    kill() 
    { 
        if(this.water !== null)
        {
            this.waterGeometry.dispose();
            this.waterMaterial.dispose();
            this.scene.remove(this.water)
        }
    }

} // end class